<template>

  <!-- Subscription Summary Container -->
  <v-row v-if="currentSubscription" no-gutters><v-col>
    <v-row align="center" no-gutters>

      <!-- Subscription Status -->
      <v-col cols="3" class="text-center">
        <!-- Heading -->
        <v-row no-gutters><v-col>
          <span class="subtitle-1 text--secondary font-weight-bold">Current Status</span>
        </v-col></v-row>
        <!-- Value -->
        <v-row no-gutters><v-col>
          <span :class="subscriptionActive ? 'success--text' : 'error--text'"
                class="text-h6 font-weight-bold text-capitalize">
            {{ currentSubscription.status }}
          </span>
        </v-col></v-row>
      </v-col>

      <v-divider vertical />

      <!-- Invoice Balance -->
      <v-col cols="3" class="text-center">
        <!-- Heading -->
        <v-row no-gutters><v-col>
          <span class="subtitle-1 text--secondary font-weight-bold">Balance</span>
        </v-col></v-row>
        <!-- Value -->
        <v-row no-gutters><v-col>
          <span :class="user.customer.stripe.balance === 0 ? 'success--text' : 'error--text'"
                class="text-h6 font-weight-bold">
            {{ `$${currencyFormat(user.customer.stripe.balance)}` }}
          </span>
        </v-col></v-row>
      </v-col>

      <v-divider vertical />

      <!-- Plan Information -->
      <v-col cols="3" class="text-center">
        <!-- Heading -->
        <v-row no-gutters><v-col>
          <span class="subtitle-1 text--secondary font-weight-bold">Plan Pricing</span>
        </v-col></v-row>
        <!-- Value -->
        <v-row no-gutters><v-col>
          <span class="text-h6 font-weight-bold info--text text-capitalize">
            {{ `$${currencyFormat(currentSubscription.price.unit_amount)}` }}
          </span>
          <span class="text-subtitle-1 font-weight-bold info--text">
            {{ `/ ${currentSubscription.price.recurring.interval}ly` }}
          </span>
        </v-col></v-row>
      </v-col>

      <v-divider vertical />

      <!-- Subscription End Date -->
      <v-col cols="3" class="text-center">
        <!-- Heading -->
        <v-row no-gutters><v-col>
          <span class="subtitle-1 text--secondary font-weight-bold">Plan Ends</span>
        </v-col></v-row>
        <!-- Value -->
        <v-row no-gutters><v-col>
          <span :class="subscriptionCanceling ? 'error--text' : 'text--secondary'" class="text-h6">
            {{ subscriptionCanceling ? dateFormatStripe(currentSubscription.cancel_at) : '-' }}
          </span>
        </v-col></v-row>
      </v-col>

    </v-row>
  </v-col></v-row>

</template>

<script>
import {mapGetters} from "vuex";
import utilityMixin from "@/mixins/utilityMixin";

export default {
  name: "subscriptionSummary",
  mixins: [utilityMixin],
  computed: {
    ...mapGetters({
      user: "user",
      currentSubscription: "currentSubscription",
      subscriptionActive: "subscriptionActive",
      subscriptionCanceling: "subscriptionCanceling"
    })
  }
}
</script>

<style scoped>

</style>