<template>

  <!-- Account/Subscription Page Container -->
  <v-row v-if="user.customer" justify="center" no-gutters><v-col cols="6">

    <!-- Account/Subscription Title -->
    <v-row no-gutters><v-col class="text-center">
      <span class="text-h6 text--secondary font-weight-bold">ScorePro Account</span>
    </v-col></v-row>
    <v-row class="mb-5" no-gutters><v-col class="text-center">
      <span class="subtitle-2 text--secondary">Manage your account and subscription below</span>
    </v-col></v-row>

    <!-- Subscription Summary -->
    <v-row no-gutters><v-col>
      <v-divider />
      <subscription-summary class="pt-5 pb-5" />
      <v-divider />
    </v-col></v-row>

    <!-- Account Details -->
    <v-row justify="center" class="pl-5 pr-5 pt-5 pb-5" no-gutters><v-col>
      <account-summary />
    </v-col></v-row>
    <v-divider class="mb-3" />

    <!-- Account Password Actions -->
    <v-row align="center" class="pl-5 pr-5 pb-3" no-gutters>
      <!-- Reset Password Title -->
      <v-col cols="6" class="text-left">
        <v-row no-gutters><v-col>
          <span class="subtitle-1 text--secondary font-weight-bold">Credentials</span>
        </v-col></v-row>
        <v-row no-gutters><v-col>
          <span class="caption text--secondary">Reset your account password?</span>
        </v-col></v-row>
      </v-col>
      <!-- Reset Password btns -->
      <v-col cols="6" class="text-right">
        <v-btn @click="resetAccountPassword" width="200" class="ml-3" outlined text>
          <span class="caption font-weight-bold text--secondary">Reset Password</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="mb-3" />


    <!-- Subscription Actions (Cancel/Reactivate) -->
    <v-row align="center" class="pl-5 pr-5" no-gutters>

      <!-- Cancel/Reactivate Title/Description -->
      <v-col cols="6" class="text-left">
        <v-row no-gutters><v-col>
          <span class="subtitle-1 text--secondary font-weight-bold">Subscription</span>
        </v-col></v-row>
        <v-row no-gutters><v-col>
          <span v-if="subscriptionActive && !subscriptionCanceling" class="caption text--secondary">
            Cancel your subscription at the end of the plan period?
          </span>
          <span v-else-if="subscriptionActive && subscriptionCanceling" class="caption text--secondary">
            Reactivate your subscription before the period ends?
          </span>
          <span v-else class="caption text--secondary">
            Create new subscription to ScorePro?
          </span>
        </v-col></v-row>
      </v-col>

      <!-- Cancel/Reativate Buttons -->
      <v-col cols="6" class="text-right">
        <!-- Cancel Subscription -->
        <v-btn v-if="subscriptionActive && !subscriptionCanceling" @click="cancelSubscription" width="200" class="ml-3 error--text" outlined text :disabled="attemptCancelSubscription">
          <v-progress-circular v-if="attemptCancelSubscription" :size="20" :width="2" color="error" indeterminate />
          <div v-else>
            <span class="caption font-weight-bold">Cancel</span>
          </div>
        </v-btn>
        <!-- Reactivate Subscription -->
        <v-btn v-else-if="subscriptionActive && subscriptionCanceling" @click="reactivateSubscription" width="200" class="ml-3 success--text" outlined text :disabled="attemptReactivateSubscription">
          <v-progress-circular v-if="attemptReactivateSubscription" :size="20" :width="2" color="success" indeterminate />
          <div v-else>
            <span class="caption font-weight-bold success--text">Reactivate</span>
          </div>
        </v-btn>
        <!-- Create New Subscription -->
        <v-btn v-else width="200" class="ml-3 success--text" @click="navigateTo('purchase')" outlined text>
            <span class="caption font-weight-bold success--text">Subscribe</span>
        </v-btn>
      </v-col>

    </v-row>
    <v-divider class="mt-3" />

    <!-- success/error dialogs -->
    <success-dialog :display="successDialogDisplay" :message="successDialogMessage" @close="successDialogDisplay=false" />
    <error-dialog :display="errorDialogDisplay" :message="errorDialogMessage" @close="errorDialogDisplay=false" />

    <!-- Confirmation Dialog -->
    <confirm-dialog ref="confirmCancel" />
    <confirm-dialog ref="confirmReactivate" />

  </v-col></v-row>

</template>

<script>
import { navigateTo } from '@/routes/navigation';
import { mapGetters } from "vuex";
import subscriptionSummary from "@/pages/application/accountsPage/components/subscriptionSummary";
import {cancelSubscription, reactivateSubscription} from "@/plugins/firebase/functions/subscription";
import AccountSummary from "@/pages/application/accountsPage/components/accountSummary";
import accountMixin from "@/mixins/accountMixin";
import dialogMixin from "@/mixins/componentMixins/dialogMixin";

export default {
  name: "accountsPage",
  mixins: [accountMixin, dialogMixin],
  components: {
    AccountSummary,
    subscriptionSummary
  },
  computed: {
    ...mapGetters({
      user: "user",
      currentSubscription: "currentSubscription",
      subscriptionCanceling: "subscriptionCanceling",
      subscriptionActive: "subscriptionActive"
    })
  },
  data: () => ({
    // subscription actions
    attemptCancelSubscription: false,
    attemptReactivateSubscription: false
  }),
  // methods for component
  methods: {
    // ACTION METHODS
    // method: reset password, send reset email
    resetAccountPassword () {
      // send email to account
      this.sendAccountPasswordEmail(this.user)
        .then(() => {
          // success, display message
          this.alertSuccess('Password Reset Email successfully sent!');
        })
        .catch(error => {
          // error occurred, display message
          this.alertError('An error occurred sending reset link, contact support if the issue persists.');
          throw error;
        })
    },

    // method: cancel current subscription
    async cancelSubscription () {
      // launch confirmation dialog for cancelling of subscription
      if (await this.$refs.confirmCancel.open('Cancel your subscription?')) {
        // set attempting action to initiate spinner on btn
        this.attemptCancelSubscription = true;
        // building subscription data for cancellation
        let data = {"subscriptionId": this.currentSubscription.id}
        // attempt to cancel subscription
        cancelSubscription(data)
            .then(() => {
              // on success, wait 5s before indicating
              setTimeout(() => { this.attemptCancelSubscription = false; }, 3000);
              // success, display message
              this.alertSuccess('Subscription successfully cancelled!');
            })
            .catch(error => {
              // stop spinner on btn
              this.attemptCancelSubscription = false;
              // error occurred, display message
              this.alertError('An error occurred cancelling your subscription, contact support if the issue persists.');
              throw error;
            });
      }
    },
    // method: reactive subscription before period ends
    async reactivateSubscription () {
      // launch confirmation dialog for cancelling of subscription
      if (await this.$refs.confirmReactivate.open('Reactivate your subscription?')) {
        // set attempting action to initiate spinner on btn
        this.attemptReactivateSubscription = true;
        // building subscription data for cancellation
        let data = {"subscriptionId": this.currentSubscription.id}
        // attempt to cancel subscription
        reactivateSubscription(data)
            .then(() => {
              // on success, wait 5s before indicating
              setTimeout(() => { this.attemptReactivateSubscription = false; }, 3000);
              // success, display message
              this.alertSuccess('Subscription successfully reactivated!');
            })
            .catch(error => {
              // stop spinner on btn
              this.attemptReactivateSubscription = false;
              // error occurred, display message
              this.alertError('An error occurred reactivating your subscription, contact support if the issue persists.');
              throw error;
            });
      }
    },
    // UTILITY METHODS
    // method: calls navigate function
    navigateTo(nextRouteName) { navigateTo(nextRouteName); },
  }
}
</script>

<style scoped>

</style>