<template>

  <!-- Events Panel Container -->
  <v-row no-gutters><v-col>

    <!-- Events Actions -->
    <v-row no-gutters><v-col>
      <events-actions :content-height="actionsContentHeight"
                      @searchValue="updateSearchValue"/>
    </v-col></v-row>

    <v-divider class="divider-opacity" />

    <!-- Events Table -->
    <v-row no-gutters><v-col>
      <events-table :header-height="headerHeight"
                    :record-height="recordHeight"
                    :search-by="searchValue"/>
    </v-col></v-row>

  </v-col></v-row>

</template>

<script>
import eventsTable from "@/pages/application/dashboardPage/panels/eventsPanel/components/eventsTable";
import eventsActions from "@/pages/application/dashboardPage/panels/eventsPanel/components/eventsActions";

export default {
  name: "eventsPanel",
  components: {
    eventsActions,
    eventsTable
  },
  data: () => ({
    // styling data -- TODO: Move to Mixin
    actionsHeaderHeight: 48,
    actionsContentHeight: 90,
    headerHeight: 48,
    recordHeight: 72,
    // search/filter/sort data
    searchValue: ''

  }),
  methods: {
    // UTILITY METHODS
    // method: update search criteria
    updateSearchValue (searchValue) { this.searchValue = searchValue; }
  }
}
</script>

<style scoped>

</style>