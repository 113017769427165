<template>

  <!-- Event Score Table Header Container -->
  <v-row no-gutters><v-col>
    <v-card :height="height" color="secondary" class="rounded-0 elevation-0">
      <v-row :height="height" no-gutters align="center" class="fill-inner-parent-ht">

        <!-- Player Number/Index -->
        <v-col cols="1" class="align-center text-center player-index-width">
          <span class="text-subtitle-2 text--secondary font-weight-bold">#</span>
        </v-col>
        <!-- Player/Team Name -->
        <v-col cols="2" class="align-center">
          <span class="text-subtitle-2 text--secondary font-weight-bold">Player</span>
        </v-col>
        <!-- Player Scorecard -->
        <v-col cols="8" class="text-left">
          <span class="text-subtitle-2 text--secondary font-weight-bold">Scorecard</span>
        </v-col>
        <!-- Player Row Actions -->
        <v-col cols="1" class="text-center"></v-col>

      </v-row>
    </v-card>
  </v-col></v-row>

</template>

<script>
export default {
  name: "scoreTableHeader",
  props: {
    height: Number
  }
}
</script>

<style scoped>

.player-index-width {
  max-width: 70px !important;
}

</style>