<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 383.25 500"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M208.81,229.68v54.49C316,306.61,327.43,341.38,327.43,373c0,46.86-35.61,88.49-122.84,88.49-113.47,0-170-70.63-189-92.16L0,426.8C28.64,455.19,99.2,500,191.63,500c106.45,0,191.62-48.77,191.62-134.63C383.25,292.24,327.84,259.68,208.81,229.68Z" style="fill:white"/><path d="M175,277.19v80.38c-9.69,1.15-16.84,5.18-16.84,10,0,5.69,10.06,10.31,22.47,10.31s22.48-4.62,22.48-10.31c0-4.81-7.16-8.84-16.84-10V180.91l82.69-47L186.26,87h0L175,80.55v141l-4.74-1.08C81.13,200.36,51.43,173.63,51.43,123c0-46.38,52.29-87,109.25-87C278,36,322.21,107.49,341.07,130.88L356.59,74.3C321.68,43.37,272.32,0,174.05,0,76.31,0,.42,61.88.42,138.8.42,221.15,66.25,253.49,175,277.19Z" style="fill:white"/></g></g></svg>
</template>

<script>
export default {
  name: "scoreproMarkGreenIcon"
}
</script>

<style scoped>

</style>