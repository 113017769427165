<template>

  <!-- Events Actions Container -->
  <v-row no-gutters><v-col>
    <!-- Actions Expansion Panel -->
    <v-expansion-panels v-model="actionsExpansion" class="rounded-0 elevation-0" flat tile>
      <v-expansion-panel key="utilities" class="pa-0 rounded-0 secondary darken-1">
        <!-- Actions Header -->
        <v-expansion-panel-header>
          <!-- Icon and Header -->
          <v-row align="center" no-gutters><v-col>
            <v-icon class="text--secondary" small>settings</v-icon>
            <span class="ml-3 text--secondary caption font-weight-bold">ACTIONS</span>
          </v-col></v-row>
          <!-- Dropdown Icon Template -->
          <template v-slot:actions>
            <v-icon class="text--secondary">expand_more</v-icon>
          </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-divider class="divider-opacity" />
          <!-- Action Panel -->
          <v-card :height="contentHeight" color="secondary darken-2" class="pa-5 rounded-0 elevation-0">
            <v-row :height="contentHeight" align="center" class="fill-inner-parent-ht text-right" no-gutters>
              <v-col class="text-left">
                <!-- Search/Filter Field -->
                <v-text-field v-model="searchBy" @keyup="$emit('searchValue', searchBy)" label="Search Events"
                              class="search-form" append-icon="search" outlined dense hide-details clearable />
              </v-col>

              <v-col class="text-right">
                <!-- Add Event Button -->
                <v-btn @click="showEventDialog" class="success--text" text outlined>
                  <v-icon small>fa-plus</v-icon>
                  <span class="ml-3 caption font-weight-bold">Add Event</span>
                </v-btn>
              </v-col>

            </v-row>
          </v-card>
          <v-divider class="divider-opacity" />
        </v-expansion-panel-content>

      </v-expansion-panel>
    </v-expansion-panels>

    <!-- Add Event Modal -->
    <event-dialog :key="displayKey" :event-title="'Create New Event'" :display="displayEventDialog" @closeDialog="hideEventDialog" />

  </v-col></v-row>

</template>

<script>
import eventDialog from "@/components/dialogs/entities/eventDialog";

export default {
  name: "eventsActions",
  components: {
    eventDialog
  },
  props: {
    contentHeight: Number
  },
  data: () => ({
    // styling data
    actionsExpansion: 0,
    // display data
    displayKey: 1,
    displayEventDialog: false,
    // search/filter data
    searchBy: ''
  }),
  methods: {
    // methods: show/hide add events dialog
    showEventDialog() {
      this.displayKey += 1;
      this.displayEventDialog = true;
    },
    hideEventDialog() { this.displayEventDialog = false; }
  }
}
</script>

<style scoped>

.search-form {
  max-width: 400px !important;
  opacity: 0.6 !important;
}

/* remove expansion panel padding */
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

</style>