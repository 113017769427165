<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path xmlns="http://www.w3.org/2000/svg" d="m492 38h-87.5v-18c0-11.046875-8.953125-20-20-20-7.25 0-249.753906 0-257 0-11.046875 0-20 8.953125-20 20v18h-87.5c-11.046875 0-20 8.953125-20 20v53c0 64.34375 48.097656 117.65625 110.230469 125.890625 9.039062 46.773437 40.054687 86.589844 83.800781 106.605469-7.363281 30.332031-34.570312 52.503906-67.03125 52.503906-1.398438 0-8.332031 0-11 0-9.085938 0-17.027344 6.125-19.339844 14.910156l-20 76c-3.335937 12.675782 6.238282 25.089844 19.339844 25.089844h320c13.101562 0 22.675781-12.414062 19.339844-25.089844l-20-76c-2.3125-8.785156-10.253906-14.910156-19.339844-14.910156-2.667969 0-9.601562 0-11 0-32.460938 0-59.667969-22.171875-67.03125-52.503906 43.746094-20.015625 74.761719-59.832032 83.800781-106.605469 62.132813-8.230469 110.230469-61.546875 110.230469-125.890625v-53c0-11.046875-8.953125-20-20-20zm-384.5 157.796875c-38.613281-8.875-67.5-43.527344-67.5-84.796875v-33h67.5zm273.082031 240.203125 9.472657 36c-7.5625 0-260.546876 0-268.109376 0l9.472657-36zm-16.082031-227.5c0 47.027344-30 88.464844-74.652344 103.113281-8.496094 2.785157-14.109375 10.871094-13.75 19.800781.953125 23.847657 9.566406 46.351563 24.515625 64.585938-10.792969 0-78.359375 0-89.226562 0 14.953125-18.234375 23.566406-40.738281 24.519531-64.585938.355469-8.929687-5.257812-17.015624-13.75-19.800781-44.65625-14.648437-74.65625-56.085937-74.65625-103.113281 0-6.464844 0-160.417969 0-168.5h217zm107.5-97.5c0 41.269531-28.886719 75.921875-67.5 84.796875v-117.796875h67.5zm0 0" fill="#1f91cd" data-original="#000000" style="" class=""/></g></svg>
</template>

<script>
export default {
  name: "scoreboardIconComponent"
}
</script>

<style scoped>

</style>