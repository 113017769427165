<template>

  <!-- Event Live Page Container -->
  <v-row v-if="getEvent(viewerId)" no-gutters><v-col>

    <!-- Event Live Header -->
    <v-row class="pb-5" no-gutters>
      <!-- Back Button
      <v-col cols="4">
        <v-btn @click="previousRoute === 'eventAdmin' ? viewEventAdmin(eventId) : navigateTo(previousRoute)" plain fab><v-icon>arrow_back</v-icon></v-btn>
      </v-col>
       -->


      <!-- Event Title -->
      <v-col class="pt-3">
        <span class="text-h5 font-weight-bold text--secondary">{{ getEvent(viewerId).name }}</span>
      </v-col>
      <!-- Event Actions -->
      <v-col class="pt-4 text-right">
        <!-- Auto-scroll Enable/Disable -->
        <v-btn v-if="!fullscreen" @click="scrollEnabled = !scrollEnabled" width="140px"
               class="mr-3 text--secondary caption" text outlined small>
          <span class="font-weight-bold">AutoScroll </span>
          <span class="ml-2">{{ (scrollEnabled ? 'ON': 'OFF')}}</span>
        </v-btn>
        <!-- Toggle Fullscreen Scoreboard -->
        <v-btn v-if="!fullscreen" @click="toggleFullscreen" class="text--secondary" text outlined small>
          <v-icon small>zoom_out_map</v-icon>
        </v-btn>
      </v-col>

    </v-row>

    <!-- Full screen ability for live event scores -->
    <fullscreen ref="fullscreen" @change="fullscreenChange" :class="fullscreen ? 'pa-5 fullscreen-bg' : ''"
                style="width: 100%; height: 90%;">
      <!-- Event Live Score Table -->
      <v-row no-gutters><v-col>
        <live-score-table :header-color="headerColor"
                          :header-height="headerHeight"
                          :header-detail-color="headerDetailColor"
                          :header-detail-height="headerHeight"
                          :record-color="recordColor"
                          :record-height="recordHeight"
                          :event="getEvent(viewerId)"
                          :scroll-enabled="scrollEnabled"
        />
      </v-col></v-row>
    </fullscreen>

  </v-col></v-row>

</template>

<script>
import Vue from 'vue';
import fullscreen from 'vue-fullscreen';
import { navigateTo } from "@/routes/navigation";
import eventsMixin from "@/mixins/firestoreMixins/eventsMixin";
import liveScoreTable from "@/pages/application/eventPages/livePage/components/liveScoreTable";
// include full screen and autoscroll
Vue.use(fullscreen);


export default {
  name: "eventLivePage",
  components: {
    liveScoreTable
  },
  mixins: [eventsMixin],
  props: {
    eventId: String,
    viewerId: String
  },
  data: () => ({
    // styling data -- TODO: Move to Mixin
    headerColor: "#122F44", //#122F44
    headerHeight: 48,
    headerDetailColor: "#10293e", //#122F44
    headerDetailHeight: 32,
    recordColor: "transparent",
    recordHeight: 48,
    // full screen data
    fullscreen: false,
    // navigation data
    previousRoute: 'dashboard',
    // scrolling enabled
    scrollEnabled: false
  }),
  methods: {
    // UTILITY METHODS
    // method: toggle fullscreen mode
    toggleFullscreen () { this.$refs['fullscreen'].toggle({ wrap: true }) },
    fullscreenChange (fullscreen) { this.fullscreen = fullscreen },
    // method: calls navigate function
    navigateTo (nextRouteName) { navigateTo(nextRouteName); }
  },
  beforeMount() {
    // beforeMount: bind event viewer to db
    this.attemptCreateEventViewer(this.eventId, this.viewerId);
  },
  beforeDestroy () {
    // beforeDestroy: perform event viewer unbinding
    this.attemptDestroyEventViewer(this.viewerId);
  },
  beforeRouteEnter (to, from, next) {
    // beforeRouteEnter: set data with previous route name
    next(vm => { vm.previousRoute = from.name; });
  }
}
</script>

<style scoped>

.back-col-width {
  max-width: 70px !important;
}

.fullscreen-bg {
  /* background */
  background: linear-gradient(#021521, #062536) !important;
  /* hide scrollbar when fullscreen */
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}
/* hide scrollbar when fullscreen */
.fullscreen-bg::-webkit-scrollbar {
  display: none;
}

</style>