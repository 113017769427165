<template>

  <!-- Events Table Header Container -->
  <v-row no-gutters><v-col>
    <v-card :height="height" color="secondary" class="rounded-0 elevation-0">
      <v-row :height="height" no-gutters align="center" class="fill-inner-parent-ht">

        <!-- Event Type Icon (League, Tournament, Etc...) -->
        <v-col cols="1" class="align-center table-icon-width"></v-col>
        <!-- Event Name -->
        <v-col class="align-center">
          <span class="text-subtitle-2 text--secondary font-weight-bold">Event Name</span>
        </v-col>
        <!-- Event Date -->
        <v-col cols="1" class="text-left">
          <span class="text-subtitle-2 text--secondary font-weight-bold">Date</span>
        </v-col>
        <!-- Course -->
        <v-col cols="2">
          <span class="text-subtitle-2 text--secondary font-weight-bold">Course</span>
        </v-col>
        <!-- Course Side -->
        <v-col cols="1">
          <span class="text-subtitle-2 text--secondary font-weight-bold">Side</span>
        </v-col>
        <!-- Hole Count -->
        <v-col cols="1" class="text-center">
          <span class="text-subtitle-2 text--secondary font-weight-bold">Holes</span>
        </v-col>
        <!-- Flights -->
        <v-col cols="1" class="text-center">
          <span class="text-subtitle-2 text--secondary font-weight-bold">Flights</span>
        </v-col>
        <!-- Players/Teams -->
        <v-col cols="1" class="text-center">
          <span class="text-subtitle-2 text--secondary font-weight-bold">Players</span>
        </v-col>
        <!-- Event Live -->
        <v-col cols="1" class="text-center">
          <span class="text-subtitle-2 text--secondary font-weight-bold">Live</span>
        </v-col>
        <!-- Event Actions -->
        <v-col cols="1" class="text-center text--secondary "></v-col>

      </v-row>
    </v-card>
  </v-col></v-row>

</template>

<script>
export default {
  name: "eventsTableHeader",
  props: {
    height: Number
  }
}
</script>

<style scoped>

.table-icon-width {
  max-width: 100px !important;
}

</style>