<template>

  <!-- Register Container -->
  <v-row justify="center" align="center" class="fill-parent-ht" no-gutters><v-col align="center" xl="6" lg="8" md="10" sm="12">

    <v-card color="secondary darken-2" class="pt-15 login-size rounded-xl elevation-0">
      <!-- Login Header -->
      <v-row justify="center" align="center" no-gutters><v-col class="text-center">
        <span class="text-h4 font-weight-bold">Create Account</span>
      </v-col></v-row>
      <!-- Heading Catch-line Sub-text -->
      <v-row justify="center" align="center" class="mt-1" no-gutters><v-col class="text-center">
        <span class="subtitle-1 subtext-style font-weight-medium">Welcome! Create your account below</span>
      </v-col></v-row>

      <!-- Enter Name -->
      <v-form ref="form" v-model="isValid" method="post">
        <v-row class="mt-10" no-gutters><v-col class="text-left">
          <span class="caption text--secondary">Name</span>
        </v-col></v-row>
        <v-row no-gutters><v-col>
          <v-text-field v-model="name" :rules="nameRules" :error-count="1" append-icon="account_circle" outlined required hide-details />
        </v-col></v-row>

        <!-- Enter Email -->
        <v-row class="mt-3" no-gutters><v-col class="text-left">
          <span class="caption text--secondary">Email Address</span>
        </v-col></v-row>
        <v-row no-gutters><v-col>
          <v-text-field v-model="email" :rules="emailRules" :error-count="1" append-icon="email" outlined required hide-details />
        </v-col></v-row>

        <!--Enter Password -->
        <v-row class="mt-3" no-gutters><v-col class="text-left">
          <span class="caption text--secondary">Password</span>
        </v-col></v-row>
        <v-row no-gutters><v-col>
          <v-text-field v-model="password" :rules="passwordRules" :error-count="1" type="password"
                        append-icon="fa-key" outlined required hide-details />
        </v-col></v-row>

        <!-- Confirm Password -->
        <v-row class="mt-3" no-gutters><v-col class="text-left">
          <span class="caption text--secondary">Confirm Password</span>
        </v-col></v-row>
        <v-row no-gutters><v-col>
          <v-text-field v-model="re_password" :rules="[(password === re_password) || 'Password must match']"
                        :error-count="1" v-on:keyup.enter="register" type="password" outlined required hide-details
                        :append-icon="(password === re_password) && (re_password) ? 'check_circle_outline' : 'cancel'"  />
        </v-col></v-row>


      </v-form>

      <!-- Login Button -->
      <v-row justify="center" align="center" class="mt-10" no-gutters><v-col>
        <v-btn @click="register" color="info" :disabled="!isValid" block large>
          <v-progress-circular v-if="attemptingRegister" :size="20" :width="2" color="white" indeterminate />
          <div v-else><span>Create Account</span></div>
        </v-btn>
      </v-col></v-row>

      <!-- OR -->
      <v-row class="mt-8" no-gutters>
        <v-col cols="5" class="pt-3"><v-divider /></v-col>
        <v-col cols="2"><span class="text--secondary subtext-style font-weight-bold">OR</span></v-col>
        <v-col cols="5" class="pt-3"><v-divider /></v-col>
      </v-row>

      <!-- Login with Google or Facebook -->


      <!-- Don't have account? Create Account now -->
      <v-row class="mt-5" no-gutters><v-col class="text-center">
        <span class="subtitle-2 text--secondary">
          <span class="subtext-style">Already have an account?</span>
          <v-btn @click="navigateTo('login')" color="info" class="ml-3 pa-0 font-weight-medium" text small plain>Login here</v-btn>
        </span>
      </v-col></v-row>

    </v-card>

    <!-- Registration Error -->
    <v-fade-transition>
      <v-row v-if="error" justify="center" align="center" class="mt-2" no-gutters><v-col>
        <v-alert width="550" color="transparent" class="error--text subtitle-1 font-weight-medium" dense>{{ error }}</v-alert>
      </v-col></v-row>
    </v-fade-transition>

  </v-col></v-row>

</template>

<script>
import { firebaseRegister } from "@/plugins/firebase/security";
import { navigateTo } from "@/routes/navigation";

export default {
  name: "registerPage",
  data: () => ({
    // attempting to register
    attemptingRegister: false,
    // form contents
    name: null,
    email: null,
    password: null,
    re_password: null,
    // disable button until input valid
    isValid: true,
    error: null,
    // form validation rules
    // name form rules
    nameRules: [
      v => (v && v.length >= 5) || 'Name must have 5+ characters',
    ],
    // email form rules
    emailRules: [
      v => !!v || 'Email is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid'
    ],
    // password form rules
    passwordRules: [
      v => !!v || 'Password is required',
      v => (v && v.length >= 5) || 'Password must have 5+ characters'
    ],

  }),
  methods: {
    // register submission
    register() {
      // set attemptingCheckout
      this.attemptingRegister = true;
      // info payload
      let user_payload = {"email": this.email, "password": this.password, "name": this.name}
      // call firebase registration
      firebaseRegister(user_payload)
          .then(() => {
            // success, navigate to purchase page
            navigateTo('purchase')
          })
          .catch(error => {
            // checkout/register failed
            this.attemptingRegister = false;
            // print error message
            this.error = error.message
          });
    },
    // method: calls navigate function
    navigateTo(nextRouteName) { navigateTo(nextRouteName); }
  }
}
</script>

<style scoped>

.login-size {
  width: 550px !important;
  height: 800px !important;
  padding-right: 80px !important;
  padding-left: 80px !important;
}
.subtext-style {
  opacity: 0.3 !important;
}

</style>