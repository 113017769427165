<template>

  <!-- Confirmation Save Dialog -->
  <v-dialog v-model="dialog" max-width="350" class="elevation-0" persistent>

    <v-card color="secondary">
      <!-- Confirm Message -->
      <v-card-text class="pt-5 subtitle-1 font-weight-bold text-center">{{ message }}</v-card-text>
      <!-- Dialog Actions -->
      <v-card-actions class="pt-0 pb-5">
        <v-row class="text-center" no-gutters><v-col cols="12">
          <!-- Do not save before navigation -->
          <v-btn @click="cancel" class="text--secondary" width="80px" small outlined>No</v-btn>
          <!-- Save before navigation -->
          <v-btn @click="agree" class="success--text ml-3" width="80px" small outlined>Yes</v-btn>
        </v-col></v-row>
      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>
export default {
  name: "confirmDialog",
  data: () => ({
    // dialog display
    dialog: false,
    // dialog contents
    message: null,
    // dialog actions
    resolve: null,
    reject: null
  }),
  methods: {
    // method: open dialog
    open (message) {
      // set component data for dialog
      this.dialog = true;
      this.message = message;
      // return promise, user response
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      })
    },
    // method: agree/confirm to action
    agree () { this.resolve(true); this.dialog = false; },
    // method: agree/confirm to action
    cancel () { this.resolve(false); this.dialog = false; }
  }
}
</script>

<style>


</style>
