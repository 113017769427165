// import vue and vue-router and store
import VueRouter from 'vue-router';
import Vue from 'vue';
import store from "@/session/store";

// page component imports
import homePage from "@/pages/public/homePage"
import pricingPage from "@/pages/public/pricingPage"
import loginPage from "@/pages/public/loginPage"
import forgotPassword from "@/pages/public/forgotPasswordPage"
import resetPassword from "@/pages/public/resetPasswordPage"
import supportPage from "@/pages/public/supportPage"
import registerPage from "@/pages/public/registerPage"

// application component imports
import dashboardPage from "@/pages/application/dashboardPage"
import eventAdminPage from "@/pages/application/eventPages/adminPage"
import eventLivePage from "@/pages/application/eventPages/livePage"
import accountsPage from "@/pages/application/accountsPage"
//import historyPage from "@/pages/application/historyPage"
import purchasePage from "@/pages/application/purchasePage"

// error component imports
import forbiddenPage from "@/pages/error/forbiddenPage"
import notFoundPage from "@/pages/error/notFoundPage"

// success component imports
import successfulCheckoutPage from "@/pages/success/successfulCheckoutPage"

Vue.use(VueRouter)


// public pages (no login or subscription required)
const publicRoutes = [
    { path: '/', name: 'home', component: homePage, meta: { position: 1 } },
    { path: '/pricing', name: 'pricing', component: pricingPage, meta: { position: 3 } },
    { path: '/support', name: 'support', component: supportPage, meta: { position: 8 } },
    { path: '/login', name: 'login', component: loginPage, meta: { position: 9 } },
    { path: '/auth/forgotPassword', name: 'forgotPassword', component: forgotPassword, meta: { position: 10 } },
    { path: '/auth/resetPassword', name: 'resetPassword', component: resetPassword, meta: { position: 10 } },
    { path: '/register', name: 'register', component: registerPage, meta: { position: 10 } },
]
// application pages (login and subscription required)
const applicationRoutes = [
    { path: '/dashboard', name: 'dashboard', component: dashboardPage, meta: { position: 1 } },
    { path: '/event/admin/:eventId/:viewerId', name: 'eventAdmin', component: eventAdminPage, meta: {  position: 3 }, props: true },
    { path: '/event/live/:eventId/:viewerId', name: 'eventLive', component: eventLivePage, meta: {  position: 4 }, props: true },
    //{ path: '/history', name: 'history', component: historyPage, meta: {  position: 6 } },
]
// application pages (only login required)
const accountRoutes = [
    { path: '/account', name: 'account', component: accountsPage, meta: {  position: 5 } },
    { path: '/purchase', name: 'purchase', component: purchasePage, meta: {  position: 7 } },
    { path: '/successfulCheckout', name: 'successfulCheckout', component: successfulCheckoutPage },
]

// error pages
const errorRoutes = [
    { path: '/forbiddenResource', name: 'forbiddenResource', component: forbiddenPage },
    { path: '*', name: 'notFoundResource', component: notFoundPage }
]

// construct new VueRouter
// publish routes above
const router = new VueRouter({
    routes: publicRoutes.concat(applicationRoutes, accountRoutes, errorRoutes), // merge all routes above
    mode: 'history'
})

// before each router request
// navigation guard + loading
router.beforeEach((to, from, next) => {
    // publicly accessible routes
    const routeExists = publicRoutes.concat(applicationRoutes, accountRoutes, errorRoutes).map(route => route.name).includes(to.name)
    const appAccessRequired = applicationRoutes.map(route => route.name).includes(to.name)
    const accountAccessRequired = accountRoutes.map(route => route.name).includes(to.name)

    // if route exists
    if (routeExists) {
        // subscription required
        if (appAccessRequired) {
            // user logged in and subscribed
            if (store.state.user.loggedIn && store.getters.subscriptionActive) {
                next();
            // use not logged in or not subscribed
            } else {
                next({name: 'forbiddenResource'});
            }
        }
        else if (accountAccessRequired) {
            // user logged in
            if (store.state.user.loggedIn) {
                next();
                // use not logged in or not subscribed
            } else {
                next({name: 'forbiddenResource'});
            }
        }
        // else, navigate to desired page
        else { next(); }
    // else, route doesn't exist
    // attempting to navigate to non-existent page
    } else {
        next({name: 'notFoundResource'});
    }


})

export { router };