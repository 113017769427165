import store from "@/session/store";
import { router } from "@/routes/router";


// function: navigate to provided route name
// :param nextRouteName: route name of route desired
function navigateTo(nextRouteName) {
    // get current route and next route
    let nextRoute = router.resolve({ name: nextRouteName });
    // if next route is not same as current
    if (!navigationRedundant(nextRoute, router.currentRoute)) {
        // find direction, positionally, of nextRoute in relation to currentRoute and set mixin data
        let transitionDirection = navigationDirection(nextRoute, router.currentRoute);
        store.dispatch('commitTransitionDirection', transitionDirection);
        // navigate to new route
        router.push({name: nextRouteName});
    }
}


// function: navigate to provided route, with parameters
// :param nextRouteName: route name of route desired
// :param params: routing parameters passed
function navigateWithParams(nextRouteName, params) {
    // get current route and next route
    let nextRoute = router.resolve({ name: nextRouteName, params: params });
    // if next route is not same as current
    if (!navigationRedundant(nextRoute, router.currentRoute)) {
        // find direction, positionally, of nextRoute in relation to currentRoute and set mixin data
        let transitionDirection = navigationDirection(nextRoute, router.currentRoute);
        store.dispatch('commitTransitionDirection', transitionDirection);
        // navigate to new route
        router.push({ name: nextRouteName, params: params });
    }
}


// function: navigate to provided route, with passed props and params
// :param nextRouteName: route name of route desired
// :param params: routing parameters passed
// :param props: routing props passed
function navigateWithParamsAndProps(nextRouteName, params, props) {
    // get current route and next route
    let nextRoute = router.resolve({ name: nextRouteName, params: params, props: props });
    // if next route is not same as current
    if (!navigationRedundant(nextRoute, router.currentRoute)) {
        // find direction, positionally, of nextRoute in relation to currentRoute and set mixin data
        let transitionDirection = navigationDirection(nextRoute, router.currentRoute);
        store.dispatch('commitTransitionDirection', transitionDirection);
        // navigate to new route
        router.push({ name: nextRouteName, params: params, props: props });
    }
}


// function: check for redundant navigation between currentRoute and nextRoute
// :param nextRoute: next route object
// :param currentRoute: current route object
function navigationRedundant(nextRoute, currentRoute) {
    // if home route (redirect) does not equal current route (during logout)
    return nextRoute.href === currentRoute.path;
}


// function: compare relative position of routes and change transitionDirection
// :param nextRoute: route object of next route
// :param currentRoute: route object of current route
function navigationDirection(nextRoute, currentRoute) {
    // next position is greater than current position
    return (nextRoute.route.meta.position > currentRoute.meta.position) ? 'left' : 'right';
}


export { navigateTo, navigateWithParams, navigateWithParamsAndProps, navigationRedundant, navigationDirection }