<template>

  <!-- Event Score Table Container -->
  <v-row v-if="event" no-gutters><v-col>

    <!-- Events Table Header -->
    <v-row no-gutters><v-col>
      <score-table-header :height="headerHeight" />
    </v-col></v-row>

    <!-- virtual scroll for players
    <v-virtual-scroll :height="windowHeight - 420" :item-height="recordHeight" bench="100" class="virtual-scroller"
                      :items="eventPlayersSorted(event)">
      <template v-slot:default="{item, index}">
        <v-row no-gutters><v-col>
          <score-table-record :ref="`${event.id}_${item.id}`"
                              :eventId="event.id"
                              :playerId="item.id"
                              :playerNo="index"
                              :player="item"
                              :event="event"
                              :height="recordHeight" />
        </v-col></v-row>
      </template>
    </v-virtual-scroll>
    -->

    <!-- Transition Group for Table Records -->
    <transition-group name="player-transition" tag="div" class="relative-player-parent">
       All Event Table Records
      <v-row v-for="(player, playerIndex) in eventPlayersSorted(event)"
             :key="player.id"
             class="player-transition-item" no-gutters><v-col>
        <score-table-record :ref="`${event.id}_${player.id}`"
                            :eventId="event.id"
                            :playerId="player.id"
                            :playerNo="playerIndex"
                            :player="player"
                            :event="event"
                            :height="recordHeight"
        />
      </v-col></v-row>
    </transition-group>

    <!-- Success/Error Dialog on save -->
    <success-dialog :display="successDialogDisplay" :message="successDialogMessage" @close="successDialogDisplay=false" />
    <error-dialog :display="errorDialogDisplay" :message="errorDialogMessage" @close="errorDialogDisplay=false" />

  </v-col></v-row>

</template>

<script>
import scoreTableHeader from "@/pages/application/eventPages/adminPage/components/scoreTableHeader";
import scoreTableRecord from "@/pages/application/eventPages/adminPage/components/scoreTableRecord";
import eventsMixin from "@/mixins/firestoreMixins/eventsMixin";
import utilityMixin from "@/mixins/utilityMixin";
import playersMixin from "@/mixins/firestoreMixins/playersMixin";
import dialogMixin from "@/mixins/componentMixins/dialogMixin";
import _ from "lodash";

export default {
  name: "scoreTable",
  mixins: [eventsMixin, utilityMixin, playersMixin, dialogMixin],
  components: {
    scoreTableHeader,
    scoreTableRecord
  },
  props: {
    // data-related props
    event: Object,
    // style props
    headerHeight: Number,
    recordHeight: Number
  },
  methods: {
    // ACTION METHODS
    // method: save all team updates to event
    async saveEventPlayers () {
      // placeholder for attemps
      let saveContainer = [];
      // loop through player records
      for (let playerId of Object.keys(this.event.players)) {
        // build ref for event player
        let player_ref = `${this.event.id}_${playerId}`;
        // execute save of players
        await this.$refs[player_ref][0].savePlayer()
          .then(response => { saveContainer.push(response); })
          .catch(error => { saveContainer.push(error); });
      }
      // extract errors and print success/error
      let errors = _.filter(saveContainer, v => v !== "success");
      if (errors.length < 1) {
        this.alertSuccess('Player scorecards saved successfully!');
        return "success"; // bubble up successful state
      }
      else {
        this.alertError(errors[0], 3000);
        return "failed" // bubble up failed state
      }
    }
  }
}
</script>

<style scoped lang="scss">

/* admin player transition */
.player-transition-item {
  transition: all 0.75s;
}
.player-transition-leave-active {
  transition: all 0.25s;
}
.player-transition-leave-to {
  opacity: 0;
}

.player-transition-enter {
  opacity: 0;
  transform: translateX(1000px);
}
.relative-player-parent {
  position: relative;
}

</style>