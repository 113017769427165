import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"
import { vuexfireMutations, firestoreAction } from "vuexfire"
import { customerCollection, subscriptionCollection } from "@/plugins/firebase/firestore"
import { courseCollection, eventCollection } from "@/plugins/firebase/firestore"
import _ from "lodash";

// Vue use vuex
Vue.use(Vuex);


const store = new Vuex.Store({
    // vuex state
    state: {
        // user entity
        user: {
            uid : null,
            loggedIn : false,
            available : false,
            customer : {},
            subscriptions : []
        },
        // application entities
        courses : [],
        events : [],
        // viewer entities
        eventViewers : {},
        // styling entities
        styling : {
            transitionDirection : 'right'
        }
    },
    // vuex store/state plugins
    plugins: [
        // vuex state is reset on page refresh
        // persist state in session storage so refresh won't clear state
        // and reset user information won't impact logged-in navigation
        createPersistedState({
            storage: window.localStorage,
        })
    ],
    // getters for state
    getters: {
        // user getters
        // getter: get user
        user: (state) => { return state.user },
        // getter: current subscription
        currentSubscription: (state) => {
            // if subscriptions exist
            if (state.user.subscriptions){
                // loop through subscriptions
                for (let i = 0; i < state.user.subscriptions.length; i++) {
                    // get this subscription
                    let subscription = state.user.subscriptions[i];
                    // potential statuses: (incomplete, incomplete_expired, trialing, active, past_due, canceled, or unpaid)
                    // active (final-state): subscription is NOT in un-editable/final state
                    if (subscription.status !== 'canceled' && subscription.status !== 'incomplete_expired') {
                        // subscription is available, in an editable states
                        return subscription;
                    }
                }
            }
            // else, nothing to return
            return null;
        },
        // getter: subscription active
        // status: (true, false)
        subscriptionActive: (state, getters) => {
            // get current subscription and status
            let subscription = getters.currentSubscription;
            let subscriptionPaused = getters.subscriptionPaused;

            // if subscription exists
            if (subscription) {
                // potential statuses: (incomplete, trialing, active, past_due, or unpaid)
                // active -- if subscription status is 'active' or 'trialing'
                if (subscription.status === 'active' || subscription.status === 'trialing') {
                    // verify subscription isn't paused
                    if (!subscriptionPaused) {
                        return true;
                    }
                }
            }
            // inactive -- no subscription, is inactive
            return false;
        },
        // getter: subscription paused
        // status: (true, false)
        subscriptionPaused: (state, getters) => {
            // get current subscription
            let subscription = getters.currentSubscription;
            // if subscription exists
            if (subscription) {
                // paused -- if pause_collection in subscription AND behavior is 'void'
                if (subscription.pause_collection && subscription.pause_collection.behavior === 'void') {
                    return true;
                }
            }
            // else, not paused
            return false;
        },
        // getter: subscription canceling
        // status: (true, false)
        subscriptionCanceling: (state, getters) => {
            // get current subscription
            let subscription = getters.currentSubscription;
            // if subscription exists
            if (subscription) {
                // canceling -- if cancel_at_period_end is true, canceling
                if (subscription.cancel_at_period_end) { return true; }
            }
            // else, not canceling
            return false;
        },
        // styling getters
        // getter: get style entity
        styling: (state) => { return state.styling },
        // course getters
        // getter: get courses
        courses: (state) => { return state.courses },
        // event getters
        // getter: get events
        events: (state) => { return state.events },
        // event view getters
        // getter: get live event by id
        eventViewer: (state) => (viewerId) => { return _.find(state.events, ['id', state.eventViewers[viewerId]]) },
    },
    // vuex mutation, updates user states
    mutations: {
        // user mutations
        // mutation: sets user authentication
        setUser(state, user) {
            state.user.uid = user.uid;
            state.user.loggedIn = user.loggedIn;
            state.user.available = true;
        },
        // live event view setters
        // mutation: set placeholder for live event viewer
        // Vue.set NECESSARY for adding new property (only reactive this way)
        createEventViewer: (state, params) => {
            Vue.set(state.eventViewers, params.viewerId, params.eventId);
        },
        // mutation: remove event from eventViewBindings
        removeEventViewer: (state, viewerId) => {
            Vue.delete(state.eventViewers, viewerId);
        },
        // mutation: remove event from eventViewBindings
        resetEventViewers: (state) => { state.eventViewers = {}; },

        // styling setters
        // mutation: set transition direction for navigation
        setTransitionDirection: (state, newDirection) => { state.styling.transitionDirection = newDirection; },

        // vuexfire mutations
        ...vuexfireMutations
    },
    // vuex actions, commit mutations to the object
    actions: {
        /**
         * Authentication Actions for Application
         */
        // user actions
        // action: commits user
        commitUser({commit}, user) {
            commit("setUser", ((user) ? { uid : user.uid, loggedIn : true } : { loggedIn : false }));
        },
        /**
         * Actions for Event Viewers
         */
        // action: set place holder for event live
        commitCreateEventViewer({commit}, params) {
            commit('createEventViewer', params);
        },
        // action: remove event from live
        commitRemoveEventViewer({commit}, viewerId) {
            commit('removeEventViewer', viewerId);
        },
        // action: reset all live events in store
        commitResetEventViewers({commit}) {
            commit('resetEventViewers');
        },

        /**
         * Styling Actions for Application
         */
        // action: commits transition direction change
        commitTransitionDirection({commit}, newDirection) {
            commit('setTransitionDirection', newDirection);
        },
        /**
         * Top-Level Grouping of Bindings for Application (init, teardown, etc...)
         */
        // actions: initialise bindings
        initBindings() {
            // bind customer information before subscription
            store.dispatch('bindCustomer').then(() => {
                store.dispatch('bindSubscriptions');
                store.dispatch('bindCourses');
                store.dispatch('bindEvents');
            });
        },
        // actions: teardown bindings
        teardownBindings() {
            //store.dispatch('unbindEventViewers');
            store.dispatch('commitResetEventViewers');

            store.dispatch('unbindEvents');
            store.dispatch('unbindCourses');
            store.dispatch('unbindSubscriptions');
            store.dispatch('unbindCustomer');
        },

        /**
         * Firestore Bindings for Account Information (User/Customer, Subscriptions)
         */
        // user stripe bindings
        bindCustomer: firestoreAction(({ bindFirestoreRef, state }) => {
            // return promise by 'bindFirestoreRef'
            return bindFirestoreRef(
                'user.customer',
                customerCollection.doc(state.user.uid)
            );
        }),
        unbindCustomer: firestoreAction(({ unbindFirestoreRef }) => {
            unbindFirestoreRef('user.customer');
        }),
        // stripe subscription bindings
        bindSubscriptions: firestoreAction(({ bindFirestoreRef, state }) => {
            if (state.user.customer) {
                return bindFirestoreRef(
                    'user.subscriptions',
                    subscriptionCollection.where('customer_id', '==', state.user.customer.stripe.customer_id)
                );
            }
        }),
        unbindSubscriptions: firestoreAction(({ unbindFirestoreRef }) => {
            unbindFirestoreRef('user.subscriptions');
        }),
        /**
         * Firestore Bindings for Application (Courses, Events, and Teams)
         */
        // course bindings
        bindCourses: firestoreAction(({ bindFirestoreRef, state }) => {
            // return promise by 'bindFirestoreRef'
            return bindFirestoreRef(
                'courses',
                courseCollection.where('creator', '==', state.user.uid));
        }),
        unbindCourses: firestoreAction(({ unbindFirestoreRef }) => {
            unbindFirestoreRef('courses');
        }),
        // event bindings
        bindEvents: firestoreAction(({ bindFirestoreRef, state }) => {
            // return promise by 'bindFirestoreRef'
            return bindFirestoreRef(
                'events',
                eventCollection.where('creator', '==', state.user.uid));
        }),
        unbindEvents: firestoreAction(({ unbindFirestoreRef }) => {
            unbindFirestoreRef('events');
        }),


        /***

        // live event viewer bindings (dynamic navigation)
        bindEventViewer: firestoreAction(({ bindFirestoreRef }, bindParams) => {
            // return promise by 'bindFirestoreRef'
            return bindFirestoreRef(
                `eventViewers.${ bindParams.viewerId }`,
                eventCollection.doc(bindParams.eventId)
            );
        }),
        unbindEventViewer: firestoreAction(({ unbindFirestoreRef, dispatch }, viewerId) => {
            // perform unbinding of liveEvent
            unbindFirestoreRef(`eventViewers.${ viewerId }`);
            // delete event from store
            dispatch('commitRemoveEventViewer', viewerId);
        }),
        unbindEventViewers: firestoreAction(({ unbindFirestoreRef, state, dispatch }) => {
            // loop through live event bindings
            for (let viewerId of Object.keys(state.eventViewers)) {
                // perform unbinding of liveEvent
                unbindFirestoreRef(`eventViewers.${ viewerId }`);
                // delete event from store
                dispatch('commitRemoveEventViewer', viewerId);
            }
            // reset event lives to default state
            dispatch('commitResetEventViewers');
        }),
         ***/

    }
});

export default store;