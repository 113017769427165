<template>

  <!-- Live Score Table (Player) Record Container -->
  <v-row no-gutters><v-col>

    <!-- Score Table (Player) Record -->
    <v-card :height="height" color="transparent" class="rounded-0 elevation-0">
      <v-row :height="height" no-gutters class="fill-inner-parent-ht">

        <!-- Player Position/Index -->
        <v-col cols="1" class="pt-3 text-center player-index-width secondary darken-2">
          <v-icon v-if="(playerFlightPos + 1) === 1" class="place-icon">$vuetify.iconFirstPlace</v-icon>
          <v-icon v-else-if="(playerFlightPos + 1)  === 2">$vuetify.iconSecondPlace</v-icon>
          <v-icon v-else-if="(playerFlightPos + 1)  === 3">$vuetify.iconThirdPlace</v-icon>
          <span v-else class="text-subtitle-2 text--secondary font-weight-bold">{{ playerNo + 1 }}</span>
        </v-col>

        <!-- Player/Team Name -->
        <v-col cols="2" class="pl-1 secondary darken-2">
          <v-row  no-gutters>
            <!-- Player Name -->
            <v-col class="pt-3" cols="10">
              <span class="text--secondary font-weight-bold">{{ player.name }}</span>
            </v-col>
            <!-- Skins Enabled -->
            <v-col cols="2" class="pt-3 pr-5 text-right">
              <span v-if="event.skins && player.skins_enabled" class="ml-3">
                <v-icon class="skins-paid-icon" small>paid</v-icon>
              </span>
            </v-col>
          </v-row>
        </v-col>

        <!-- Player Scores -->
        <v-col class="fill-inner-parent-ht text-center">
          <v-row class="fill-inner-parent-ht" no-gutters>
            <template v-for="(hole, holeId) in eventHoles(event)">
              <!-- Player Hole Score -->
              <v-col :key="`${playerId}_${holeId}`" class="pt-3 text-center secondary darken-1">
                <span :class="player.skins_enabled && skinWinner(playerId, holeId) ? 'skin-winner-style' : ''">
                  <span :class="player.scorecard[holeId] || player.scorecard[holeId] === 0 ? getHoleScoreClass(player.scorecard[holeId], hole.par, skinWinner(playerId, holeId)) : ''"
                        class="font-weight-bold">
                    {{ player.scorecard[holeId] || player.scorecard[holeId] === 0 ? player.scorecard[holeId] : '-' }}
                  </span>
                </span>
              </v-col>

              <!-- CONDITIONAL RENDERINGS -->
              <!-- Score (Out) Total -->
              <v-col v-if="hole.hole === 9 && eventHoleCount(event) !== 9" :key="`${playerId}_${holeId}_out`" class="pt-3 text-center secondary darken-2">
                <span class="text--secondary font-weight-bold">{{ eventPlayerScoreOut(player.scorecard) ? eventPlayerScoreOut(player.scorecard) : '-' }}</span>
              </v-col>
              <!-- Score (In) Total -->
              <v-col v-if="hole.hole === 18 && eventHoleCount(event) !== 9" :key="`${playerId}_${holeId}_in`" class="pt-3 text-center secondary darken-2">
                <span class="text--secondary font-weight-bold">{{ eventPlayerScoreIn(player.scorecard) ? eventPlayerScoreIn(player.scorecard) : '-' }}</span>
              </v-col>
              <!-- Score Total -->
              <v-col v-if="hole.hole === 18 || hole.hole === eventHoleCount(event)" :key="`${playerId}_${holeId}_total`" class="pt-3 text-center secondary darken-2">
                <span class="text--secondary font-weight-bold">{{ eventPlayerScoreTotal(event, player.scorecard) ? eventPlayerScoreTotal(event, player.scorecard) : '-' }}</span>
              </v-col>
              <!-- Player Handicap -->
              <v-col v-if="(hole.hole === 18 || hole.hole === eventHoleCount(event)) && event.handicap" :key="`${playerId}_${holeId}_handicap`" class="pt-3 text-center secondary darken-2">
                <span class="text--secondary font-weight-bold">{{ player.handicap ? player.handicap : '-' }}</span>
              </v-col>
              <!-- Score To Par -->
              <v-col v-if="hole.hole === 18 || hole.hole === eventHoleCount(event)" :key="`${playerId}_${holeId}_topar`" class="pt-3 pr-5 text-center secondary darken-2">
                <!-- Score Under Par -->
                <span v-if="eventPlayerScoreToPar(event, player) < 0" class="text--secondary font-weight-bold">
                  {{ eventPlayerScoreToPar(event, player) }}
                </span>
                <!-- Score Over Par -->
                <span v-else-if="eventPlayerScoreToPar(event, player) > 0" class="text--secondary font-weight-bold">
                  {{ `+${eventPlayerScoreToPar(event, player)}` }}
                </span>
                <!-- Score Even Par -->
                <span v-else class="text--secondary font-weight-bold">E</span>
              </v-col>

            </template>
          </v-row>
        </v-col>

      </v-row>
    </v-card>

    <v-divider class="divider-opacity" />
  </v-col></v-row>

</template>

<script>
import eventsMixin from "@/mixins/firestoreMixins/eventsMixin";


export default {
  name: "liveScoreRecord",
  mixins: [eventsMixin],
  props: {
    // data-related props
    event: Object,
    playerSkins: Object,
    playerFlightPos: Number,
    playerNo: Number,
    playerId: String,
    player: Object,
    // styling props
    height: Number
  },
  data: () => ({

  }),
  methods: {
    // UTILITY METHODS
    // method: checks if player in player skins, if so, checks if they've won hole in question
    skinWinner (playerId, holeId) {
      return this.playerSkins[playerId] ? this.playerSkins[playerId].includes(holeId) : false;
    },
    // method: get hole score styling class, based on score in-relation to par
    getHoleScoreClass (holeScore, holePar, holeSkinWinner) {
      // calculate scoreToPar for hole
      let scoreToPar = holeScore - holePar;
      // handle all styling possibilities
      if (holeSkinWinner) { return 'skin-winner-text primary' }

      // : eagles or better
      else if (scoreToPar < -1) { return 'birdie-under-style text--secondary' }
      // : birdies
      else if (scoreToPar === -1) { return 'birdie-style text--secondary' }
      // : pars
      else if (scoreToPar === 0) { return 'text--secondary' }
      // : bogeys
      else if (scoreToPar === 1) { return 'bogey-style text--secondary' }
      // : bogeys
      else if (scoreToPar > 1) { return 'bogey-over-style text--secondary' }
    }
  }
}
</script>

<style scoped>

/* hole score styling */
.birdie-under-style {
  border-style: double;
  border-width: 6px;
  border-radius: 50%;
  border-color: rgba(255, 68, 68, 0.5);
  padding: 2px 7px 2px 7px !important;
}
.birdie-style {
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  border-color: rgba(255, 68, 68, 0.5);
  padding: 5px 10px 5px 10px !important;
}
.bogey-style {
  border-style: solid;
  border-width: 1px;
  border-color: rgba(58, 181, 58, 0.4);
  padding: 5px 10px 5px 10px;
}
.bogey-over-style {
  border-style: solid;
  border-width: 1px;
  border-color: rgba(58, 181, 58, 0.4);
  padding: 2px 7px 2px 7px;
  outline: 1px solid rgba(58, 181, 58, 0.4);
  outline-offset: 2px;
}
.bogey-over-style:before {
  border-style: solid;
  border-width: 1px;
  border-color: #CF2132;
  padding: 10px 15px 10px 15px;
}

/* skin winner styling */
.skins-paid-icon {
  color: #E0DD28 !important;
  opacity: 0.75 !important;
}
.skin-winner-style {
  padding: 6px 11px 6px 11px !important;
  border-radius:50%;
  background-color: #E0DD28;
  opacity: 0.75 !important;
}
.skin-winner-text {
  color: #122F44;
}

.player-index-width {
  max-width: 70px !important;
}
.place-icon {
  opacity: 0.9;
}


</style>