import _ from "lodash";
import { courseCollection } from "@/plugins/firebase/firestore";


/*************************************************************
 Mixin for Events in Firestore
**************************************************************/

var coursesMixin = {

    data: () => ({
        // hole keys for calculations
        frontHoles: ['hole_01', 'hole_02', 'hole_03', 'hole_04', 'hole_05', 'hole_06', 'hole_07', 'hole_08', 'hole_09'],
        backHoles: ['hole_10', 'hole_11', 'hole_12', 'hole_13', 'hole_14', 'hole_15', 'hole_16', 'hole_17', 'hole_18']
    }),

    methods: {
        // COMPUTED METHODS
        // computed: course hole count
        courseHoleCount (course) { return Object.keys(course.holes).length },
        // computed: calculate course front par
        courseFrontPar (course) {
            let par = 0;
            this.frontHoles.forEach((hole) => { par += parseInt(course['holes'][hole]['par']); });
            return par
        },
        // computed: calculate course back par
        courseBackPar (course) {
            let par = 0;
            this.backHoles.forEach((hole) => { par += parseInt(course['holes'][hole]['par']); });
            return par
        },
        // computed: calculate course par
        coursePar (course) {
            let par = 0;
            Object.keys(course['holes']).forEach((hole) => { par += parseInt(course['holes'][hole]['par']); });
            return par
        },
        // computed: sort courses by certain field
        coursesSorted (courses, sortField) {
            // no search criteria, return sorted events
            return _.orderBy(courses, [sortField], ['desc'])
        },

        // CRUD METHODS
        // method: delete existing course
        deleteCourse (courseId) { courseCollection.doc(courseId).delete(); },

        // DATA VALIDATION METHODS
        // method: validate course payload
        $_validateCoursePayload (coursePayload) {
            console.log(coursePayload);
        },
    }
}

export default coursesMixin;