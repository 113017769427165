<template>
  <!-- Dashboard Container -->
  <v-row no-gutters><v-col>


    <!-- Metrics Container -->
    <v-row no-gutters><v-col>

    </v-col></v-row>

    <!-- Tabbed/Content Container -->
    <v-row no-gutters><v-col>
      <!-- Tabbed Menu for Events and Courses -->
      <v-tabs v-model="tabSelected" background-color="secondary" color="white" hide-slider>
        <!-- Tab Collection -->
        <v-row no-gutters>
          <!-- Events Tab -->
          <v-tab key="events" class="caption font-weight-bold">Events</v-tab>
          <!-- Courses Tab -->
          <v-tab key="courses" class="caption font-weight-bold">Courses</v-tab>
        </v-row>

        <!-- HIDE FILTER FOR NOW
        <v-row no-gutters><v-col cols="12" class="pt-2 pr-3 text-right">
          <span class="caption text--secondary font-weight-bold">FILTER</span>
          <v-btn-toggle v-model="filterSelected" class="ml-5" group mandatory dense>
            <v-btn id="sevendays" :value="7" class="ml-0 mr-0 tab-action-btn" text outlined dense small>7</v-btn>
            <v-btn id="thirtydays" :value="30" class="ml-0 mr-0 tab-action-btn" text outlined dense small>30</v-btn>
            <v-btn id="ninetydays" :value="90" class="ml-0 mr-0 tab-action-btn" text outlined dense small>90</v-btn>
            <v-btn id="all" :value="0" class="ml-0 mr-0 tab-action-btn" text outlined dense small>ALL</v-btn>
          </v-btn-toggle>
          <v-tooltip color="secondary" bottom activator="#sevendays"><span>7 Days</span></v-tooltip>
          <v-tooltip color="secondary" bottom activator="#thirtydays"><span>30 Days</span></v-tooltip>
          <v-tooltip color="secondary" bottom activator="#ninetydays"><span>90 Days</span></v-tooltip>
          <v-tooltip color="secondary" bottom activator="#all"><span>All Days</span></v-tooltip>
        </v-col></v-row>
        -->

      </v-tabs>

      <v-divider class="divider-opacity" />

      <!-- Tabbed Menu Items/Content -->
      <v-tabs-items v-model="tabSelected" class="transparent">
        <!-- Events Tab Content/Panel -->
        <v-tab-item key="events">
          <events-panel />
        </v-tab-item>
        <!-- Courses Tab Content/Panel -->
        <v-tab-item key="courses">
          <courses-panel />
        </v-tab-item>
      </v-tabs-items>
    </v-col></v-row>

    <!-- Tutorial Inclusion for New Users -->
    <get-started :display="displayTutorial" @close="hideTutorialDialog" />

  </v-col></v-row>
</template>

<script>

import eventsPanel from "@/pages/application/dashboardPage/panels/eventsPanel"
import coursesPanel from "@/pages/application/dashboardPage/panels/coursesPanel";
import getStarted from "@/components/dialogs/tutorials/getStarted";
import {mapGetters} from "vuex";

export default {
  name: "dashboardPage",
  components: {
    eventsPanel,
    coursesPanel,
    getStarted
  },
  computed: {
    ...mapGetters({ events: "events", courses: "courses" })
  },
  data: () => ({
    // models/data for tabbed view
    tabSelected: null,
    // models/data for filtering view
    filterSelected: 30,
    // display tutorial
    displayTutorial: false
  }),
  methods: {
    // method: toggle 30 day filter
    toggleFilter30Days () { this.filter30Days = !this.filter30Days },
    // methods: tutorial dialogue display
    // methods: show/hide add courses dialog
    showTutorialDialog() { this.displayTutorial = true; },
    hideTutorialDialog() { this.displayTutorial = false; }
  },
  mounted() {
    // set display of tutorial on mount
    if (this.events.length === 0 && this.courses.length === 0) { this.displayTutorial = true; }
  }
}
</script>

<style scoped>

/* tab action styles */
.tab-action-btn{
  height: 26px !important;
  width: 42px !important;
}
.tab-action-btn-active{
  opacity: 0.8 !important;
}
.tab-action-btn-inactive{
  opacity: 0.4 !important;
}

</style>