<template>

  <!-- Courses Actions Container -->
  <v-row no-gutters><v-col>
    <!-- Actions Expansion Panel -->
    <v-expansion-panels v-model="actionsExpansion" class="rounded-0 elevation-0" flat tile>
      <v-expansion-panel key="utilities" class="pa-0 rounded-0 secondary darken-1">
        <!-- Actions Header -->
        <v-expansion-panel-header>
          <!-- Icon and Header -->
          <v-row align="center" no-gutters><v-col>
            <v-icon class="text--secondary" small>settings</v-icon>
            <span class="ml-3 text--secondary caption font-weight-bold">ACTIONS</span>
          </v-col></v-row>
          <!-- Dropdown Icon Template -->
          <template v-slot:actions>
            <v-icon class="text--secondary">expand_more</v-icon>
          </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-divider />
          <!-- Action Panel -->
          <v-card :height="contentHeight" color="secondary darken-2" class="pa-5 rounded-0 elevation-0">
            <v-row :height="contentHeight" align="center" class="fill-inner-parent-ht text-right" no-gutters><v-col>
              <!-- Add Event Button -->
              <v-btn @click="showCoursesDialog" class="success--text" text outlined>
                <v-icon small>fa-plus</v-icon>
                <span class="ml-3 caption font-weight-bold">Add Course</span>
              </v-btn>
            </v-col></v-row>
          </v-card>
        </v-expansion-panel-content>

      </v-expansion-panel>
    </v-expansion-panels>

    <!-- Add Course Modal -->
    <course-dialog :course-title="'Create New Course'" :display="displayCoursesDialog" @closeDialog="hideCoursesDialog" />

  </v-col></v-row>

</template>

<script>
import courseDialog from "@/components/dialogs/entities/courseDialog";

export default {
  name: "coursesActions",
  components: {
    courseDialog
  },
  props: {
    contentHeight: Number
  },
  data: () => ({
    // styling data
    actionsExpansion: 0,
    // display data
    displayCoursesDialog: false
  }),
  methods: {
    // methods: show/hide add courses dialog
    showCoursesDialog() { this.displayCoursesDialog = true; },
    hideCoursesDialog() { this.displayCoursesDialog = false; }
  }
}
</script>

<style scoped>

/* remove expansion panel padding */
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

</style>