import firebase from "firebase";
import store from "@/session/store";
// Required for side-effects
require("firebase/functions");


const environments = {
    "localhost": "development",
    "scorepro-development.firebaseapp.com": "development",
    "scorepro-staging.firebaseapp.com": "staging",
    "scorepro.golf": "production"
}


function buildFirebaseConfiguration() {
    // Builds Firebase Configuration, depending on environment
    return {
        projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
        appId: process.env.VUE_APP_FIREBASE_APP_ID,
        apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
        authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.VUE_APP_FIREBASE_DB_URL,
        storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_FIREBASE_MSG_SENDER_ID,
        measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
    };
}

// get environment
const environment = environments[location.hostname];
console.log("Hostname: " + location.hostname)
console.log("Launching for environment: " + environment)

// build configuration
const configuration = buildFirebaseConfiguration()

// Initializes Firebase with appropriate configuration for Environment
firebase.initializeApp(configuration);

// define initial instances of firebase services
const db = firebase.firestore();
const functions = firebase.functions();


// firebase on authentication state change, dispatch commitUser
firebase.auth().onAuthStateChanged(user => {
    // commit user from firebase to user in store
    store.dispatch("commitUser", user);
    // check if user exists
    if (user) {
        // initialise bindings
        store.dispatch('initBindings');
    } else {
        // teardown bindings
        store.dispatch('teardownBindings');
    }
});


export { firebase, functions, db };
