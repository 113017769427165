<template>

  <!-- Score Table (Player) Record Container -->
  <v-row no-gutters><v-col>

    <!-- Score Table (Player) Record -->
    <v-card :height="height" color="transparent" class="pt-2 rounded-0 elevation-0">
      <v-row :height="height" no-gutters align="center" class="fill-inner-parent-ht">

        <!-- Player Number/Index -->
        <v-col cols="1" class="text-center player-index-width">
          <span class="text-subtitle-2 text--secondary font-weight-bold">{{ playerNo + 1 }}</span>
        </v-col>

        <!-- Player/Team Name -->
        <v-col cols="2" class="pr-1">
          <v-text-field v-model="playerName" label="Player Name"
                        class="rounded-0 lightened-input" outlined dense hide-details />
        </v-col>

        <!-- Player Scores -->
        <v-col class="pr-1 text-center">
          <v-row no-gutters>
            <template v-for="(hole, holeId) in eventHoles(event)">
              <!-- Hole Score Input -->
              <v-col :key="`${playerId}_${holeId}`">
                <v-text-field v-model.number="playerScorecard[holeId]" :label="hole.hole.toString()"
                              :rules="holeInputRules" class="rounded-0 centered-input lightened-input custom-hole-input"
                              outlined dense hide-details />
              </v-col>
              <!-- Hole Out Total -->
              <v-col v-if="hole.hole === 9 && eventHoleCount(event) !== 9" :key="`${playerId}_${holeId}_out`">
                <v-text-field label="" :value="eventPlayerScoreOut(playerScorecard) === 0 ? '-' : eventPlayerScoreOut(playerScorecard)"
                              class="rounded-0 score-totals-width font-weight-bold centered-input lightened-input"
                              filled outlined dense hide-details disabled />
              </v-col>
              <!-- Hole In Total -->
              <v-col v-if="hole.hole === 18 && eventHoleCount(event) !== 9" :key="`${playerId}_${holeId}_in`">
                <v-text-field label="" :value="eventPlayerScoreIn(playerScorecard) === 0 ? '-' : eventPlayerScoreIn(playerScorecard)"
                              class="rounded-0 score-totals-width font-weight-bold centered-input lightened-input"
                              filled outlined dense hide-details disabled />
              </v-col>
              <!-- Hole All Total -->
              <v-col v-if="hole.hole === 18 || hole.hole === eventHoleCount(event)" :key="`${playerId}_${holeId}_total`">
                <v-text-field label="" :value="eventPlayerScoreTotal(event, playerScorecard) === 0 ? '-' : eventPlayerScoreTotal(event, playerScorecard)"
                              class="rounded-0 score-totals-width font-weight-bold centered-input lightened-input"
                              filled outlined dense hide-details disabled />
              </v-col>
              <!-- Player Handicap -->
              <v-col v-if="(hole.hole === 18 || hole.hole === eventHoleCount(event)) && event.handicap"
                     :key="`${playerId}_${holeId}_handicap`" class="ml-1">
                <v-text-field :id="`handicap-${playerId}`" v-model.number="playerHandicap" label="H" :value="player.handicap"
                              :rules="handicapRules" class="rounded-0 score-totals-width centered-input lightened-input"
                              filled outlined dense hide-details />
                <v-tooltip bottom color="secondary" :activator="`#handicap-${playerId}`">
                  <span>18-Hole Handicap</span>
                </v-tooltip>
              </v-col>
            </template>
          </v-row>
        </v-col>

        <!-- Score Record (Player) Actions -->
        <v-col cols="1" class="text-right pr-3" :class="event.skins ? 'action-w-skins' : 'action-wo-skins'">

          <!-- Player Skins Enable/Disabled -->
          <v-btn v-if="event.skins" :id="`player_skins_${ playerId }`" @click="skinsUpdate" class="action-button" text fab dense small>
            <v-icon :class="playerSkins ? 'success--text' : 'text--secondary'">paid</v-icon>
          </v-btn>
          <!-- Delete Player Record -->
          <v-btn :id="`player_delete_${ playerId }`" @click="deletePlayer(eventId, playerId)" class="ml-2 action-button" text dense fab small>
            <v-icon class="text--secondary" color="white">close</v-icon>
          </v-btn>

          <!-- Player Skins Tooltip -->
          <v-tooltip color="secondary" bottom :activator="`#player_skins_${ playerId }`">
            <span class="text--secondary">{{ playerSkins ? 'Disable Skins' : 'Enable Skins'}}</span>
          </v-tooltip>
          <!-- Player Delete Tooltip -->
          <v-tooltip color="secondary" bottom :activator="`#player_delete_${ playerId }`">
            <span class="text--secondary">Delete</span>
          </v-tooltip>
        </v-col>

      </v-row>
    </v-card>

    <v-divider class="divider-opacity" />

  </v-col></v-row>


</template>

<script>
import _ from "lodash";
import eventsMixin from "@/mixins/firestoreMixins/eventsMixin";
import playersMixin from "@/mixins/firestoreMixins/playersMixin";
import { eventCollection } from "@/plugins/firebase/firestore";
import dialogMixin from "@/mixins/componentMixins/dialogMixin";

export default {
  name: "scoreTableRecord",
  mixins: [eventsMixin, playersMixin, dialogMixin],
  props: {
    // data-related props
    eventId: String,
    event: Object,
    playerNo: Number,
    playerId: String,
    player: Object,
    // styling props
    height: Number
  },
  data: () => ({
    // player form information
    playerName: null,
    playerScorecard: {},
    playerHandicap: 0,
    playerSkins: false,
    // form validation
    holeInputRules: [v => ((!isNaN(parseFloat(v)) && v >= -20 && v <= 20) || (v === "")) || 'Must be valid score'],
    handicapRules: [v => (_.isInteger(v) || v === "") || 'Must be valid handicap']
  }),
  methods: {
    // UITILITY METHODS
    // method: check blank scorecard input
    isFilled(value) {
      return _.isInteger(value) || value === "";
    },
    // ACTION METHODS
    // method: save/update player record
    savePlayer () {
      // validate player scores
      let playerValidatedScorecard = _.pickBy(this.playerScorecard, this.isFilled);
      // check for valid handicap

      // check if validated scorecard matches user input
      if (Object.keys(playerValidatedScorecard).length === Object.keys(this.playerScorecard).length) {
        // save player payload
        let playerPayload = {
          [`players.${this.playerId}.name`]: (this.playerName ? this.playerName : null),
          [`players.${this.playerId}.scorecard`]: this.playerScorecard
        }
        // if handicapped event enabled
        if (this.event.handicap) {
          if (this.isFilled(this.playerHandicap)) {
            let playerHandicapPayload = { [`players.${this.playerId}.handicap`]: this.playerHandicap }
            _.merge(playerPayload, playerHandicapPayload)
          } else {
            return Promise.resolve(`Could not save ${this.playerName} scorecard, invalid input for Handicap.`)
          }
        }
        // if player skins enabled
        if (this.event.skins) {
          let playerSkinsPayload = { [`players.${this.playerId}.skins_enabled`]: this.playerSkins }
          _.merge(playerPayload, playerSkinsPayload)
        }
        // get event record from database and update players object
        return eventCollection.doc(this.eventId).update(playerPayload)
          .then(() => { return "success" })
          .catch(except => { return except });
      }
      // else, validated scorecard different than player scorecard
      else {
        // error occurred, display message
        let invalidHoles = _.difference(Object.keys(this.playerScorecard), Object.keys(playerValidatedScorecard));
        //this.alertError(`Could not save ${this.playerName} scorecard, invalid input for holes (${invalidHoles}).`, 5000)
        return Promise.resolve(`Could not save ${this.playerName} scorecard, invalid input for holes (${invalidHoles}).`)
      }

    },
    // method: toggle player skins
    skinsUpdate () { this.playerSkins = !this.playerSkins },

    // UTILITY METHODS
    // method: populate data from prop
    populatePlayerData () {
      this.playerName = _.cloneDeep(this.player.name);
      this.playerHandicap = _.cloneDeep(this.player.handicap ? this.player.handicap : 0);
      this.playerScorecard = _.cloneDeep(this.player.scorecard);
      this.playerSkins = _.cloneDeep(this.player.skins_enabled ? this.player.skins_enabled : false);
    }
  },
  mounted() {
    // mounted: pre-populate player data on mount
    this.populatePlayerData()
  }
}
</script>

<style scoped>

.player-index-width {
  max-width: 70px !important;
}
.score-totals-width {
  min-width: 52px !important;
}
.tooltip-styling {
  opacity: 0.5 !important;
}

.action-wo-skins {
  max-width: 70px !important;
}
.action-w-skins {
  max-width: 120px !important;
}

/* vuetify overrides */
.v-text-field--outlined >>> fieldset {
  opacity: 0.5 !important;
}

/deep/ .lightened-input input {
  opacity: 0.9 !important;
}
/deep/ .lightened-input input::placeholder{
  opacity: 0.3 !important;
}

/deep/ .centered-input input {
  padding-top: 12px !important;
  text-align: center;
}

/* hide arrows on number input */


</style>