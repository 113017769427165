import { navigateTo } from "@/routes/navigation";
import firebase from "firebase";
import { createStripeCheckoutSession } from "@/plugins/firebase/functions/checkout";
import store from "@/session/store";
import { router } from "@/routes/router";
import { stripe } from "@/plugins/stripe/configuration";


// firebase login function
function firebaseLogIn(email, password) {
    // make firebase SignIn request
    return firebase.auth().signInWithEmailAndPassword(email, password)
        .then(user => {
            // login successful, perform user commit to store
            store.dispatch('commitUser', user);
            // perform initialisation of bindings
            store.dispatch('initBindings');
            // set temporary delay for bindings to complete
            // redirect to either dashboard or account page
            setTimeout(() => {
                (store.getters.subscriptionActive) ? navigateTo('dashboard') : navigateTo('account')
            },1500);
        })
        .catch(error => {
            throw error
        });
}

// firebase logout function
function firebaseLogOut() {
    // make firebase signOut request
    firebase.auth().signOut()
        .then(() => {
            // teardown bindings
            store.dispatch('teardownBindings');
            // also, clear session storage of user sign-in
            window.localStorage.clear();
            sessionStorage.clear();
            // navigate to login
            navigateTo('home');
        });
}

// fireBase register function
// integrated with stripe client-only checkout
function firebaseRegister(info) {
    // register new user to Firebase
    return firebase.auth().createUserWithEmailAndPassword(info.email, info.password)
        // update user displayname in firebase
        .then(user => {
            // udpate user displayname
            user.user.updateProfile({ displayName: info.name });
            // registration successful, perform user commit to store
            store.dispatch('commitUser', user);
            // perform initialisation of bindings
            store.dispatch('initBindings');
            // pass user to next in chain
            return user
        })
        .catch(error => {
            // throw registration exception
            throw error;
        });
}

// firebase reset password function
function firebaseResetPassword(newPassword) {
    console.log('FB Resetting password')
    // get current user
    let user = firebase.auth().currentUser;
    // attempt password reset
    return user.updatePassword(newPassword)
        .then(() => {
            // Update successful.
            console.log(`Password successfully reset for user ${user.email}.`);
        }).catch(function(error) {
            // An error happened, throw
            throw error;
        });
}

// firebase reset password by code
function firebaseResetPasswordByCode(code, newPassword) {
    console.log('FB Resetting password with code');
    return firebase.auth().confirmPasswordReset(code, newPassword)
        .then(() => {
            // success
        })
        .catch(error => {
            // error occurred, throw
            throw error;
        });
}

// firebase send password recovery email
function firebaseSendPasswordRecoveryEmail(customerEmail) {
    console.log('Sending recovery email');
    return firebase.auth().sendPasswordResetEmail(customerEmail)
        .then(() => {
            // email sent
        }).catch(error => {
            // An error happened, throw
            throw error;
        });
}

// purchase subscription (after registration)
function purchaseSubscription(customerId, priceId, trialEnabled) {
    // build payload for session
    let payload = {
        customerId: customerId,
        priceId: priceId,
        successUrl: process.env.VUE_APP_DOMAIN + router.resolve({name: 'successfulCheckout'}).href,
        cancelUrl: process.env.VUE_APP_DOMAIN + router.resolve({name: 'purchase'}).href,
        trialEnabled: trialEnabled
    }
    // get customer information from Stripe and commit to store
    return createStripeCheckoutSession(payload)
        .then(session => {
            return session.data.id
        })
        // redirect to Stripe Checkout
        .then(sessionId => {
            stripe.redirectToCheckout({sessionId: sessionId})
        }).catch(error => {
            // exception caught in session creation or redirection to stripe
            throw error;
        });
}


export { firebaseLogIn, firebaseLogOut, firebaseRegister,
         firebaseResetPassword, firebaseResetPasswordByCode,
         firebaseSendPasswordRecoveryEmail,
         purchaseSubscription
};