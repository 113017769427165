import { render, staticRenderFns } from "./eventIconComponent.vue?vue&type=template&id=53030040&scoped=true&"
import script from "./eventIconComponent.vue?vue&type=script&lang=js&"
export * from "./eventIconComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53030040",
  null
  
)

export default component.exports