import {firebaseSendPasswordRecoveryEmail} from "@/plugins/firebase/security";
import {customerCollection} from "@/plugins/firebase/firestore";

/*************************************************************
 Mixin for Accounts
 **************************************************************/

var accountMixin = {

    data: () => ({}),

    methods: {
        // ACCOUNT INFORMATION Methods
        // method: get current email for account
        accountEmail (user) { return user.customer.email },
        // method: get current display name for account
        accountDisplayName (user) { return (user.customer && 'name' in user.customer ? user.customer.name : '') },
        // method: get current club name for account
        accountClubName (user) { return (user.customer && 'club_name' in user.customer ? user.customer.club_name : '') },
        // method: get current phone number for account
        accountPhoneNumber (user) { return (user.customer && 'phone' in user.customer ? user.customer.phone : '') },
        // method: get phone prefix for account
        // NOTE - Defaults to +1 for now
        accountPhonePrefix () { return '+1' },

        // ACCOUNT DECISION Methods
        // method: determine if user should receive trial (if user has no previous subscriptions)
        accountReceivesTrial (user) { return (user.subscriptions.length <= 0) },

        // ACCOUNT ACTION METHODS
        // method: send email to recover/reset password
        sendAccountPasswordEmail (user) { return firebaseSendPasswordRecoveryEmail(user.customer.email) },

        // CRUD-OPERATION METHODS
        // method: update account information
        updateAccountInformation (user, information) { return customerCollection.doc(this.user.uid).update(information) }


    }

}

export default accountMixin;