<template>

  <!-- Web (Public) Application Bar -->
  <v-app-bar flat :height="72" color="secondary darken-3">
    <v-row justify="center" align="center" class="pt-10" no-gutters>
      <!-- Logo Column -->
      <v-col xl="3" lg="4" md="5" class="text-left">
        <!-- Logo -->
        <svg class="logo-mark-style logo-style" @click="navigateTo('home')">
          <scorepro-mark-white-icon />
        </svg>
        <!-- Application Name -->
        <svg class="logo-type-style logo-style" @click="navigateTo('home')">
          <scorepro-logo-type-alt-icon />
        </svg>
      </v-col>
      <!-- Helper Column -->
      <v-col xl="3" lg="4" md="5" class="text-right">
        <web-header-helper class="header-helper-item" />
      </v-col>
    </v-row>
  </v-app-bar>

</template>

<script>
import scoreproMarkWhiteIcon from "@/components/icons/logos/scoreproMarkWhiteIcon";
import scoreproLogoTypeAltIcon from "@/components/icons/logos/scoreproLogoTypeAltIcon";
import webHeaderHelper from "@/components/layout/headers/components/webHeaderHelper";
import {navigateTo} from "@/routes/navigation";

export default {
  name: "webHeader",
  components: {
    scoreproMarkWhiteIcon,
    scoreproLogoTypeAltIcon,
    webHeaderHelper
  },
  methods: {
    // method: calls navigate function
    navigateTo(nextRouteName) { navigateTo(nextRouteName); },
  }
}
</script>

<style scoped>

/* application name */
.logo-type-style {
  height: 16px !important;
  width: 180px !important;
}
.logo-mark-style {
  height: 24px !important;
  width: 24px !important;
  top: 40px !important;
  position: absolute;
}

/* logo style */
.logo-style {
  cursor: pointer !important;
}

</style>