<template>
  <!-- Centered Row for Email -->
  <v-row justify="center" align="center" class="fill-height flex-nowrap" no-gutters>
    <v-col cols="3">
      <v-card min-width="50%" class="transparent elevation-0">

        <!-- Login Title -->
        <v-card-title class="pl-0 pr-0" color="white">
          <span>Recover Password</span>
        </v-card-title>

        <!-- Login Form -->
        <v-card-text class="pa-0">
          <v-form ref="form" v-model="emailValid" method="post">
            <!-- Email/Username -->
            <v-row justify="center" align="center" no-gutters>
              <v-text-field v-model="email" :rules="emailRules" :error-count="1" label="Email"
                            append-icon="account_circle" outlined required />
            </v-row>
          </v-form>
        </v-card-text>

        <!-- Submission Actions -->
        <v-card-actions class="pa-0">
          <v-row no-gutters>
            <v-col cols="12">
              <!-- Login Button -->
              <v-btn @click="sendRecoveryEmail" :disabled="!emailValid" width="100%" outlined>
                <!-- login attempt spinner -->
                <span v-if="attemptingRecovery">
                  <v-progress-circular :size="20" :width="2" color="white" indeterminate />
                </span>
                <!-- send recovery email btn text -->
                <span v-else>Send Email</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

      </v-card>
    </v-col>

    <!-- Error Alerts with Message -->
    <v-dialog v-model="errorAlertDisplay" max-width="600" class="elevation-0">
      <v-alert v-model="errorAlertDisplay" type="error" class="pa-5 ma-0 fill-height">{{ errorAlertMessage }}</v-alert>
    </v-dialog>
    <!-- Success Alerts with Message -->
    <v-dialog v-model="successAlertDisplay" max-width="600"  class="elevation-0">
      <v-alert v-model="successAlertDisplay" type="success" class="pa-5 ma-0 fill-height">{{ successAlertMessage }}</v-alert>
    </v-dialog>

  </v-row>

</template>

<script>
import { firebaseSendPasswordRecoveryEmail } from "@/plugins/firebase/security"
import { navigateTo } from "@/routes/navigation";


export default {
  name: "forgotPasswordPage",
  data: () => ({
    // attempting login recovery
    attemptingRecovery: false,
    // form entry
    email: null,
    // validation check
    emailValid: true,
    // validation rules
    emailRules: [
      v => !!v || '* Email is required',
      v => /.+@.+/.test(v) || '* Email must be valid'
    ],
    // error alert popup
    errorAlertDisplay: false,
    errorAlertMessage: null,
    // success alert popup
    successAlertDisplay: false,
    successAlertMessage: 'Email successfully sent!',
  }),
  methods: {
    // method: send recovery email
    sendRecoveryEmail() {
      // set attempt spinner
      this.attemptingRecovery = true;
      // call firebase and pass email to send for recovery
      firebaseSendPasswordRecoveryEmail(this.email)
          .then(() => {
            // send request submitted successfully
            this.successAlertMessage = 'Recovery email successfully sent! Redirecting to login...';
            this.successAlertDisplay = true;
            // set attempt spinner
            this.attemptingRecovery = false;
            // redirect to login after n seconds
            setTimeout(() => navigateTo('login'), 2000);
          })
          .catch(error => {
            // error occurred
            // set error message and display
            this.errorAlertMessage = error;
            this.errorAlertDisplay = true;
            // set attempt spinner
            this.attemptingRecovery = false;
          });
    }
  }
}
</script>

<style scoped>

</style>