<template>

  <!-- Pricing Row -->
  <v-row justify="center" align="center" class="pricing-style secondary darken-1" no-gutters>
    <!-- Pricing Tag -->
    <v-col class="pr-5">
      <v-row class="pr-15" no-gutters><v-col class="text-right">
        <span class="text-h4 font-weight-bold">Save your <span class="success--text">green</span></span>
      </v-col></v-row>
      <v-row class="mt-3 pr-15" no-gutters><v-col class="text-right">
        <span class="text-h5 subtext-style font-weight-medium">Simple payments to get you running</span>
      </v-col></v-row>
      <v-row class="pr-15" no-gutters><v-col class="text-right">
        <span class="text-h5 subtext-style font-weight-medium">the tournament of your dreams</span>
      </v-col></v-row>
    </v-col>
    <!-- Pricing Cards -->
    <v-col class="pl-5">
      <v-row align="center" no-gutters>
        <!-- (First Card) Background Card -->
        <v-card width="200" min-height="300" color="info" class="rounded-xl rounded-r-0 elevation-0">
          <v-card-text class="pt-15">
            <v-row justify="center" align="center" no-gutters><v-col class="pt-7 text-right">
              <span class="text-h2 white--text font-weight-bold">$999</span>
            </v-col></v-row>
            <v-row justify="center" align="center" no-gutters><v-col class="pr-7 text-right">
              <span class="subtitle-1 white--text font-weight-bold">PER YEAR</span>
            </v-col></v-row>
          </v-card-text>
          <v-card-actions>
            <v-row no-gutters><v-col class="text-right">
              <v-btn color="secondary" @click="navigateTo('register')" depressed>
                <span class="subtitle-2 font-weight-bold">Get Started</span>
                <v-icon class="ml-3" small>east</v-icon>
              </v-btn>
            </v-col></v-row>
          </v-card-actions>
        </v-card>
        <!-- (First Card) Background Card -->
        <v-card width="280" min-height="360" color="white" class="rounded-xl">
          <v-card-text class="pt-15">
            <v-row justify="center" align="center" no-gutters><v-col class="pt-7 text-center">
              <span class="text-h1 info--text font-weight-bold">$99</span>
            </v-col></v-row>
            <v-row justify="center" align="center" no-gutters><v-col class="text-center">
              <span class="subtitle-1 info--text font-weight-bold">PER MONTH</span>
            </v-col></v-row>
          </v-card-text>
          <v-card-actions>
            <v-row no-gutters><v-col class="text-center">
              <v-btn color="secondary" @click="navigateTo('register')" large depressed>
                <span class="subtitle-1 font-weight-bold">Get Started</span>
                <v-icon class="ml-3">east</v-icon>
              </v-btn>
            </v-col></v-row>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-col>
  </v-row>

</template>

<script>
import {navigateTo} from "@/routes/navigation";

export default {
  name: "pricePanel",
  methods: {
    // method: calls navigate function
    navigateTo(nextRouteName) { navigateTo(nextRouteName); }
  }
}
</script>

<style scoped>

.pricing-style {
  min-height: 600px !important;
}

.subtext-style {
  opacity: 0.3 !important;
}

</style>