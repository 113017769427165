<template>

  <!-- Pricing Row -->
  <v-row justify="center" align="center" class="features-style secondary darken-3" no-gutters><v-col>

    <!-- Headline -->
    <v-row justify="center" align="center" no-gutters><v-col class="text-center">
      <span class="text-h4 font-weight-bold">Supported Features</span>
    </v-col></v-row>
    <!-- Catch-line Sub-text -->
    <v-row justify="center" align="center" class="mt-1" no-gutters><v-col class="text-center">
      <span class="subtitle-1 subtext-style font-weight-medium">
        Some key features to help handle your tournament scoring
      </span>
    </v-col></v-row>

    <!-- Features -->
    <v-row justify="center" align="center" class="mt-5"  no-gutters>
      <v-col align="right" class="pr-2">
        <feature-card :height="featureHeight" :width="featureWidth" :color="features[0].color"
                      :icon="features[0].icon" :iconColor="features[0].iconColor"
                      :title="features[0].title" :description="features[0].description" />
      </v-col>
      <v-col class="pl-2">
        <feature-card :height="featureHeight" :width="featureWidth" :color="features[1].color"
                      :icon="features[1].icon" :iconColor="features[1].iconColor"
                      :title="features[1].title" :description="features[1].description" />
      </v-col>

    </v-row>
    <v-row justify="center" align="center" class="mt-3"  no-gutters>
      <v-col align="right" class="pr-2">
        <feature-card :height="featureHeight" :width="featureWidth" :color="features[2].color"
                      :icon="features[2].icon" :iconColor="features[2].iconColor"
                      :title="features[2].title" :description="features[2].description" />
      </v-col>
      <v-col class="pl-2">
        <feature-card :height="featureHeight" :width="featureWidth" :color="features[3].color"
                      :icon="features[3].icon" :iconColor="features[3].iconColor"
                      :title="features[3].title" :description="features[3].description" />
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="mt-3"  no-gutters>
      <v-col align="right" class="pr-2">
        <feature-card :height="featureHeight" :width="featureWidth" :color="features[4].color"
                      :icon="features[4].icon" :iconColor="features[4].iconColor"
                      :title="features[4].title" :description="features[4].description" />
      </v-col>
      <v-col class="pl-2">
        <feature-card :height="featureHeight" :width="featureWidth" :color="features[5].color"
                      :icon="features[5].icon" :iconColor="features[5].iconColor"
                      :title="features[5].title" :description="features[5].description" />
      </v-col>
    </v-row>
    
    

  </v-col></v-row>

</template>

<script>
import FeatureCard from "@/pages/public/homePage/components/featureCard";
export default {
  name: "featuresPanel",
  components: {FeatureCard},
  data: () => ({
    featureHeight: 100,
    featureWidth: 400,
    features: [
      {color: 'secondary darken-1', icon: 'connected_tv', iconColor: 'info', title: 'Live Scoreboard', description: 'Cast and display any number of events throughout the clubhouse.'},
      {color: 'secondary darken-1', icon: 'emoji_events', iconColor: 'warning', title: 'Unlimited Events', description: 'Create as many events as you need. No artificial limits.'},
      {color: 'secondary darken-1', icon: 'paid', iconColor: 'success', title: 'Skins', description: 'Enable event skins and display them on your scoreboard.'},
      {color: 'secondary darken-1', icon: 'filter_list', iconColor: 'info', title: 'Flights', description: 'Events can contain up to 8 flights, automatically generated.'},
      {color: 'secondary darken-1', icon: 'exposure_plus_1', iconColor: 'info', title: 'Handicaps', description: 'Create handicap-based events, calculated in scoreboard.'},
      {color: 'secondary darken-1', icon: 'shuffle', iconColor: 'error', title: 'Playoffs', description: 'Automatically handle scorecard playoffs and tie-breakers.'}
    ]
  }),
}
</script>

<style scoped>

.features-style {
  min-height: 650px !important;
}

.subtext-style {
  opacity: 0.3 !important;
}

</style>