<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 494.4 494.4" style="enable-background:new 0 0 494.4 494.4;" xml:space="preserve">
<path style="fill:#D89E77;" d="M247.2,494.4c-132,0-240-108-240-240s108-240,240-240s240,108,240,240S379.2,494.4,247.2,494.4z"/>
    <circle style="fill:#D88B56;" cx="247.2" cy="240" r="224.8"/>
    <g>
	<path style="fill:#CC7E48;" d="M48.8,267.2c0-124,100.8-224.8,224.8-224.8c55.2,0,105.6,20,144.8,52.8
		c-40.8-48.8-102.4-80-171.2-80C123.2,15.2,22.4,116,22.4,240c0,68.8,31.2,130.4,80,172C68.8,372.8,48.8,322.4,48.8,267.2z"/>
      <path style="fill:#CC7E48;" d="M406.4,81.6c88,88,88,230.4,0,318.4S176,488,88,400"/>
</g>
    <path style="fill:#F7C6A4;" d="M247.2,480c-132,0-240-108-240-240s108-240,240-240s240,108,240,240S379.2,480,247.2,480z M247.2,30.4c-116,0-209.6,94.4-209.6,209.6c0,116,94.4,209.6,209.6,209.6S456.8,355.2,456.8,240S363.2,30.4,247.2,30.4z"/>
    <g>
	<path style="fill:#E8B18B;" d="M487.2,240c0-60.8-22.4-116-59.2-157.6c-42.4-36.8-97.6-59.2-158.4-59.2c-40.8,0-78.4,10.4-112,28
		c27.2-13.6,57.6-20.8,89.6-20.8c63.2,0,120.8,28.8,159.2,73.6c44.8,38.4,72.8,95.2,72.8,159.2c0,32-7.2,62.4-20,89.6
		C476.8,319.2,487.2,280.8,487.2,240z"/>
      <path style="fill:#E8B18B;" d="M29.6,263.2c0,60.8,22.4,116,59.2,157.6c42.4,36.8,97.6,59.2,157.6,59.2c40.8,0,78.4-10.4,112-28
		c-27.2,12.8-57.6,20-89.6,20c-63.2,0-120.8-28.8-159.2-73.6C64.8,360,36,303.2,36,239.2c0-32,7.2-62.4,20-89.6
		C40,184,29.6,222.4,29.6,263.2z"/>
</g>
    <g>
	<path style="fill:#FFDCC5;" d="M248.8,22.4c0,0.8-0.8,1.6-1.6,1.6l0,0c-0.8,0-1.6-0.8-1.6-1.6V7.2c0-0.8,0.8-1.6,1.6-1.6l0,0
		c0.8,0,1.6,0.8,1.6,1.6V22.4z"/>
      <path style="fill:#FFDCC5;" d="M248.8,473.6c0,0.8-0.8,1.6-1.6,1.6l0,0c-0.8,0-1.6-0.8-1.6-1.6v-15.2c0-0.8,0.8-1.6,1.6-1.6l0,0
		c0.8,0,1.6,0.8,1.6,1.6V473.6z"/>
      <path style="fill:#FFDCC5;" d="M402.4,88c-0.8,0.8-1.6,0.8-2.4,0l0,0c-0.8-0.8-0.8-1.6,0-2.4l11.2-11.2c0.8-0.8,1.6-0.8,2.4,0l0,0
		c0.8,0.8,0.8,1.6,0,2.4L402.4,88z"/>
      <path style="fill:#FFDCC5;" d="M84,406.4c-0.8,0.8-1.6,0.8-2.4,0l0,0c-0.8-0.8-0.8-1.6,0-2.4l11.2-11.2c0.8-0.8,1.6-0.8,2.4,0l0,0
		c0.8,0.8,0.8,1.6,0,2.4L84,406.4z"/>
      <path style="fill:#FFDCC5;" d="M464.8,242.4c-0.8,0-1.6-0.8-1.6-1.6l0,0c0-0.8,0.8-1.6,1.6-1.6H480c0.8,0,1.6,0.8,1.6,1.6l0,0
		c0,0.8-0.8,1.6-1.6,1.6H464.8z"/>
      <path style="fill:#FFDCC5;" d="M14.4,242.4c-0.8,0-1.6-0.8-1.6-1.6l0,0c0-0.8,0.8-1.6,1.6-1.6h15.2c0.8-0.8,1.6,0,1.6,0.8l0,0
		c0,0.8-0.8,1.6-1.6,1.6H14.4V242.4z"/>
      <path style="fill:#FFDCC5;" d="M400,396c-0.8-0.8-0.8-1.6,0-2.4l0,0c0.8-0.8,1.6-0.8,2.4,0l11.2,11.2c0.8,0.8,0.8,1.6,0,2.4l0,0
		c-0.8,0.8-1.6,0.8-2.4,0L400,396z"/>
      <path style="fill:#FFDCC5;" d="M80.8,76.8c-0.8-0.8-0.8-1.6,0-2.4l0,0c0.8-0.8,1.6-0.8,2.4,0l11.2,11.2c0.8,0.8,0.8,1.6,0,2.4l0,0
		c-0.8,0.8-1.6,0.8-2.4,0L80.8,76.8z"/>
      <path style="fill:#FFDCC5;" d="M140,51.2c0.8,0.8,0,2.4-0.8,2.4l0,0c-0.8,0.8-2.4,0-2.4-0.8l-8-13.6c-0.8-0.8,0-2.4,0.8-2.4l0,0
		c0.8-0.8,2.4,0,2.4,0.8L140,51.2z"/>
      <path style="fill:#FFDCC5;" d="M365.6,441.6c0.8,0.8,0,2.4-0.8,2.4l0,0c-0.8,0.8-2.4,0-2.4-0.8l-8-13.6c-0.8-0.8,0-2.4,0.8-2.4l0,0
		c0.8-0.8,2.4,0,2.4,0.8L365.6,441.6z"/>
      <path style="fill:#FFDCC5;" d="M305.6,30.4c0,0.8-1.6,1.6-2.4,1.6l0,0c-0.8,0-1.6-1.6-1.6-2.4l4-15.2c0-0.8,1.6-1.6,2.4-1.6l0,0
		c0.8,0,1.6,1.6,1.6,2.4L305.6,30.4z"/>
      <path style="fill:#FFDCC5;" d="M188.8,465.6c0,0.8-1.6,1.6-2.4,1.6l0,0c-0.8,0-1.6-1.6-1.6-2.4l4-15.2c0-0.8,1.6-1.6,2.4-1.6l0,0
		c0.8,0,1.6,1.6,1.6,2.4L188.8,465.6z"/>
      <path style="fill:#FFDCC5;" d="M436.8,132.8c-0.8,0.8-2.4,0-2.4-0.8l0,0c-0.8-0.8,0-2.4,0.8-2.4l13.6-8c0.8-0.8,2.4,0,2.4,0.8l0,0
		c0.8,0.8,0,2.4-0.8,2.4L436.8,132.8z"/>
      <path style="fill:#FFDCC5;" d="M46.4,358.4c-0.8,0.8-2.4,0-2.4-0.8l0,0c-0.8-0.8,0-2.4,0.8-2.4l13.6-8c0.8-0.8,2.4,0,2.4,0.8l0,0
		c0.8,0.8,0,2.4-0.8,2.4L46.4,358.4z"/>
      <path style="fill:#FFDCC5;" d="M456.8,298.4c-0.8,0-1.6-1.6-1.6-2.4l0,0c0-0.8,1.6-1.6,2.4-1.6l15.2,4c0.8,0,1.6,1.6,1.6,2.4l0,0
		c0,0.8-1.6,1.6-2.4,1.6L456.8,298.4z"/>
      <path style="fill:#FFDCC5;" d="M21.6,181.6c-0.8,0-1.6-1.6-1.6-2.4l0,0c0-0.8,1.6-1.6,2.4-1.6l15.2,4c0.8,0,1.6,1.6,1.6,2.4l0,0
		c0,0.8-1.6,1.6-2.4,1.6L21.6,181.6z"/>
      <path style="fill:#FFDCC5;" d="M60,129.6c0.8,0.8,1.6,1.6,0.8,2.4l0,0c-0.8,0.8-1.6,1.6-2.4,0.8l-13.6-8c-0.8-0.8-1.6-1.6-0.8-2.4
		l0,0c0.8-0.8,1.6-1.6,2.4-0.8L60,129.6z"/>
      <path style="fill:#FFDCC5;" d="M450.4,355.2c0.8,0.8,1.6,1.6,0.8,2.4l0,0c-0.8,0.8-1.6,1.6-2.4,0.8l-13.6-8
		c-0.8-0.8-1.6-1.6-0.8-2.4l0,0c0.8-0.8,1.6-1.6,2.4-0.8L450.4,355.2z"/>
      <path style="fill:#FFDCC5;" d="M192.8,29.6c0,0.8,0,2.4-1.6,2.4l0,0c-0.8,0-2.4,0-2.4-1.6l-4-15.2c0-0.8,0-2.4,1.6-2.4l0,0
		c0.8,0,2.4,0,2.4,1.6L192.8,29.6z"/>
      <path style="fill:#FFDCC5;" d="M309.6,464.8c0,0.8,0,2.4-1.6,2.4l0,0c-0.8,0-2.4,0-2.4-1.6l-4-15.2c0-0.8,0-2.4,1.6-2.4l0,0
		c0.8,0,2.4,0,2.4,1.6L309.6,464.8z"/>
      <path style="fill:#FFDCC5;" d="M357.6,52.8c-0.8,0.8-1.6,1.6-2.4,0.8l0,0c-0.8-0.8-1.6-1.6-0.8-2.4l8-13.6c0.8-0.8,1.6-1.6,2.4-0.8
		l0,0c0.8,0.8,1.6,1.6,0.8,2.4L357.6,52.8z"/>
      <path style="fill:#FFDCC5;" d="M132,443.2c-0.8,0.8-1.6,1.6-2.4,0.8l0,0c-0.8-0.8-1.6-1.6-0.8-2.4l8-13.6c0.8-0.8,1.6-1.6,2.4-0.8
		l0,0c0.8,0.8,1.6,1.6,0.8,2.4L132,443.2z"/>
      <path style="fill:#FFDCC5;" d="M457.6,185.6c-0.8,0-2.4,0-2.4-1.6l0,0c0-0.8,0-2.4,1.6-2.4l15.2-4c0.8,0,2.4,0,2.4,1.6l0,0
		c0,0.8,0,2.4-1.6,2.4L457.6,185.6z"/>
      <path style="fill:#FFDCC5;" d="M22.4,302.4c-0.8,0-2.4,0-2.4-1.6l0,0c0-0.8,0-2.4,1.6-2.4l15.2-4c0.8,0,2.4,0,2.4,1.6l0,0
		c0,0.8,0,2.4-1.6,2.4L22.4,302.4z"/>
      <path style="fill:#FFDCC5;" d="M292.8,246.4c12-11.2,18.4-26.4,18.4-43.2c0-29.6-21.6-60.8-69.6-60.8c-25.6,0-48,9.6-59.2,17.6
		c-5.6,4-8,11.2-5.6,17.6l6.4,18.4c1.6,4.8,5.6,8,9.6,9.6c4.8,1.6,9.6,0.8,13.6-2.4c6.4-4.8,18.4-9.6,28.8-9.6
		c17.6,0,17.6,9.6,17.6,13.6c0,13.6-20,16.8-27.2,16.8h-14.4c-8.8,0-15.2,7.2-15.2,15.2v19.2c0,8.8,7.2,15.2,15.2,15.2h14.4
		c8,0,33.6,1.6,33.6,23.2c0,2.4,0,8.8-5.6,14.4s-15.2,7.2-22.4,7.2c-13.6,0-28-5.6-33.6-9.6c-4-2.4-8.8-3.2-13.6-1.6s-8,4.8-9.6,9.6
		l-7.2,20c-2.4,6.4,0,14.4,6.4,18.4c11.2,7.2,32.8,15.2,57.6,15.2c58.4,0,84.8-37.6,84.8-72.8C318.4,276.8,308.8,258.4,292.8,246.4z
		"/>
</g>
    <path style="fill:#F7C6A4;" d="M212.8,274.4h14.4c8,0,33.6,1.6,33.6,23.2c0,2.4,0,8.8-5.6,14.4s-15.2,7.2-22.4,7.2
	c-13.6,0-28-5.6-33.6-9.6c-4-2.4-8.8-3.2-13.6-1.6s-8,4.8-9.6,9.6l-7.2,20c-2.4,6.4,0,14.4,6.4,18.4c11.2,7.2,32.8,15.2,57.6,15.2
	c58.4,0,84.8-37.6,84.8-72.8c0-20.8-9.6-39.2-25.6-51.2c12-11.2,18.4-26.4,18.4-43.2c0-8-1.6-15.2-4.8-22.4L212.8,274.4L212.8,274.4
	z"/>
    <path style="fill:#CC7E48;" d="M218.4,88.8c0,7.2-6.4,19.2-13.6,19.2c-7.2,0-13.6-12-13.6-19.2s6.4-13.6,13.6-13.6
	C212,75.2,218.4,81.6,218.4,88.8z"/>
    <circle style="fill:#F7C6A4;" cx="204.8" cy="88.8" r="13.6"/>
    <path style="fill:#E8B18B;" d="M214.4,79.2c5.6,5.6,5.6,13.6,0,19.2s-13.6,5.6-19.2,0"/>
    <path style="fill:#CC7E48;" d="M260.8,88.8c0,7.2-6.4,19.2-13.6,19.2s-13.6-12-13.6-19.2s6.4-13.6,13.6-13.6S260.8,81.6,260.8,88.8z
	"/>
    <circle style="fill:#F7C6A4;" cx="247.2" cy="88.8" r="13.6"/>
    <path style="fill:#E8B18B;" d="M256.8,79.2c5.6,5.6,5.6,13.6,0,19.2s-13.6,5.6-19.2,0"/>
    <path style="fill:#CC7E48;" d="M303.2,88.8c0,7.2-6.4,19.2-13.6,19.2S276,96,276,88.8s6.4-13.6,13.6-13.6
	C296.8,75.2,303.2,81.6,303.2,88.8z"/>
    <circle style="fill:#F7C6A4;" cx="289.6" cy="88.8" r="13.6"/>
    <path style="fill:#E8B18B;" d="M299.2,79.2c5.6,5.6,5.6,13.6,0,19.2s-13.6,5.6-19.2,0"/>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</template>

<script>
export default {
  name: "iconThirdPlace"
}
</script>

<style scoped>

</style>