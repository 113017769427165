<template>
  <v-hover v-slot="{ hover }">
    <v-card :height="height" :width="width" :min-width="width" :color="color" :elevation="hover ? 16 : 2" hover class="pl-2">
      <div v-if="hover" :class="hover ? 'hovered' : ''" />
      <v-row justify="center" no-gutters>
        <!-- Feature Icon -->
        <v-col class="text-center icon-style pt-5">
          <v-icon :color="iconColor" class="icon-size">{{ icon }}</v-icon>
        </v-col>
        <!-- Feature Title and Description -->
        <v-col class="pl-3 pt-4 pb-3 pr-10">
          <v-row class="text-left" no-gutters><v-col>
            <span class="text-h6 text--secondary font-weight-bold">{{ title }}</span>
          </v-col></v-row>
          <v-row class="text-left" no-gutters><v-col class="subtitle-2 text--secondary desc-style font-weight-bold">
            {{ description }}
          </v-col></v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-hover>

</template>

<script>
export default {
  name: "featureCard",
  props: {
    height: [String, Number],
    width: [String, Number],
    color: [String],
    icon: String,
    iconColor: String,
    title: String,
    description: String
  }
}
</script>

<style scoped>

.icon-style {
  max-width: 90px !important;
  min-width: 90px !important;
}
.icon-size {
  font-size: 60px !important;
}
.desc-style {
  line-height: 1.2 !important;
  opacity: 0.6 !important;
}
.hovered {
  top: 0 !important;
  left: 0 !important;
  width: 2px !important;
  height: 100% !important;
  position: absolute !important;
  background-color: darkgrey !important;
}

</style>