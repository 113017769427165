<template>

  <!-- Button Transition Format Necessary -->
  <!-- https://github.com/vuetifyjs/vuetify/issues/10578 -->

  <div>
    <!-- Event History Button (Hide history for now)
    <v-btn id="history_button" text small @click="navigateTo('history')"
           :disabled="!userSubscribed"
           class="header-v-btn header-v-btn-bg grey--text"
           :color="$route.name === 'history' ? 'success' : ''">
      <v-icon>history</v-icon>
      <v-tooltip bottom color="secondary" activator="#history_button">
        <span>History</span>
      </v-tooltip>
    </v-btn>
     -->

    <!-- Support Button (Hide FAQ for now)
    <v-btn id="support_button" text small @click="navigateTo( 'support')"
           class="header-v-btn header-v-btn-bg grey--text"
           :color="$route.name === 'support' ? 'success' : ''">
      <v-icon>contact_support</v-icon>
      <v-tooltip bottom color="secondary" activator="#support_button">
        <span>Support</span>
      </v-tooltip>
    </v-btn>
     -->

    <!-- Logout Button -->
    <v-btn @click="userLogOut" small outlined class="ml-4 grey--text">Logout</v-btn>

    <!-- Purchase Buttons
    <v-btn v-if="!userSubscribed" @click="$emit('navigate', 'purchase')"
           small outlined class="white--text ml-5">Purchase</v-btn>
    -->
  </div>

</template>

<script>
import { navigateTo } from "@/routes/navigation";
import { firebaseLogOut } from "@/plugins/firebase/security";


export default {
  name: "appHeaderHelper",
  props: {
    userSubscribed: Boolean
  },
  // methods for component
  methods: {
    // method: calls navigate function
    navigateTo(nextRouteName) { navigateTo(nextRouteName); },
    // method: call firebase logout
    userLogOut() { firebaseLogOut(); },
  }

}
</script>

<style scoped>

.header-misc-v-btn {
  width: 48px !important;
  min-width: 48px !important;
  height: 48px !important;
  min-height: 48px !important;
}

</style>