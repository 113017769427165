import Vue from "vue"
import App from "./App.vue"
import vuetify from "@/plugins/vuetify/vuetify"
import store from "@/session/store"
import { router } from "@/routes/router"
import { firebase }  from "@/plugins/firebase/configuration"
import { firestorePlugin } from "vuefire"


// use firestore
Vue.use(firestorePlugin);

// disable production tips
Vue.config.productionTip = false
// create new vue instance
new Vue({
  router,
  store,
  firebase,
  vuetify,
  render: h => h(App),
}).$mount('#app')
