<template>

  <!-- Courses Table Container -->
  <v-row no-gutters><v-col>

    <!-- Courses Table Header -->
    <v-row no-gutters><v-col>
      <courses-table-header :height="headerHeight" />
    </v-col></v-row>

    <!-- Transition Group for Table Records -->
    <transition-group name="course-transition" tag="div" class="relative-course-parent">
      <!-- All Courses Table Records -->
      <v-row v-for="course in coursesSorted(courses, 'created.seconds')" :key="`${course.name}`"
             class="course-transition-item" no-gutters><v-col>
        <courses-table-record :course="course" :height="recordHeight" />
      </v-col></v-row>
    </transition-group>

  </v-col></v-row>

</template>

<script>
import coursesTableHeader from "@/pages/application/dashboardPage/panels/coursesPanel/components/coursesTableHeader";
import coursesTableRecord from "@/pages/application/dashboardPage/panels/coursesPanel/components/coursesTableRecord";
import { mapGetters } from "vuex";
import coursesMixin from "@/mixins/firestoreMixins/coursesMixin";

export default {
  name: "coursesTable",
  mixins: [ coursesMixin ],
  components: {
    coursesTableHeader,
    coursesTableRecord
  },
  props: {
    headerHeight: Number,
    recordHeight: Number
  },
  computed: {
    // computed: store/state getters
    ...mapGetters({ courses: "courses" })
  }
}
</script>

<style scoped>

/* event transition */
.course-transition-item {
  transition: all 0.75s;
}

.course-transition-leave-active {
  transition: all 0.25s;
}
.course-transition-leave-to {
  opacity: 0;
}

.course-transition-enter {
  opacity: 0;
  transform: translateX(1000px);
}


.relative-course-parent {
  position: relative;
}

</style>