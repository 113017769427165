<template>

  <!-- Header Card -->
  <v-row no-gutters><v-col>
    <v-card :height="height" color="transparent" class="pt-1 rounded-0 elevation-0">
      <v-row :height="height" no-gutters align="center" class="fill-inner-parent-ht"><v-col class="pl-6">
        <span class="text--secondary subtitle-2 font-weight-bold text-uppercase">
          {{ flightNumber > 0 ? `${flightMap[flightNumber]} Flight` : 'Championship' }}
        </span>
      </v-col></v-row>
    </v-card>

    <v-divider class="divider-opacity" />

  </v-col></v-row>

</template>

<script>
export default {
  name: "liveScoreFlight",
  props: {
    flightNumber: Number,
    // styling props
    height: Number
  },
  data: () => ({
    // display data
    flightMap: {1: 'First', 2: 'Second', 3: 'Third', 4: 'Fourth', 5: 'Fifth', 6: 'Sixth', 7: 'Seventh', 8: 'Eighth'}
  }),
}
</script>

<style scoped>

</style>