<template>

  <!-- Success Alerts with Message -->
  <v-dialog v-model="dialogDisplay" max-width="450"  class="elevation-0">
    <v-alert v-model="dialogDisplay" type="success" color="success" class="pa-5 ma-0 fill-height text-center">{{ message }}</v-alert>
  </v-dialog>

</template>

<script>
export default {
  name: "successDialog",
  props: {
    display: Boolean,
    message: String
  },
  computed: {
    dialogDisplay: {
      get () { return this.display },
      set () { return this.$emit('close'); }
    }
  }
}
</script>

<style scoped>

</style>