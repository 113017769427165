import { v4 as uuidv4 } from "uuid";
import { eventCollection } from "@/plugins/firebase/firestore";
import { firebase } from "@/plugins/firebase/configuration";


/*************************************************************
 Mixin for Events in Firestore
 **************************************************************/

var playersMixin = {

    methods: {
        /*****************************************************************
         *  Player: Methods relating to CRUD-OPERATIONS for Player
         ****************************************************************/
        // CRUD METHODS
        // method: create new player for event
        createPlayer (eventId) {
            // build new team payload
            let newPlayerId = uuidv4()
            let newPlayerPayload = { name: null, scorecard: {}, created: new Date() };
            // add team to event
            eventCollection.doc(eventId).update({ [`players.${newPlayerId}`]: newPlayerPayload });
        },
        // method: delete player/participant record from event
        deletePlayer (eventId, playerId) {
            // get event record from database and update players object
            eventCollection.doc(eventId).update({ [`players.${playerId}`]: firebase.firestore.FieldValue.delete() });
        }

    }
}

export default playersMixin;