<template>

  <!-- Purchase Page Container -->
  <v-row justify="center" align="center" class="pb-15" no-gutters><v-col>
    <!-- Heading Catch-line -->
    <v-row justify="center" align="center" no-gutters><v-col class="text-center">
      <span class="text-h4 font-weight-bold">Simple pricing for everyone</span>
    </v-col></v-row>
    <!-- Heading Catch-line Sub-text -->
    <v-row justify="center" align="center" class="mt-3" no-gutters><v-col class="text-center">
      <span class="subtitle-1 subtext-style font-weight-medium">Select a plan that fits your season.</span>
    </v-col></v-row>
    <v-row justify="center" align="center" no-gutters><v-col class="text-center">
      <span class="subtitle-1 subtext-style font-weight-medium">Affordable for any size course, large or small.</span>
    </v-col></v-row>

    <!-- High-level Price Container -->
    <v-row v-if="showProducts" justify="center" align="center" class="mt-10" no-gutters>
      <v-col align="center" xl="6" lg="8" md="10" sm="12">

        <v-card color="transparent" class="pa-0 price-container rounded-xl" flat>
          <!-- Prices -->
          <v-row class="prices-row mb-2" no-gutters>
            <v-spacer />
            <!-- Monthly Price -->
            <v-hover v-slot="{ hover }">
              <v-card height="100%" width="250" color="info" :elevation="hover ? 16 : 0" hover
                      class="pa-10 rounded-xl rounded-br-0 rounded-tr-0">
                <!-- Plan Title -->
                <v-row align="center" no-gutters><v-col class="text-center">
                  <span class="text-h4 font-weight-bold">{{ products[1].priceName }}</span>
                </v-col></v-row>
                <!-- Plan Price -->
                <v-row justify="center" align="center" class="mt-6" no-gutters><v-col class="text-center">
                  <span class="text-h2 white--text font-weight-bold">$</span>
                  <span class="text-h2 white--text font-weight-bold">{{ products[1].priceAmount / 100 }}</span>
                </v-col></v-row>
                <v-row justify="center" align="center" class="mt-2" no-gutters><v-col class="text-center">
                  <span class="subtitle-1 white--text font-weight-bold text-uppercase">PER {{ products[1].priceInterval }}</span>
                </v-col></v-row>
                <!-- Select Plan Button -->
                <v-row class="mt-7" no-gutters><v-col class="text-center">
                  <v-btn width="100%" color="secondary" @click="navigateTo('register')" large depressed>
                    <span class="white--text text-subtitle-2 font-weight-bold">Get Started</span>
                    <v-icon class="ml-3 white--text font-weight-bold">east</v-icon>
                  </v-btn>
                </v-col></v-row>
              </v-card>
            </v-hover>
            <v-hover v-slot="{ hover }">
              <!-- Yearly Price -->
              <v-card height="100%" width="250" color="secondary" :elevation="hover ? 16 : 0" hover
                      class="pa-10 rounded-xl rounded-bl-0 rounded-tl-0">
                <!-- Plane Title -->
                <v-row align="center" no-gutters><v-col class="text-center">
                  <span class="info--text text-h4 font-weight-bold">{{ products[0].priceName }}</span>
                </v-col></v-row>
                <!-- Plan Price -->
                <v-row justify="center" align="center" class="mt-6" no-gutters><v-col class="text-center">
                  <span class="text-h2 info--text font-weight-bold">$</span>
                  <span class="text-h2 info--text font-weight-bold">{{ products[0].priceAmount / 100 }}</span>
                </v-col></v-row>
                <v-row justify="center" align="center" class="mt-2" no-gutters><v-col class="text-center">
                  <span class="subtitle-1 info--text font-weight-bold text-uppercase">PER {{ products[0].priceInterval }}</span>
                </v-col></v-row>
                <!-- Select Plan Button -->
                <v-row class="mt-7" no-gutters><v-col class="text-center">
                  <v-btn width="100%" color="info" @click="navigateTo('register')" large depressed>
                    <span class="secondary--text text-subtitle-2 font-weight-bold">Get Started</span>
                    <v-icon class="ml-3 secondary--text font-weight-bold">east</v-icon>
                  </v-btn>
                </v-col></v-row>
              </v-card>
            </v-hover>

          </v-row>

          <!-- Plan Features -->
          <v-row v-for="key in Object.keys(products[0].productAttr).sort()" :key="key" no-gutters><v-col>
            <v-row class="pl-10 pt-5 pb-5">
              <v-col class="feature-name-col font-weight-bold text--secondary text-left">{{ key.split('|')[1] }}</v-col>
              <v-col class="text-center">
                <span v-if="products[1].productAttr[key] === 'Unlimited'"><v-icon>all_inclusive</v-icon></span>
                <span v-else-if="products[1].productAttr[key] === 'Enabled'"><v-icon color="success">check_circle</v-icon></span>
                <span v-else-if="products[1].productAttr[key] === 'Disabled'"><v-icon color="error">cancel</v-icon></span>
                <span v-else>{{ products[1].productAttr[key] }}</span>
              </v-col>
              <v-col class="text-center">
                <span v-if="products[0].productAttr[key] === 'Unlimited'"><v-icon>all_inclusive</v-icon></span>
                <span v-else-if="products[0].productAttr[key] === 'Enabled'"><v-icon color="success">check_circle</v-icon></span>
                <span v-else-if="products[0].productAttr[key] === 'Disabled'"><v-icon color="error">cancel</v-icon></span>
                <span v-else>{{ products[0].productAttr[key] }}</span>
              </v-col>
            </v-row>
            <v-divider class="divider-opacity" />
          </v-col></v-row>

        </v-card>

      </v-col>
    </v-row>

  </v-col></v-row>

</template>

<script>
import { productCollection } from "@/plugins/firebase/firestore";
import {navigateTo} from "@/routes/navigation";

export default {
  name: "pricingPage",
  computed: {
    // computed: checks if products available to show
    showProducts() { return this.products.length > 0 }
  },
  data: () => ({
    // products from stripe
    products: []
  }),
  methods: {
    // method: build product information
    setProducts () {
      // product container
      let container = []
      // query active products
      productCollection.where('active', '==', true).get()
          .then(querySnapshot => {
            // get product data
            let productData = querySnapshot.docs.map(doc => doc.data());
            // loop through products and get prices
            productData.forEach(product => {
              // query active prices for product
              productCollection.doc(product.product_id).collection('price').where('active', '==', true).get()
                  .then(querySnapshot => {
                    // get price data
                    let priceData = querySnapshot.docs.map(doc => doc.data());
                    // loop through price data
                    priceData.forEach(price => {
                      // build product data
                      let liveProduct = {
                        productId : product.product_id,
                        productName : product.name,
                        productDesc : productData.description,
                        productAttr : product.metadata,
                        priceId : price.price_id,
                        priceName : price.name,
                        priceInterval : price.recurring.interval,
                        priceAmount : price.unit_amount,
                      }
                      // add product to container
                      container.push(liveProduct);
                    });
                  });
            });
          });
      // set products to fetched products
      this.products = container;
    },

    // UTILITY METHODS
    // method: calls navigate function
    navigateTo(nextRouteName) { navigateTo(nextRouteName); }
  },
  mounted () {
    // on mount, fetch products from firestore
    this.setProducts()
  }
}
</script>

<style scoped>

.subtext-style {
  opacity: 0.3 !important;
}

.price-container {
  width: 800px !important;
}

.prices-row {
  height: 320px !important;
}

.feature-name-col {
  width: 200px !important;
}

</style>