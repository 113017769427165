import moment from "moment";


/*************************************************************
 Mixin for Utilities
 **************************************************************/

var utilityMixin = {

    data: () => ({
        // window-related data
        windowHeight: 0
    }),

    methods: {
        // WINDOW-RELATED METHODS
        getWindowHeight() { this.windowHeight = document.documentElement.clientHeight; },

        // CURRENCY-RELATED METHODS
        // method: format currency from stripe
        currencyFormat (value) { return (Math.round(value) / 100).toFixed(2); },

        // DATE-RELATED METHODS
        // method: format date from firebase
        dateFormat (firebaseDate) { return moment( firebaseDate.seconds * 1000 ).format("MMM DD, YYYY"); },
        // method: format date from stripe
        dateFormatStripe (stripeDate) { return moment( stripeDate * 1000 ).format("MMM DD, YYYY"); }
    },
    mounted () {
        // mounted: add window event listener
        this.$nextTick(function() {
            window.addEventListener('resize', this.getWindowHeight);
            this.getWindowHeight();
        });
    },
    beforeDestroy() {
        // beforeDestroy: remove window event listener
        window.removeEventListener('resize', this.getWindowHeight);
    }

}

export default utilityMixin;