import { render, staticRenderFns } from "./iconThirdPlace.vue?vue&type=template&id=1124bccf&scoped=true&"
import script from "./iconThirdPlace.vue?vue&type=script&lang=js&"
export * from "./iconThirdPlace.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1124bccf",
  null
  
)

export default component.exports