<template>

  <!-- Modify/Create Course Dialogue -->
  <v-dialog v-model="display" max-width="600" class="elevation-0" persistent>

    <v-card class="secondary lighten-1">
      <!-- Add Event Heading/Title -->
      <v-card-title class="text-h6">{{ eventTitle }}</v-card-title>
      <v-card-subtitle class="pt-1">Complete fields below and save</v-card-subtitle>
      <!-- Event detail input -->
      <v-divider />
      <v-card-text>
        <v-form ref="eventForm" v-model="eventInputValid" method="post">
          <!-- Input: Event Name -->
          <v-row no-gutters><v-col cols="12" class="pa-0 pt-5">
            <v-text-field v-model="eventName" label="Event Name" :rules="[eventNameExists]"
                          filled outlined dense clearable hide-details required />
          </v-col></v-row>

          <!-- Input: Course Selection -->
          <v-row class="mt-3" no-gutters><v-col cols="12" class="pa-0">
            <v-select v-model="eventCourseId" :items="courses" item-value="id" item-text="name" :rules="[eventCourseExists]"
                      label="Course" filled outlined dense clearable required hide-details>

              <!-- Selection Template -->
              <template slot="selection" slot-scope="{ item }">
                <!-- List Item Avatar -->
                <v-icon class="pt-1" small>$vuetify.icons.courseIcon</v-icon>
                <!-- List Course Name -->
                <span class="pl-3 pt-1">{{ item.name }}</span>
              </template>

              <!-- Selection List Template -->
              <template slot="item" slot-scope="{ item }">
                <!-- List Item Avatar -->
                <v-list-item-icon>
                  <v-icon>$vuetify.icons.courseIcon</v-icon>
                </v-list-item-icon>
                <!-- List Course Name -->
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle v-html="`
                    <v-row no-gutters>
                        <!-- Created On -->
                        <span class='caption text--secondary'>${ dateFormat(item.created) }</span>
                        <!-- Hole Count -->
                        <span class='caption text--secondary'>  |  ${Object.entries(item.holes).length} Holes</span>
                        <!-- Course Par -->
                        <span class='caption text--secondary'>  |  Par ${coursePar(item)}</span>
                    </v-row>
                  `"/>
                </v-list-item-content>
              </template>
            </v-select>
          </v-col></v-row>

          <!-- Input: Hole Count -->
          <v-row class="mt-3" no-gutters><v-col cols="12" class="pa-0">
            <v-btn-toggle v-model="eventHoles" group mandatory dense>
              <v-btn width="150px" class="ml-0 mr-0 disable-btn-events" text outlined>Holes</v-btn>
              <v-btn value="front" width="134px" class="ml-0 mr-0" text outlined :disabled="allHolesDisabled">Front 9</v-btn>
              <v-btn value="back" width="134px" class="ml-0 mr-0" text outlined :disabled="backHolesDisabled">Back 9</v-btn>
              <v-btn value="full" width="134px" class="ml-0 mr-0" text outlined :disabled="backHolesDisabled">Full 18</v-btn>
            </v-btn-toggle>
          </v-col></v-row>

          <!-- Input: Event Scorecard Playoff -->
          <v-row class="mt-1" no-gutters><v-col cols="12" class="pa-0">
            <v-btn-toggle v-model="eventPlayoff" group mandatory dense>
              <v-btn width="150px" class="ml-0 mr-0 disable-btn-events" text outlined>Playoff Hole</v-btn>
              <v-btn value="random" width="201px" class="ml-0 mr-0" text outlined>Random</v-btn>
              <v-btn value="handicap" width="201px" class="ml-0 mr-0" text outlined>Hole Handicap</v-btn>
            </v-btn-toggle>
          </v-col></v-row>

          <!-- Input: Event Handicapped  -->
          <v-row class="mt-1" no-gutters><v-col cols="12" class="pa-0">
            <v-btn-toggle v-model="eventHandicap" group mandatory dense>
              <v-btn width="150px" class="ml-0 mr-0 disable-btn-events" text outlined>Handicap</v-btn>
              <v-btn :value="false" width="201px" class="ml-0 mr-0" text outlined>No</v-btn>
              <v-btn :value="true" width="201px" class="ml-0 mr-0" text outlined>Yes</v-btn>
            </v-btn-toggle>
          </v-col></v-row>

          <!-- Input: Event Skins  -->
          <v-row class="mt-1" no-gutters><v-col cols="12" class="pa-0">
            <v-btn-toggle v-model="eventSkins" group mandatory dense>
              <v-btn width="150px" class="ml-0 mr-0 disable-btn-events" text outlined>Skins</v-btn>
              <v-btn :value="false" width="201px" class="ml-0 mr-0" text outlined>No</v-btn>
              <v-btn :value="true" width="201px" class="ml-0 mr-0" text outlined>Yes</v-btn>
            </v-btn-toggle>
          </v-col></v-row>

          <!-- Input: Event Flights  -->
          <v-row class="mt-1" no-gutters><v-col cols="12" class="pa-0">
            <v-btn-toggle v-model="eventFlights" group mandatory dense>
              <v-btn width="150px" class="ml-0 mr-0 disable-btn-events" text outlined>Flights</v-btn>
              <v-btn :value="1" width="50px" class="ml-0 mr-0" text outlined>1</v-btn>
              <v-btn :value="2" width="50px" class="ml-0 mr-0" text outlined>2</v-btn>
              <v-btn :value="3" width="50px" class="ml-0 mr-0" text outlined>3</v-btn>
              <v-btn :value="4" width="50px" class="ml-0 mr-0" text outlined>4</v-btn>
              <v-btn :value="5" width="50px" class="ml-0 mr-0" text outlined>5</v-btn>
              <v-btn :value="6" width="50px" class="ml-0 mr-0" text outlined>6</v-btn>
              <v-btn :value="7" width="51px" class="ml-0 mr-0" text outlined>7</v-btn>
              <v-btn :value="8" width="51px" class="ml-0 mr-0" text outlined>8</v-btn>
            </v-btn-toggle>
          </v-col></v-row>

        </v-form>
      </v-card-text>

      <!-- Event Actions -->
      <v-divider />
      <v-card-actions>
        <v-row class="pa-2 text-right" no-gutters><v-col cols="12">
          <!-- Cancel Event Creation -->
          <v-btn small outlined @click="closeDialog" class="white--text">Cancel</v-btn>
          <!-- Create New Event -->
          <v-btn small outlined @click="eventUpsert" class="success--text ml-3" :disabled="!eventInputValid">Save</v-btn>
        </v-col></v-row>
      </v-card-actions>
    </v-card>

    <!-- success/error dialogs -->
    <success-dialog :display="successDialogDisplay" :message="successDialogMessage" @close="successDialogDisplay=false" />
    <error-dialog :display="errorDialogDisplay" :message="errorDialogMessage" @close="errorDialogDisplay=false" />

  </v-dialog>

</template>

<script>
import { mapGetters } from "vuex";
import { eventCollection, courseCollection } from "@/plugins/firebase/firestore";
import moment from "moment";
import dialogMixin from "@/mixins/componentMixins/dialogMixin";

export default {
  name: "eventDialog",
  mixins: [dialogMixin],
  props: {
    // prop: display this dialog
    display: Boolean,
    // prop: event detail, if passed
    event: Object,
    eventTitle: String
  },
  computed: {
    // computed: store/state getters
    ...mapGetters({ courses: "courses", user: "user" }),
    // computed: get course object from selected id
    eventCourse() { return this.courses.find((course) => { return course.id === this.eventCourseId }) },
    // computed: holes disabled if course is not set
    allHolesDisabled () { return !this.eventCourse },
    // computed: holes disabled if course cannot support
    backHolesDisabled () { return this.eventCourse ? Object.entries(this.eventCourse.holes).length < 18 : true },
    // computed: reconcile selected holes based on course selection
    reconcileEventHoles () {
      return Object.entries(this.eventCourse.holes).length < 18 ? 'front' : this.eventHoles
    }
  },
  data: () => ({
    // event form inputs
    eventName: null,
    eventCourseId: null,
    eventHandicap: false,
    eventSkins: false,
    eventHoles: 'front',      // ['front', 'back', 'full']
    eventPlayoff: 'random',
    eventFlights: 1,
    // event form validation
    eventInputValid: false,
  }),
  methods: {
    // VALIDATION METHODS
    // method: does event name exist
    eventNameExists (value) { return !!value },
    // method: has user selected course
    eventCourseExists (value) { return !!value },

    // COMPUTED METHOD
    // method: calculate par
    // computed: calculate course par
    coursePar (course) {
      let par = 0;
      for (let hole of Object.keys(course['holes'])) { par += parseInt(course['holes'][hole]['par']) }
      return par
    },

    // ACTION METHODS
    // method: execute create OR update
    eventUpsert () {
      // if course id has been passed, update existing course
      if (this.event && this.event.id) { this.updateEvent() }
      // else, create new course
      else { this.createEvent() }
    },
    // method: UPDATE existing event
    updateEvent () {
      // build event payload to update event
      let eventPayload = {
        name: this.eventName,
        course: courseCollection.doc(this.eventCourse.id),
        holes: this.reconcileEventHoles,
        handicap: this.eventHandicap,
        skins: this.eventSkins,
        playoff_type: this.eventPlayoff,
        playoff: (this.event.playoff_type === this.eventPlayoff && this.event.playoff) ? this.event.playoff : this.getPlayoffHole(),
        flights: this.eventFlights
      }
      // update existing event
      eventCollection.doc(this.event.id).update(eventPayload)
          .then(() => {
            // success, display message
            this.alertSuccess('Event successfully updated!')
            // close dialog
            this.closeDialog();
            // reset event form
            this.resetEventFormData();
            // close message dialog
            setTimeout(() => { this.successAlertDisplay = false; }, 1000);
          })
          .catch(error => {
            // error occurred, display message
            this.alertError('An error occurred updating your event, review your input and try again.');
            // throw error
            throw error;
          });
    },
    // method: CREATE new event
    createEvent () {
      // build event payload to create
      let eventPayload = {
        name: this.eventName,
        course: courseCollection.doc(this.eventCourse.id),
        holes: this.reconcileEventHoles,
        handicap: this.eventHandicap,
        skins: this.eventSkins,
        playoff_type: this.eventPlayoff,
        playoff: this.getPlayoffHole(),
        flights: this.eventFlights,
        players: {},
        creator: this.user.uid,
        created: new Date()
      }
      // create new event content
      eventCollection.add(eventPayload)
          .then(() => {
            // success, display message
            this.alertSuccess('New event successfully created!')
            // close dialog
            this.closeDialog();
            // reset event form
            this.resetEventFormData();
            // close message dialog
            setTimeout(() => { this.successAlertDisplay = false; }, 1000);
          })
          .catch(error => {
            // error occurred, display message
            this.alertError('An error occurred creating your new event, review your input and try again.')
            // throw error
            throw error;
          });
    },
    // method: reset event form data
    resetEventFormData () {
      // reset event form
      this.$refs.eventForm.reset();
      // manually reset other forms to default
      this.eventName = null;
      this.eventCourseId = null;
      this.eventHandicap = false;
      this.eventSkins = false;
      this.eventHoles = 'front';
      this.eventPlayoff = 'random';
      this.eventFlights = 1;
    },

    // UTILITY METHODS
    // method: close dialog
    closeDialog() { this.$emit('closeDialog'); },
    // method: format date from stripe
    dateFormat (firebaseDate) { return moment( firebaseDate.seconds * 1000 ).format("MMM DD, YYYY"); },

    // method: generate random playoff hole
    getPlayoffHole () {
      // direct to function, based on eventPlayoff selection
      if (this.eventPlayoff === 'random') { return this.playoffHoleRandom() }
      // else, playoff hole is hardest handicap
      else if (this.eventPlayoff === 'handicap') { return this.playoffHoleHandicap() }
    },
    // method: generate random playoff hole
    playoffHoleRandom () {
      // if course holes are 'back'
      if (this.reconcileEventHoles === 'back') { return Math.floor(Math.random() * (Math.floor(18) - Math.ceil(10) + 1)) + Math.ceil(10); }
      // if course holes are 'full'
      else if (this.reconcileEventHoles === 'full') { return Math.floor(Math.random() * (Math.floor(18) - Math.ceil(1) + 1)) + Math.ceil(1); }
      // else course holes are 'front'
      else { return Math.floor(Math.random() * (Math.floor(9) - Math.ceil(1) + 1)) + Math.ceil(1); }
    },
    // method: find handicapped playoff hole
    playoffHoleHandicap () {
      // define holes content
      let holesFront = [1, 2, 3, 4, 5, 6, 7, 8, 9];
      let holesBack = [10, 11, 12, 13, 14, 15, 16, 17, 18];
      // set trimmedHoles container
      let trimmedHoles = [];
      // check if hole number is in appropriate range
      if (this.reconcileEventHoles === 'front') {
        trimmedHoles = Object.values(this.eventCourse.holes).map((hole) => holesFront.includes(hole.hole) ? hole : undefined);
      }
      else if (this.reconcileEventHoles === 'back') {
        trimmedHoles = Object.values(this.eventCourse.holes).map((hole) => holesBack.includes(hole.hole) ? hole : undefined);
      }
      else { trimmedHoles = Object.values(this.eventCourse.holes); }
      // remove undefined from trimmed holes
      trimmedHoles = trimmedHoles.filter(x => x !== undefined)
      // find hardest hole and return hole number
      return trimmedHoles.reduce(function(prev, curr) { return prev.handicap < curr.handicap ? prev : curr; }).hole;
    },

    // method: pre-populate data from event information
    populateEvent () {
      // event form inputs
      if (this.event) {
        // build data attributes
        this.eventName = this.event.name ? this.event.name : null;
        this.eventCourseId = this.event.course ? this.event.course.id : null;
        this.eventHandicap = this.event.handicap ? this.event.handicap : false;
        this.eventSkins = this.event.skins ? this.event.skins : false;
        this.eventHoles = this.event.holes ? this.event.holes : 'front';  // ['front', 'back', 'full']
        this.eventPlayoff = this.event.playoff_type ? this.event.playoff_type : 'random';
        this.eventFlights = this.event.flights ? this.event.flights : 1;
      }
    }
  },
  mounted () {
    // mounted: populate event data if event passed
    this.populateEvent()
  },
  beforeRouteLeave() {
    // beforeDestroy: reset form data to defaults
    this.resetEventFormData()
  }
}
</script>

<style scoped>

.eighteen-hole-styling {
  opacity: 0.25 !important;
}

/* Style first button in group (acts as header) */
.v-btn-toggle--group>.v-btn.v-btn:first-child {
  background-color: #062536 !important;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}
.disable-btn-events {
  pointer-events: none !important;
}
.v-btn-toggle--group>.v-btn.v-btn:last-child {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

</style>