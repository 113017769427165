<template>

  <v-btn class="pa-0" text disabled>
    <v-icon small>{{ icon }}</v-icon>
    <span class="ml-1 subtitle-2 font-weight-bold">
      <span class="text--secondary font-weight-bold metric-category">{{ category }}</span>
      <span class="ml-2 text--secondary font-weight-bold">{{ value }}</span>
    </span>
  </v-btn>

</template>

<script>
export default {
  name: "eventMetricTag",
  props: {
    icon: String,
    category: String,
    value: [Number, String]
  }
}
</script>

<style scoped>

.metric-category {
  opacity: 0.65 !important;
}


</style>