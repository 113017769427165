import successDialog from "@/components/dialogs/feedback/successDialog";
import errorDialog from "@/components/dialogs/feedback/errorDialog";
import confirmDialog from "@/components/dialogs/feedback/confirmDialog";

/*************************************************************
 Mixin for Dialogs
 **************************************************************/

var dialogMixin = {

    components: {
        successDialog,
        errorDialog,
        confirmDialog
    },
    data: () => ({
        // success dialog data
        successDialogDisplay: false,
        successDialogMessage: null,
        // error dialog data
        errorDialogDisplay: false,
        errorDialogMessage: null,
        // confirm dialog data
        confirmDialogDisplay: false,
        confirmDialogMessage: null
    }),
    methods: {
        // ACTION METHODS
        // method: launch success alert
        alertSuccess(message, duration=2000) {
            this.successDialogMessage = message;
            this.successDialogDisplay = true;
            setTimeout(() => { this.successDialogDisplay = false; }, duration);
        },
        // method: launch success alert
        alertError(message, duration=2000) {
            this.errorDialogMessage = message;
            this.errorDialogDisplay = true;
            setTimeout(() => { this.errorDialogDisplay = false; }, duration);
        }

    }
}

export default dialogMixin;