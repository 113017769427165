<template>
  <!-- Application Container (vuetify)-->
  <v-app>
    <!-- Header for application -->
    <v-slide-y-transition leave-absolute group>
      <app-header v-if="user.loggedIn && $route.name !== 'login'" key="app-header"
                  :user-subscribed="subscriptionActive" class="pl-11 pr-11" />
      <web-header v-else key="web-header" />
    </v-slide-y-transition>

    <!-- Main for application content -->
    <v-main class="pt-0 fill-parent-ht">
      <!-- Provides the application the proper gutter -->
      <v-container class="container-padding background-style fill-height"
                   :class="user.loggedIn && $route.name !== 'login' ? 'pl-15 pr-15 pb-10 app-container-padding' : 'pl-0 pr-0 pb-0 web-container-padding'"
                   fluid align-start>
        <!-- Transition Animation between Routes -->
        <transition :name="transitionDirectionName" mode="out-in">
          <!-- Routing Handler -->
          <router-view :key="$route.fullPath" class="router-item"></router-view>
        </transition>
      </v-container>
    </v-main>

    <!-- Footer for application -->
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import appHeader from "@/components/layout/headers/appHeader";
import webHeader from "@/components/layout/headers/webHeader";

export default {
  name: 'App',
  components: {
    appHeader,
    webHeader
  },
  computed: {
    // computed: store/state getters
    ...mapGetters({ user: "user", subscriptionActive: "subscriptionActive", styling: "styling" }),
    // computed: transition direction name for transitions
    transitionDirectionName () { return 'slide-' + this.styling.transitionDirection + '-complete' }
  }
}

</script>

<style>

  /* GLOBAL STYLING */
  .divider-opacity {
    opacity: 0.25 !important;
  }
  .fill-parent-ht {
    height: calc(100% - 80px) !important;
  }
  .fill-inner-parent-ht {
    height: 100% !important;
  }

  /* default v-app styles */
  /* hides scrollbar */
  html {
    overflow: auto !important;
    -ms-overflow-style: none;
  }
  html::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  /* virtual scroller styling */
  div::-webkit-scrollbar {
    width: 6px;
    background-color: #0d2334;
  }
  div::-webkit-scrollbar-track {
    background-color: #0d2334;
  }
  div::-webkit-scrollbar-thumb {
    background-color: #b3bbc2;
  }

  /* this content styling */
  .background-style {
    background: linear-gradient(#021521, #062536);
    /*background: radial-gradient(130% 150% ellipse at 60% 5%, #21485f, #061a2f);*/
  }
  .web-container-padding{
    padding-top: 80px !important;
  }
  .app-container-padding{
    padding-top: 160px !important;
  }

  /* header main transitions */
  .header-main-item {
    transition: all 1s;
  }
  .header-main-complete-enter, .header-main-complete-leave-to {
    opacity: 0;
    transform: translateY(-300px);
  }
  .header-main-complete-leave-active {
    position: absolute;
  }

  /* router view transitions */
  /* translateX for enter and leave-to set to slide left to right (ie. -1500 and 1500)
     right-to-left slide introduces a flicker (ie. 1500 and -1500) */
  .router-item{
    transition: all 0.4s ease;
  }
  /* slide left out of screen */
  .slide-left-complete-enter {
    opacity: 0;
    transform: translateX(500px);
    position: absolute;
    top: 0 !important;
  }
  .slide-left-complete-enter-to, .slide-left-complete-leave {
    opacity: 1;
  }
  .slide-left-complete-leave-to {
    opacity: 0;
    transform: translateX(-1000px);
  }
  /* slide right out of screen */
  .slide-right-complete-enter {
    opacity: 0;
    transform: translateX(-500px);
    position: absolute;
    top: 0 !important;
  }
  .slide-right-complete-enter-to, .slide-right-complete-leave {
    opacity: 1;
  }
  .slide-right-complete-leave-to {
    opacity: 0;
    transform: translateX(1000px);
  }

</style>
