<template>

  <!-- Home/Landing Page Container -->
  <v-row class="pt-5" no-gutters><v-col>

    <!-- Heading Catch-line -->
    <v-row justify="center" align="center" no-gutters><v-col class="text-center">
      <span class="text-h4 font-weight-bold">Managing your event scores</span>
    </v-col></v-row>
    <v-row justify="center" align="center" no-gutters><v-col class="text-center">
      <span class="text-h4 font-weight-bold">should be a one man job</span>
    </v-col></v-row>

    <!-- Heading Catch-line Sub-text -->
    <v-row justify="center" align="center" class="mt-3" no-gutters><v-col class="text-center">
      <span class="text-h5 subtext-style font-weight-medium">Put away the score sheets and</span>
    </v-col></v-row>
    <v-row justify="center" align="center" no-gutters><v-col class="text-center">
      <span class="text-h5 subtext-style font-weight-medium">keep score the modern way</span>
    </v-col></v-row>

    <!-- Get started Button -->
    <v-row justify="center" align="center" class="mt-10" no-gutters><v-col class="text-center">
      <v-btn color="success" @click="navigateTo('register')" x-large>
        <span class="secondary--text text--darken-3 text-h6 font-weight-bold">Get Started</span>
        <v-icon class="ml-3 secondary--text text--darken-3 font-weight-bold">east</v-icon>
      </v-btn>
    </v-col></v-row>

    <!-- Screenshot Card -->
    <v-row justify="center" align="center" class="mt-13" no-gutters><v-col xl="8" lg="10" class="text-center">
      <v-card width="100%" min-height="400" max-height="800" :height="mockDisplayHeight" class="pl-5 pt-5 pr-5 pb-0 bezel-style elevation-0 rounded-xl rounded-b-0">
          <v-fade-transition>
          <div class="pl-2 pt-2 pr-3 pb-0 crop-screenshot">
            <v-img :src="images.screenshot" />
          </div>
        </v-fade-transition>
      </v-card>
    </v-col></v-row>

    <!-- Features Panel/Row -->
    <features-panel class="panel-elevation" />
    <!-- Price Panel/Row -->
    <price-panel class="panel-elevation" />
    <!-- Questions/FAQ Panel/Row -->
    <faq-panel class="panel-elevation" />
    <!-- Follow-up Panel -->
    <follow-panel />

  </v-col></v-row>

</template>

<script>
import utilityMixin from "@/mixins/utilityMixin";
import {navigateTo} from "@/routes/navigation";
import pricePanel from "@/pages/public/homePage/components/pricePanel";
import faqPanel from "@/pages/public/homePage/components/faqPanel";
import followPanel from "@/pages/public/homePage/components/followPanel";
import FeaturesPanel from "@/pages/public/homePage/components/featuresPanel";

export default {
  name: "homePage",
  mixins: [utilityMixin],
  components: {
    FeaturesPanel,
    pricePanel,
    faqPanel,
    followPanel
  },
  computed: {
    // computed: mock display height
    mockDisplayHeight () {
      return this.windowHeight - 474;
    }
  },
  data: () => ({
    // image data
    images: {
      screenshot: require('/src/assets/scorepro-screen.png')
    }
  }),
  methods: {
    // method: calls navigate function
    navigateTo(nextRouteName) { navigateTo(nextRouteName); }
  }
}
</script>

<style scoped>

.subtext-style {
  opacity: 0.3 !important;
}

/* bezel style */
.bezel-style {
  background: linear-gradient(#0A2C3E, #021D2B);
}

.crop-screenshot {
  opacity: 0.8 !important;
}

.panel-elevation {
  position: relative;
  z-index: 1 !important;
}

</style>