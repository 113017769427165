<template>

  <!-- Events Table Record Container -->
  <v-row no-gutters><v-col>

    <!-- Events Table Record -->
    <v-card @click="viewEventAdmin(event.id)" :height="height" color="transparent" class="rounded-0 elevation-0 row-card">
      <v-row :height="height" no-gutters align="center" class="fill-inner-parent-ht">
        <!-- Event Type Icon (League, Tournament, Etc...) -->
        <v-col cols="1" class="text-center table-icon-width">
          <v-icon class="event-type-icon-styling">$vuetify.icons.scoreboardIcon</v-icon>
        </v-col>
        <!-- Event Name -->
        <v-col>
          <span class="text-subtitle-2 text--secondary">{{ event.name }}</span>
        </v-col>
        <!-- Event Date -->
        <v-col cols="1" class="text-left">
          <span class="text-subtitle-2 text--secondary">{{ dateFormat(event.created) }}</span>
        </v-col>
        <!-- Course -->
        <v-col cols="2">
          <span class="text-subtitle-2 text--secondary">{{ event.course.name }}</span>
        </v-col>
        <!-- Course Side -->
        <v-col cols="1">
          <span class="text-subtitle-2 text--secondary font-weight-bold text-capitalize">{{ event.holes }}</span>
        </v-col>

        <!-- Hole Count -->
        <v-col cols="1" class="text-center">
          <span class="text-subtitle-2 text--secondary">{{ eventHoleCount(event) }}</span>
        </v-col>
        <!-- Flight Count -->
        <v-col cols="1" class="text-center">
          <span class="text-subtitle-2 text--secondary">{{ event.flights }}</span>
        </v-col>
        <!-- Player Count -->
        <v-col cols="1" class="text-center">
          <span class="text-subtitle-2 text--secondary">{{ eventPlayerCount(event) }}</span>
        </v-col>

        <!-- Event Live -->
        <v-col cols="1" class="text-center">
          <!-- View Live Scoreboard -->
          <v-btn :id="`event_live_${ event.id }`" @click.stop="viewEventLive(event.id)" class="ml-3 warning--text" outlined text>
            <v-icon small>emoji_events</v-icon>
            <span class="ml-3 caption font-weight-bold">Live</span>
          </v-btn>
          <!-- Event Live/Offline Tooltip -->
          <v-tooltip color="secondary" bottom :activator="`#event_live_${ event.id }`">
            <span>Live Scoreboard</span>
          </v-tooltip>
        </v-col>

        <!-- Event Actions -->
        <v-col cols="1" class="text-right pr-3">

          <!-- Update Event -->
          <v-btn :id="`event_update_${ event.id }`" @click.stop="showEventDialog" text dense fab small>
            <v-icon class="text--secondary" color="white">settings</v-icon>
          </v-btn>
          <!-- Delete Event -->
          <v-btn :id="`event_delete_${ event.id }`" @click.stop="deleteEvent(event.id)" class="ml-3" text dense fab small>
            <v-icon class="text--secondary" color="white">close</v-icon>
          </v-btn>

          <!-- Event Update Tooltip -->
          <v-tooltip color="secondary" bottom :activator="`#event_update_${ event.id }`">
            <span>Update</span>
          </v-tooltip>
          <!-- Event Delete Tooltip -->
          <v-tooltip color="secondary" bottom :activator="`#event_delete_${ event.id }`">
            <span>Delete</span>
          </v-tooltip>
        </v-col>

      </v-row>
    </v-card>
    <v-divider class="divider-opacity" />

    <!-- Update Event Modal -->
    <event-dialog :key="displayKey" :event-title="`Change Event - ${event.name}`" :event="event" :display="displayEventDialog" @closeDialog="hideEventDialog" />

  </v-col></v-row>

</template>

<script>
import eventsMixin from "@/mixins/firestoreMixins/eventsMixin";
import utilityMixin from "@/mixins/utilityMixin";
import eventDialog from "@/components/dialogs/entities/eventDialog";

export default {
  name: "eventsTableRecord",
  mixins: [ eventsMixin, utilityMixin ],
  components: {
    eventDialog
  },
  props: {
    event: Object,
    height: Number
  },
  data: () => ({
    // display data
    displayKey: 1,
    displayEventDialog: false,
    displaySaveDialog: false
  }),
  methods: {
    // UTILITY METHODS
    // methods: show/hide add events dialog
    showEventDialog() {
      this.displayKey += 1;
      this.displayEventDialog = true;
    },
    hideEventDialog() { this.displayEventDialog = false; }
  }
}
</script>

<style scoped>

.table-icon-width {
  max-width: 100px !important;
}

.row-card:hover {
  background: #062536 !important;
}

</style>