<template>

  <!-- Courses Table Record Container -->
  <v-row no-gutters><v-col>

    <!-- Courses Table Record -->
    <v-card :height="height" color="transparent" class="rounded-0 elevation-0">
      <v-row :height="height" no-gutters align="center" class="fill-inner-parent-ht">
        <!-- Courses Icon -->
        <v-col cols="1" class="text-center table-icon-width">
          <v-icon class="event-type-icon-styling">$vuetify.icons.courseIcon</v-icon>
        </v-col>
        <!-- Course Name -->
        <v-col>
          <span class="text-subtitle-2 text--secondary">{{ course.name }}</span>
        </v-col>
        <!-- Course Created Date -->
        <v-col cols="3" class="text-left">
          <span class="text-subtitle-2 text--secondary">{{ dateFormat(course.created) }}</span>
        </v-col>
        <!-- Course Hole Count -->
        <v-col cols="2" class="text-center">
          <span class="text-subtitle-2 text--secondary">{{ courseHoleCount(course) }}</span>
        </v-col>
        <!-- Course Par -->
        <v-col cols="2" class="text-center">
          <span class="text-subtitle-2 text--secondary">{{ coursePar(course) }}</span>
        </v-col>

        <!-- Course Actions -->
        <v-col cols="1" class="text-right pr-3">
          <!-- Update Course -->
          <v-btn :id="`course_update_${ course.id }`" @click="showCoursesDialog" class="ml-3" text dense fab small>
            <v-icon class="text--secondary" color="white">settings</v-icon>
          </v-btn>
          <!-- Course Delete Tooltip -->
          <v-tooltip color="secondary" bottom :activator="`#course_update_${ course.id }`">
            <span>Update</span>
          </v-tooltip>

          <!-- Delete Course -->
          <v-btn :id="`course_delete_${ course.id }`" @click="deleteCourse(course.id)" class="ml-3" text dense fab small>
            <v-icon class="text--secondary" color="white">close</v-icon>
          </v-btn>
          <!-- Course Delete Tooltip -->
          <v-tooltip color="secondary" bottom :activator="`#course_delete_${ course.id }`">
            <span>Delete</span>
          </v-tooltip>
        </v-col>

      </v-row>
    </v-card>

    <v-divider class="divider-opacity" />

    <!-- Update Course Modal -->
    <course-dialog :course="course" :course-title="'Update Course'" :display="displayCoursesDialog" @closeDialog="hideCoursesDialog" />

  </v-col></v-row>

</template>

<script>
import coursesMixin from "@/mixins/firestoreMixins/coursesMixin";
import utilityMixin from "@/mixins/utilityMixin";
import courseDialog from "@/components/dialogs/entities/courseDialog";

export default {
  name: "coursesTableRecord",
  mixins: [ coursesMixin, utilityMixin ],
  components: {
    courseDialog
  },
  props: {
    course: Object,
    height: Number
  },
  data: () => ({
    // display data
    displayCoursesDialog: false
  }),
  methods: {
    // methods: show/hide add courses dialog
    showCoursesDialog() { this.displayCoursesDialog = true; },
    hideCoursesDialog() { this.displayCoursesDialog = false; }
  }
}
</script>

<style scoped>

.table-icon-width {
  max-width: 100px !important;
}

</style>