<template>

  <!-- Courses Table Header Container -->
  <v-row no-gutters><v-col>
    <v-card :height="height" color="secondary" class="rounded-0 elevation-0">
      <v-row :height="height" no-gutters align="center" class="fill-inner-parent-ht">

        <!-- Event Type Icon (League, Tournament, Etc...) -->
        <v-col cols="1" class="text-center table-icon-width"></v-col>
        <!-- Event Name -->
        <v-col class="align-center">
          <span class="text-subtitle-2 text--secondary font-weight-bold">Course Name</span>
        </v-col>
        <!-- Course Created Date -->
        <v-col cols="3" class="text-left">
          <span class="text-subtitle-2 text--secondary font-weight-bold">Created</span>
        </v-col>
        <!-- Hole Count -->
        <v-col cols="2" class="text-center">
          <span class="text-subtitle-2 text--secondary font-weight-bold">Holes</span>
        </v-col>
        <!-- Course Par -->
        <v-col cols="2" class="text-center">
          <span class="text-subtitle-2 text--secondary font-weight-bold">Par</span>
        </v-col>
        <!-- Event Actions -->
        <v-col cols="1" class="text-center text--secondary "></v-col>

      </v-row>
    </v-card>
  </v-col></v-row>

</template>

<script>
export default {
  name: "coursesTableHeader",
  props: {
    height: Number
  }
}
</script>

<style scoped>

.table-icon-width {
  max-width: 100px !important;
}

</style>