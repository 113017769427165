<template>
  <!-- Button Transition Format Necessary -->
  <!-- https://github.com/vuetifyjs/vuetify/issues/10578 -->
  <div>
    <v-row no-gutters><v-col>

      <!-- Dashboard Button -->
      <v-btn id="dashboard_button" @click="navigateTo('dashboard')"
             class="pt-3 pb-3 header-icon" :disabled="!userSubscribed"
             :color="$route.name === 'dashboard' ? 'success' : ''" text>
        <v-icon large>dashboard</v-icon>
      </v-btn>
      <v-tooltip bottom color="secondary" activator="#dashboard_button">
        <span>Dashboard</span>
      </v-tooltip>

      <!-- Account Button -->
      <v-btn id="account_button" @click="navigateTo('account')"
             class="ml-3 pt-3 pb-3 header-icon"
             :color="$route.name === 'account' ? 'success' : ''" text>
        <v-icon large>account_circle</v-icon>
      </v-btn>
      <v-tooltip bottom color="secondary" activator="#account_button">
        <span>Account</span>
      </v-tooltip>

    </v-col></v-row>

  </div>
</template>

<script>
import { navigateTo } from "@/routes/navigation";


export default {
  name: "appHeaderMain",
  props: {
    userSubscribed: Boolean
  },
  // data for component
  data: () => ({
    selectionPosition: 0
  }),
  // methods for component
  methods: {
    // method: calls navigate function
    navigateTo(nextRouteName) { navigateTo(nextRouteName); }
  }
}
</script>

<style scoped>

/* button styles */
.header-icon {
  width: 48px !important;
  min-width: 48px !important;
  height: 48px !important;
  min-height: 48px !important;
}

.header-v-btn {
  width: 56px !important;
  min-width: 56px !important;
  height: 56px !important;
  min-height: 56px !important;
}
.header-v-btn-bg {
  background: radial-gradient(150% 140% ellipse at 10% 10%, #2c3134, #101213);
}

.unsubscribed {
  opacity: 0.25 !important;
}

</style>