<template>

  <!-- Header Detail Card -->
  <v-row no-gutters><v-col>
    <v-card :height="height" color="transparent" class="rounded-0 elevation-0">
      <v-row :height="height" no-gutters align="center" class="fill-inner-parent-ht">

        <!-- Player Position -->
        <v-col cols="1" class="align-center text-center player-pos-width">
        </v-col>
        <!-- Player/Team Name -->
        <v-col cols="2" class="pl-1 align-center">
        </v-col>
        <!-- Event Hole Scores -->
        <v-col class="pr-5 align-center text-center header-detail-style">
          <v-row no-gutters>
            <template v-for="(hole, holeId) in eventHoles(event)">
              <!-- Holes -->
              <v-col :key="`${holeId}`" class="text-center">
                <span class="text--secondary text-subtitle-2">{{ hole.par }}</span>
              </v-col>

              <!-- CONDITIONAL RENDERING -->
              <!-- Hole Out -->
              <v-col v-if="hole.hole === 9 && eventHoleCount(event) !== 9" :key="`${holeId}_out`" class="text-center">
                <span class="text--secondary text-subtitle-2 font">{{ courseFrontPar(event.course) }}</span>
              </v-col>
              <!-- Hole In -->
              <v-col v-if="hole.hole === 18 && eventHoleCount(event) !== 9" :key="`${holeId}_in`" class="text-center">
                <span class="text--secondary text-subtitle-2">{{ courseBackPar(event.course) }}</span>
              </v-col>
              <!-- Hole Total -->
              <v-col v-if="hole.hole === 18 || hole.hole === eventHoleCount(event)" :key="`${holeId}_total`" class="text-center">
                <span v-if="event.holes === 'front'" class="text--secondary text-subtitle-2">{{ courseFrontPar(event.course) }}</span>
                <span v-else-if="event.holes === 'back'" class="text--secondary text-subtitle-2">{{ courseBackPar(event.course) }}</span>
                <span v-else class="text--secondary text-subtitle-2">{{ coursePar(event.course) }}</span>
              </v-col>
              <!-- Handicap -->
              <v-col v-if="(hole.hole === 18 || hole.hole === eventHoleCount(event)) && event.handicap" :key="`${holeId}_handicap`" class="text-center"></v-col>
              <!-- Score ToPar -->
              <v-col v-if="hole.hole === 18 || hole.hole === eventHoleCount(event)" :key="`${holeId}_topar`" class="text-center"></v-col>
            </template>
          </v-row>
        </v-col>

      </v-row>
    </v-card>

  </v-col></v-row>

</template>

<script>
import eventsMixin from "@/mixins/firestoreMixins/eventsMixin";
import coursesMixin from "@/mixins/firestoreMixins/coursesMixin";


export default {
  name: "liveScoreHeaderDetail",
  mixins: [eventsMixin, coursesMixin],
  props: {
    // data props
    event: Object,
    // styling props
    height: Number
  }
}
</script>

<style scoped>

.player-pos-width {
  max-width: 70px !important;
}

.header-detail-style{
  opacity: 0.75 !important;
}

</style>