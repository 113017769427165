<template>

  <!-- Tutorial Dialogue -->
  <v-dialog v-model="dialogDisplay" max-width="800" class="elevation-0" persistent>

    <v-card color="secondary" class="pl-5 pt-5 pr-5 pb-10 elevation-0">
      <!-- Cancel icon in Header -->
      <v-row justify="center" align="center" no-gutters><v-col class="text-right">
        <v-btn @click="$emit('close')" fab text small><v-icon>close</v-icon></v-btn>
      </v-col></v-row>

      <!-- Tutorial Header -->
      <v-row justify="center" align="center" no-gutters><v-col class="text-center">
        <span class="text-h4 font-weight-bold">Welcome to ScorePro!</span>
      </v-col></v-row>
      <!-- Tutorial Sub-text -->
      <v-row justify="center" align="center" class="mt-1" no-gutters><v-col class="text-center">
        <span class="text-h6 subtext-style font-weight-medium">Complete the steps below to help you get started</span>
      </v-col></v-row>

      <!-- Tutorial Stepper -->
      <v-row justify="center" align="center" class="pl-10 pr-10" no-gutters><v-col>
        <v-stepper v-model="tutorialStep" class="secondary elevation-0">
          <!-- Stepper Header -->
          <v-stepper-header class="elevation-0">
            <v-stepper-step :complete="newCourseCreated" step="1" color="info" class="text-subtitle-2">Create Course</v-stepper-step>
            <v-divider />
            <v-stepper-step :complete="newCourseCreated && newEventCreated" step="2" color="info" class="text-subtitle-2">Create Event</v-stepper-step>
            <v-divider />
            <v-stepper-step :complete="tutorialStep > 3" step="3" color="info" class="text-subtitle-2">Manage Event</v-stepper-step>
          </v-stepper-header>

          <!-- Stepper Content -->
          <!-- Step 1: Create New Course -->
          <v-stepper-content step="1" class="elevation-0">
            <v-row justify="center" align="center" no-gutters><v-col class="text-center">
              <v-btn color="success" @click="enableDisplayStep1" x-large>
                <span class="secondary--text text--darken-3 text-h6 font-weight-bold">Create Course</span>
                <v-icon class="ml-3 secondary--text text--darken-3 font-weight-bold">east</v-icon>
              </v-btn>
            </v-col></v-row>
            <course-dialog v-if="displayStep1" :display="displayStep1" course-title="Create New Course" @closeDialog="disableDisplayStep1" />
          </v-stepper-content>

          <!-- Step 2: Create New Event -->
          <v-stepper-content step="2" class="elevation-0">
            <v-row justify="center" align="center" no-gutters><v-col class="text-center">
              <v-btn color="success" @click="enableDisplayStep2" x-large>
                <span class="secondary--text text--darken-3 text-h6 font-weight-bold">Create Event</span>
                <v-icon class="ml-3 secondary--text text--darken-3 font-weight-bold">east</v-icon>
              </v-btn>
            </v-col></v-row>
            <event-dialog v-if="displayStep2" :display="displayStep2" event-title="Create New Event" @closeDialog="disableDisplayStep2" />
          </v-stepper-content>

          <!-- Step 3: Complete -->
          <v-stepper-content step="3" class="elevation-0">
            <v-row justify="center" align="center" no-gutters><v-col class="text-center">
              <v-btn color="success" @click="$emit('close')" x-large>
                <span class="secondary--text text--darken-3 text-h6 font-weight-bold">Go to Dashboard</span>
                <v-icon class="ml-3 secondary--text text--darken-3 font-weight-bold">east</v-icon>
              </v-btn>
            </v-col></v-row>
          </v-stepper-content>



        </v-stepper>
      </v-col></v-row>


    </v-card>

  </v-dialog>

</template>

<script>
import CourseDialog from "@/components/dialogs/entities/courseDialog";
import EventDialog from "@/components/dialogs/entities/eventDialog";
import {mapGetters} from "vuex";

export default {
  name: "getStarted",
  components: {EventDialog, CourseDialog},
  props: {
    // display for tutorial dialogue
    display: Boolean
  },
  computed: {
    // store getters
    ...mapGetters({ events: "events", courses: "courses" }),
    // computed: display dialogue
    dialogDisplay: {
      get () { return this.display },
      set () { return this.$emit('close'); }
    },
    // tutorial progress
    tutorialStep () {
      if (this.newEventCreated) { return 3 }
      else if (this.newCourseCreated) { return 2 }
      else { return 1 }
    },
    newCourseCreated () { return this.courses.length > 0 },
    newEventCreated () { return this.events.length > 0 }
  },
  data: () => ({
    // steps display dialogue
    displayStep1: false,
    displayStep2: false,
    displayStep3: false
  }),
  methods: {
    // methods: show/hide step 1 dialog
    enableDisplayStep1() { this.displayStep1 = true; },
    disableDisplayStep1() { this.displayStep1 = false; },
    // methods: show/hide step 2 dialog
    enableDisplayStep2() { this.displayStep2 = true; },
    disableDisplayStep2() { this.displayStep2 = false; },
    // methods: show/hide step 3 dialog
    enableDisplayStep3() { this.displayStep3 = true; },
    disableDisplayStep3() { this.displayStep3 = false; }
  }
}
</script>

<style scoped>

.subtext-style {
  opacity: 0.3 !important;
}

</style>