<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 58 59" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><g xmlns="http://www.w3.org/2000/svg" id="017---Golf-Green" transform="translate(-1 -1)"><path id="Shape" d="m41.66 20.66c.21842-.1918663.3425296-.4692878.34-.76v-14.5c-.0018915-.36685736-.2045104-.70322631-.5279231-.87640861-.3234128-.17318229-.7156897-.1553705-1.0220769.04640861-7.33 4.57-14.66-6.27-22-2.92.3536816.50044707.545621 1.09720415.55 1.71v15.07c7.55-2.75 15.11 8.8 22.66 2.23z" fill="#1F91CD" data-original="#000000" style="" class=""/><path id="Shape" d="m54.72 49.21c-2.52-1.09-9.59-3-9.07-6.41.37-2.43 5-1.62 5.08-4 .05-1.61-2.24-1.92-3.6-2-9.13-1.01-19.13-1.29-28.13.58v7.29c3.75 2 1.93 6.33-3 6.33s-6.73-4.38-3-6.33v-5.67c-4.49 1.5-11.76 4.6-12 9.35-.27 5.51 7.62 7.78 12.38 8.73l.32.07c7.4359231 1.6062877 15.0555886 2.1950036 22.65 1.75 5.9450153-.341655 11.8498903-1.1914217 17.65-2.54 2.26-.65 6.16-1.84 4.26-4.66-.9326539-1.1353506-2.156227-1.9959994-3.54-2.49zm-13.72 4.79c-2.209139 0-4-1.790861-4-4s1.790861-4 4-4 4 1.790861 4 4-1.790861 4-4 4z" fill="#1F91CD" data-original="#000000" style="" class=""/><path id="Shape" d="m15 3.36v43.64c0 .5522847.4477153 1 1 1s1-.4477153 1-1c0-46.89.13-43.87-.24-44.29-.2721225-.31821168-.7136768-.43320365-1.1064462-.28814676-.3927695.1450569-.653586.51944719-.6535538.93814676z" fill="#1F91CD" data-original="#000000" style="" class=""/></g></g></svg>
</template>

<script>
export default {
  name: "courseIconComponent"
}
</script>

<style scoped>

</style>