import Vue from 'vue';
import Vuetify from 'vuetify';

import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import 'font-awesome/css/font-awesome.min.css';
import v1Theme from "@/plugins/vuetify/themes/v1Theme";

// Custom Icons
import scoreproMarkGreenIcon from "@/components/icons/logos/scoreproMarkGreenIcon";
import scoreproLogoTypeIcon from "@/components/icons/logos/scoreproLogoTypeIcon";

import dashboardIconComponent from "@/components/icons/navigation/dashboardIconComponent";
import scoreboardIconComponent from "@/components/icons/navigation/scoreboardIconComponent";
import eventIconComponent from "@/components/icons/navigation/eventIconComponent";
import courseIconComponent from "@/components/icons/navigation/courseIconComponent";
import accountIconComponent from "@/components/icons/navigation/accountIconComponent";

import iconFirstPlace from "@/components/icons/scoreboard/iconFirstPlace";
import iconSecondPlace from "@/components/icons/scoreboard/iconSecondPlace";
import iconThirdPlace from "@/components/icons/scoreboard/iconThirdPlace";

Vue.use(Vuetify)

const vuetifyOpts = {
    theme: {
        dark: true,
        themes: { dark: v1Theme }
    },
    icons: {
        iconfont: 'mdiSvg' || 'fa4', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
        values: {
            scoreproMarkGreenIcon: { component: scoreproMarkGreenIcon },
            scoreproLogoTypeIcon: { component: scoreproLogoTypeIcon },

            dashboardIcon: { component: dashboardIconComponent },
            scoreboardIcon: { component: scoreboardIconComponent },
            eventsIcon: { component: eventIconComponent },
            courseIcon: { component: courseIconComponent },
            accountIcon: { component: accountIconComponent },

            iconFirstPlace: { component: iconFirstPlace },
            iconSecondPlace: { component: iconSecondPlace },
            iconThirdPlace: { component: iconThirdPlace }
        }
    }
}

export default new Vuetify(vuetifyOpts)


/*
Yellow for buttons/live: E0DD28
Green for buttons/live: 52AF41


Blue for form highlight btn/save changes: 1096DE
 */