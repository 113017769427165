export default {
    primary: {

    },
    secondary: {
        lighten5: '#406a82',
        lighten4: '#104c6d',
        lighten3: '#0e415d',
        lighten2: '#0c3951',
        lighten1: '#0b3247',
        base: '#0A2C3E',
        darken1: '#062536',
        darken2: '#021D2B',
        darken3: '#021521'
    },

    // All keys will generate theme styles,
    // Here we add a custom `tertiary` color

    error: '#CF2132',
    info: '#1096DE',
    success: '#52AF41',
    warning: '#E0DD28',
}