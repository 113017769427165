<template>

  <svg id="Capa_1" enable-background="new 0 0 510 510" height="512" viewBox="0 0 510 510" width="512" xmlns="http://www.w3.org/2000/svg"><g><g id="XMLID_1643_"><path id="XMLID_1665_" d="m45 75.3v308.5c5.9 3.6 204.1 122.4 210 126l15-217.2-15-217.3-105-15.3z" fill="#ff7c48"/><path id="XMLID_1663_" d="m465 75.3-105-15.3-105 15.3v434.5c5.9-3.6 204.1-122.4 210-126 0-17.2 0-291.4 0-308.5z" fill="#ff415b"/><path id="XMLID_1662_" d="m124.4 289.6c0 72 58.6 130.6 130.6 130.6l15-130.6-15-130.6c-72 0-130.6 58.6-130.6 130.6z" fill="#f1f1f1"/><path id="XMLID_1649_" d="m385.6 289.6c0-72-58.6-130.6-130.6-130.6v261.2c72 .1 130.6-58.5 130.6-130.6z" fill="#d6d6ea"/><path id="XMLID_1647_" d="m240 225.3v30h15l7.5-15-7.5-15c-5.2 0-9.8 0-15 0z" fill="#555a67"/><path id="XMLID_1_" d="m255 225.3v30h15c0-10.5 0-19.5 0-30-5.2 0-9.8 0-15 0z" fill="#414952"/><path id="XMLID_1650_" d="m135 180.3c-10.5 0-19.5 0-30 0 0-28.3 0-88.5 0-105l15-15.3 15 15.3z" fill="#ff415b"/><path id="XMLID_1599_" d="m375 180.3h30c0-28.3 0-88.5 0-105l-15-15.3-15 15.3z" fill="#e50058"/><path id="XMLID_1667_" d="m0 .3v75h255l15-37.5-15-37.5c-7.2 0-247.8 0-255 0z" fill="#555a67"/><path id="XMLID_1666_" d="m510 .3c-7.2 0-247.8 0-255 0v75h255c0-20.2 0-63.3 0-75z" fill="#414952"/></g><path d="m285 285.3h30v30h-30z" fill="#414952"/><path d="m195 285.1h30v30h-30z" fill="#555a67"/></g></svg>
</template>

<script>
export default {
  name: "eventsIconComponent"
}
</script>

<style scoped>

</style>