<template>
  <!-- Home/Landing Page Container -->
  <v-row justify="center" align="center" class="fill-parent-ht pt-5" no-gutters><v-col>

    <!-- Error Code -->
    <v-row justify="center" align="center" no-gutters>
        <v-icon color="info" class="subtext-style lock-icon">lock</v-icon>
        <span class="ml-5 mr-5 pt-3 text-h2 info--text subtext-style font-weight-bold">403</span>
        <v-icon color="info" class="subtext-style lock-icon">lock</v-icon>
    </v-row>

    <!-- Heading Catch-line -->
    <v-row justify="center" align="center" class="mt-5" no-gutters><v-col class="text-center">
      <span class="text-h2 font-weight-bold">We are Sorry</span>
    </v-col></v-row>
    <!-- Heading Catch-line Sub-text -->
    <v-row justify="center" align="center" class="mt-3" no-gutters><v-col class="text-center">
      <span class="text-h6 subtext-style font-weight-medium">The page you're trying to access is restricted.</span>
    </v-col></v-row>
    <v-row justify="center" align="center" no-gutters><v-col class="text-center">
      <span class="text-h6 subtext-style font-weight-medium">Please contact support if you feel you've reached this in error.</span>
    </v-col></v-row>

    <!-- Go Back Home Button -->
    <v-row justify="center" align="center" class="mt-10" no-gutters><v-col class="text-center">
      <v-btn width="300" color="info" @click="user.loggedIn ? navigateTo('account') : navigateTo('home')" x-large>
        <span class="text-h6 font-weight-bold">Go Back</span>
      </v-btn>
    </v-col></v-row>

  </v-col></v-row>
</template>

<script>
import {mapGetters} from "vuex";
import {navigateTo} from "@/routes/navigation";

export default {
  name: "forbiddenPage",
  computed: {
    // computed: store/state getters
    ...mapGetters({user: "user"})
  },
  methods: {
    // method: calls navigate function
    navigateTo(nextRouteName) { navigateTo(nextRouteName); }
  }
}
</script>

<style scoped>

.subtext-style {
  opacity: 0.3 !important;
}
.lock-icon {
  font-size: 60px !important;
}

</style>