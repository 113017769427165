<template>

  <!-- Modify/Create Course Dialogue -->
  <v-dialog v-model="display" max-width="800" class="elevation-0" persistent>

    <v-card color="secondary lighten-1">
      <!-- Add Course Heading/Title -->
      <v-card-title class="text-h6">{{ courseTitle }}</v-card-title>
      <v-card-subtitle class="pt-1">Complete fields below and save</v-card-subtitle>

      <!-- Course detail input -->
      <v-divider />
      <v-card-text>
        <v-form ref="frontCourseForm" v-model="frontInputValid" method="post">
          <!-- Input: Course Name -->
          <v-row class="mt-3" no-gutters><v-col>
            <v-text-field v-model="courseName" label="Course Name" :rules="[courseNameExists]"
                          filled outlined dense clearable required hide-details />
          </v-col></v-row>

          <!-- Input: Hole Count -->
          <v-row justify="center" align="center" class="mt-3" no-gutters><v-col>
            <v-btn-toggle v-model="holesEighteen" group mandatory dense block>
              <v-btn width="150px" class="ml-0 mr-0 disable-btn-events" text outlined>Holes</v-btn>
              <v-btn :value="false" width="300px" class="ml-0 mr-0" text outlined>9 Holes</v-btn>
              <v-btn :value="true" width="300px" class="ml-0 mr-0" text outlined>18 Holes</v-btn>
            </v-btn-toggle>
          </v-col></v-row>

          <!-- Front-Nine Hole Input -->
          <v-row class="mt-2" no-gutters><v-col cols="12">
            <!-- Handicaps -->
            <v-row class="input-height mt-1" no-gutters>
              <!-- Handicap Title -->
              <v-col cols="3">
                <v-text-field label="Handicap" class="no-border-radius" filled outlined dense required disabled />
              </v-col>
              <!-- Handicap Inputs -->
              <v-col cols="1" v-for="hole in [1, 2, 3, 4, 5, 6, 7, 8, 9]" :key="'hole_'+hole">
                <v-text-field v-model="courseFrontHoles['hole_0'+hole]['handicap']" :label="hole.toString()" :rules="[handicapExists, handicapValid, handicapInRange]"
                              typ="number" class="no-border-radius" filled outlined dense hide-details required />
              </v-col>
            </v-row>
            <!-- Pars -->
            <v-row class="input-height" no-gutters>
              <!-- Pars Title -->
              <v-col cols="3">
                <v-text-field label="Par" class="no-border-radius" filled outlined dense required disabled />
              </v-col>
              <!-- Par Inputs -->
              <v-col cols="1" v-for="hole in [1, 2, 3, 4, 5, 6, 7, 8, 9]" :key="'hole_'+hole">
                <v-text-field v-model="courseFrontHoles['hole_0'+hole]['par']" :label="hole.toString()" :rules="[parExists, parInRange]"
                              typ="number" class="no-border-radius" filled outlined dense hide-details required />
              </v-col>
            </v-row>
          </v-col></v-row>
        </v-form>
        <v-form ref="backCourseForm" v-model="backInputValid" method="post">
          <!-- Back-Nine Hole Input -->
          <v-row :class="holesEighteen ? '' : 'eighteen-hole-styling'" class="mt-2" no-gutters><v-col cols="12">
            <!-- Handicaps -->
            <v-row class="input-height mt-1" no-gutters>
              <!-- Handicap Title -->
              <v-col cols="3">
                <v-text-field label="Handicap" class="no-border-radius" filled outlined dense required disabled />
              </v-col>
              <!-- Handicap Inputs -->
              <v-col cols="1" v-for="hole in [10, 11, 12, 13, 14, 15, 16, 17, 18]" :key="'hole_'+hole">
                <v-text-field v-model="courseBackHoles['hole_'+hole]['handicap']" :label="hole.toString()" :rules="[handicapExists, handicapValid, handicapInRange]"
                              typ="number" class="no-border-radius" :disabled="!holesEighteen" filled outlined dense hide-details required />
              </v-col>
            </v-row>
            <!-- Pars -->
            <v-row class="input-height" no-gutters>
              <!-- Pars Title -->
              <v-col cols="3">
                <v-text-field label="Par" class="no-border-radius" filled outlined dense required disabled />
              </v-col>
              <!-- Par Inputs -->
              <v-col cols="1" v-for="hole in [10, 11, 12, 13, 14, 15, 16, 17, 18]" :key="'hole_'+hole">
                <v-text-field v-model="courseBackHoles['hole_'+hole]['par']" :label="hole.toString()" :rules="[parExists, parInRange]"
                              typ="number" class="no-border-radius" :disabled="!holesEighteen" filled outlined dense hide-details required />
              </v-col>
            </v-row>
          </v-col></v-row>
        </v-form>
      </v-card-text>

      <!-- Add Course Actions -->
      <v-divider />
      <v-card-actions>
        <v-row class="pa-2 text-right" no-gutters><v-col cols="12">
          <!-- Cancel Course Creation -->
          <v-btn small outlined @click="closeDialog" class="white--text">Cancel</v-btn>
          <!-- Create New Course -->
          <v-btn small outlined class="success--text ml-3" @click="courseUpsert"
                 :disabled="holesEighteen ? !(frontInputValid && backInputValid) : !frontInputValid">Save</v-btn>
        </v-col></v-row>
      </v-card-actions>
    </v-card>

    <!-- success/error dialogs -->
    <success-dialog :display="successDialogDisplay" :message="successDialogMessage" @close="successDialogDisplay=false" />
    <error-dialog :display="errorDialogDisplay" :message="errorDialogMessage" @close="errorDialogDisplay=false" />

  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { courseCollection } from "@/plugins/firebase/firestore";
import dialogMixin from "@/mixins/componentMixins/dialogMixin";

export default {
  name: "coursesDialog",
  mixins: [dialogMixin],
  props: {
    // prop: display this dialog
    display: Boolean,
    // optional: additional course props for update
    course: Object,
    courseTitle: String
    // optional: dialog nested in
  },
  computed: {
    // computed: store/state getters
    ...mapGetters({ user: "user" }),
  },
  data: () => ({
    // course form inputs
    courseName: null,
    courseFrontHoles: {
      'hole_01': {'par': null, 'handicap': null, 'hole': 1},
      'hole_02': {'par': null, 'handicap': null, 'hole': 2},
      'hole_03': {'par': null, 'handicap': null, 'hole': 3},
      'hole_04': {'par': null, 'handicap': null, 'hole': 4},
      'hole_05': {'par': null, 'handicap': null, 'hole': 5},
      'hole_06': {'par': null, 'handicap': null, 'hole': 6},
      'hole_07': {'par': null, 'handicap': null, 'hole': 7},
      'hole_08': {'par': null, 'handicap': null, 'hole': 8},
      'hole_09': {'par': null, 'handicap': null, 'hole': 9}
    },
    courseBackHoles: {
      'hole_10': {'par': null, 'handicap': null, 'hole': 10},
      'hole_11': {'par': null, 'handicap': null, 'hole': 11},
      'hole_12': {'par': null, 'handicap': null, 'hole': 12},
      'hole_13': {'par': null, 'handicap': null, 'hole': 13},
      'hole_14': {'par': null, 'handicap': null, 'hole': 14},
      'hole_15': {'par': null, 'handicap': null, 'hole': 15},
      'hole_16': {'par': null, 'handicap': null, 'hole': 16},
      'hole_17': {'par': null, 'handicap': null, 'hole': 17},
      'hole_18': {'par': null, 'handicap': null, 'hole': 18},
    },
    holesEighteen: false,
    // form validation
    frontInputValid: false,
    backInputValid: false,
    parValidValues: ['3', '4', '5'],
    handicapValidValues: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18']
  }),
  methods: {
    // COMPUTED METHODS
    // method: get all existing handicaps
    existingHandicaps () {
      // handicap container
      let handicaps = [];
      // if eighteen, combine front and back handicaps, else only front
      let combinedHoles = this.holesEighteen ? Object.values({ ...this.courseFrontHoles, ...this.courseBackHoles }) : Object.values(this.courseFrontHoles);
      // iterate and put handicaps in container
      for (let index in combinedHoles) { handicaps.push(combinedHoles[index]['handicap']) }
      // filter nulls from array and return
      return handicaps.filter(Boolean);
    },

    // VALIDATION METHODS
    // method: does course name exist
    courseNameExists (value) { return !!value },
    // method: does handicap value exist
    handicapExists (value) { return !!value },
    // method: is entered handicap unique for course holes
    handicapValid (value) { return this.existingHandicaps().filter(item => item === value).length <= 1 },
    // method: handicap value in range
    handicapInRange (value) { return this.handicapValidValues.includes(value) },
    // method: does par value exist
    parExists (value) { return !!value },
    // method: par value in range
    parInRange (value) { return this.parValidValues.includes(value) },

    // ACTION METHODS
    // method: execute create OR update
    courseUpsert () {
      // if course id has been passed, update existing course
      if (this.course && this.course.id) { this.updateCourse() }
      // else, create new course
      else { this.createCourse() }
    },
    // method: UPDATE existing course
    updateCourse () {
      console.log(this.course)
      // create deep copy of holes data, merge if 18-holes selected
      let holes = JSON.parse(JSON.stringify(this.holesEighteen ? { ...this.courseFrontHoles, ...this.courseBackHoles } : this.courseFrontHoles));
      // convert pars and handicaps to integers
      for (let hole of Object.keys(holes)) { holes[hole]['par'] = parseInt(holes[hole]['par'], 10) }
      for (let hole of Object.keys(holes)) { holes[hole]['handicap'] = parseInt(holes[hole]['handicap'], 10) }

      // build course payload to create
      let coursePayload = {
        'name': this.courseName,
        'holes': holes,
        'creator': this.user.uid
      }
      // update/replace existing course content
      courseCollection.doc(this.course.id).update(coursePayload)
          .then(() => {
            // success, display message
            this.alertSuccess('Course successfully updated!')
            // close course dialog
            this.closeDialog();
            // reset course forms
            this.$refs.frontCourseForm.reset();
            this.$refs.backCourseForm.reset();
          })
          .catch(error => {
            // error occurred, display message
            this.alertError(`An error occurred updating ${this.courseName}, review your input and try again.`)
            throw error;
          });
    },
    // method: CREATE new course
    createCourse () {
      // create deep copy of holes data, merge if 18-holes selected
      let holes = JSON.parse(JSON.stringify(this.holesEighteen ? { ...this.courseFrontHoles, ...this.courseBackHoles } : this.courseFrontHoles));
      // convert pars and handicaps to integers
      for (let hole of Object.keys(holes)) { holes[hole]['par'] = parseInt(holes[hole]['par'], 10) }
      for (let hole of Object.keys(holes)) { holes[hole]['handicap'] = parseInt(holes[hole]['handicap'], 10) }

      // build course payload to create
      let coursePayload = {
        'name': this.courseName,
        'holes': holes,
        'creator': this.user.uid,
        'created': new Date()
      }
      // write payload to course collection
      courseCollection.add(coursePayload)
          .then(() => {
            // success, display message
            this.alertSuccess('New course successfully created!')
            // close dialog
            this.closeDialog();
            // reset course forms
            this.$refs.frontCourseForm.reset();
            this.$refs.backCourseForm.reset();
          })
          .catch(error => {
            // error occurred, display message
            this.alertError('An error occurred creating your new course, review your input and try again.')
            throw error;
          });
    },

    // UTILITY METHODS
    // method: pre-populate course data from passed course prop (if has content)
    populateCourseData () {
      // check if course was passed
      if (this.course) {
        // set course name
        this.courseName = this.course.name;
        // set eighteen holes selected
        this.holesEighteen = Object.keys(this.course.holes).length > 9;
        // convert course pars/handicaps to string
        let stringCourseHoles = JSON.parse(JSON.stringify(this.course.holes));
        Object.keys(stringCourseHoles).forEach(key => stringCourseHoles[key]['par'] = stringCourseHoles[key]['par'].toString())
        Object.keys(stringCourseHoles).forEach(key => stringCourseHoles[key]['handicap'] = stringCourseHoles[key]['handicap'].toString())
        // populate front 9
        Object.keys(this.courseFrontHoles).forEach(key => this.courseFrontHoles[key] = stringCourseHoles[key]);
        // check if 18 and populate back 9
        if (this.holesEighteen) {
          Object.keys(this.courseBackHoles).forEach(key => this.courseBackHoles[key] = stringCourseHoles[key]);
        }
      }
    },
    // method: close dialog
    closeDialog() { this.$emit('closeDialog'); }
  },
  mounted() {
    // mounted: on mount, populate data if course was passed and pre-populate data
    this.populateCourseData()
  },
  watch: {
    // watcher: check if 'display' is visible and run populateCourseData if so
    display () { this.display ? this.populateCourseData() : {} }
  }
}

</script>

<style scoped>
.input-height {
  height: 39px !important;
}
.eighteen-hole-styling {
  opacity: 0.25 !important;
}
.no-border-radius {
  border-radius: 0px !important;
}

/* Course Options Styling */
.disable-btn-events {
  pointer-events: none !important;
}

.v-btn-toggle--group>.v-btn.v-btn:first-child {
  background-color: #062536 !important;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.v-btn-toggle--group>.v-btn.v-btn:last-child {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}

</style>