<template>

  <!-- Application Bar -->
  <v-app-bar app flat :height="72" color="secondary darken-2">
    <v-row justify="center" align="center" no-gutters>
      <!-- Logo Column -->
      <v-col xl="5" lg="4" md="5" class="text-left">
        <!-- Logo -->
        <svg class="logo-mark-style logo-style" @click="userSubscribed ? navigateTo('dashboard') : ''">
          <scorepro-mark-white-icon />
        </svg>
        <!-- Application Name -->
        <svg class="logo-type-style logo-style" @click="userSubscribed ? navigateTo('dashboard') : ''">
          <scorepro-logo-type-alt-icon />
        </svg>
      </v-col>
      <!-- Navigation Icons Column -->
      <v-col xl="2" lg="4" md="2" class="text-center">
        <app-header-main v-if="$route.name !== 'login'"
                         :user-subscribed="userSubscribed"
                         class="header-main-item" />
      </v-col>
      <!-- Helper Column -->
      <v-col xl="5" lg="4" md="5" class="text-right">
        <app-header-helper :user-subscribed="userSubscribed"
                           key="app-helper" class="header-helper-item" />
      </v-col>
    </v-row>
  </v-app-bar>

</template>

<script>
import appHeaderMain from "@/components/layout/headers/components/appHeaderMain";
import appHeaderHelper from "@/components/layout/headers/components/appHeaderHelper";
import scoreproMarkWhiteIcon from "@/components/icons/logos/scoreproMarkWhiteIcon";
import scoreproLogoTypeAltIcon from "@/components/icons/logos/scoreproLogoTypeAltIcon";
import { navigateTo } from "@/routes/navigation";

export default {
  name: "appHeader",
  components: {
    appHeaderMain,
    appHeaderHelper,
    scoreproMarkWhiteIcon,
    scoreproLogoTypeAltIcon
  },
  props: {
    userSubscribed: Boolean
  },
  methods: {
    // method: calls navigate function
    navigateTo(nextRouteName) { navigateTo(nextRouteName); },

    // HACK: element transitions
    // TODO: find a way to fix this in vuetify
    // https://forum.vuejs.org/t/transition-group-leave-transition-w-position-absolute-jumping-to-top-left-flip/12258/3
    beforeLeave(el) {
      const {marginLeft, marginTop, width, height} = window.getComputedStyle(el)
      el.style.left = `${el.offsetLeft - parseFloat(marginLeft, 10)}px`
      el.style.top = `${el.offsetTop - parseFloat(marginTop, 10)}px`
      el.style.width = width
      el.style.height = height
    }
  }
}
</script>

<style scoped>

/* application name */
.logo-type-style {
  height: 16px !important;
  width: 180px !important;
}
.logo-mark-style {
  height: 24px !important;
  width: 24px !important;
  top: 20px !important;
  position: absolute;
}

/* logo style */
.logo-style {
  cursor: pointer !important;
}

</style>