<template>

  <!-- Event Actions Container -->
  <v-row no-gutters><v-col>
    <!-- Actions Expansion Panel -->
    <v-expansion-panels v-model="actionsExpansion" class="rounded-0 elevation-0" flat tile>
      <v-expansion-panel key="utilities" class="pa-0 rounded-0 secondary darken-1">
        <!-- Actions Header -->
        <v-expansion-panel-header>
          <!-- Icon and Header -->
          <v-row align="center" no-gutters><v-col>
            <v-icon class="text--secondary" small>settings</v-icon>
            <span class="ml-3 text--secondary caption font-weight-bold">ACTIONS</span>
          </v-col></v-row>
          <!-- Dropdown Icon Template -->
          <template v-slot:actions>
            <v-icon class="text--secondary">expand_more</v-icon>
          </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-divider class="divider-opacity" />
          <!-- Action Panel -->
          <v-card :height="contentHeight" color="secondary darken-2" class="pa-5 rounded-0 elevation-0">
            <v-row :height="contentHeight" align="center" class="fill-inner-parent-ht text-right" no-gutters>
              <v-col class="text-left">
                <!-- Update Event Button -->
                <v-btn @click="showEventDialog" class="text--secondary" text outlined>
                  <v-icon small>edit</v-icon>
                  <span class="ml-3 caption font-weight-bold">Update Event</span>
                </v-btn>
                <!-- View Live Scoreboard -->
                <v-btn @click="navigateToLiveScoreboard"  class="ml-3 warning--text" outlined text>
                  <v-icon small>emoji_events</v-icon>
                  <span class="ml-3 caption font-weight-bold">View Live</span>
                </v-btn>
              </v-col>

              <v-col class="text-right">
                <!-- Save Players -->
                <v-btn @click="$emit('saveChanges')" class="info--text" outlined text>
                  <v-icon small>task_alt</v-icon>
                  <span class="ml-3 caption font-weight-bold">Save Changes</span>
                </v-btn>
                <!-- Add Player -->
                <v-btn @click="createPlayer(event.id)" class="ml-3 success--text" outlined text>
                  <v-icon small>fa-plus</v-icon>
                  <span class="ml-3 caption font-weight-bold">Add Player</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-divider class="divider-opacity" />
        </v-expansion-panel-content>

      </v-expansion-panel>
    </v-expansion-panels>

    <!-- Add Event Modal -->
    <event-dialog :key="displayKey" :event-title="`Change Event - ${event.name}`" :event="event" :display="displayEventDialog" @closeDialog="hideEventDialog" />

    <!-- Confirmation Save Dialog -->
    <confirm-dialog ref="confirmSave" />

  </v-col></v-row>

</template>

<script>
import eventDialog from "@/components/dialogs/entities/eventDialog";
import confirmDialog from "@/components/dialogs/feedback/confirmDialog";
import playersMixin from "@/mixins/firestoreMixins/playersMixin";
import eventsMixin from "@/mixins/firestoreMixins/eventsMixin";


export default {
  name: "eventActions",
  mixins: [playersMixin, eventsMixin],
  components: {
    eventDialog,
    confirmDialog
  },
  props: {
    // data-related props
    event: Object,
    // styling props
    contentHeight: Number
  },
  data: () => ({
    // styling data
    actionsExpansion: 0,
    // display data
    displayKey: 1,
    displayEventDialog: false,
    displaySaveDialog: false
  }),
  methods: {
    // UTILITY METHODS
    // navigate to view live scoreboard
    async navigateToLiveScoreboard () {
      // launch confirmation dialog for saving of changes (before navigation away)
      if (await this.$refs.confirmSave.open('Save changes before exiting?')) {
        // emit save changes to save records
        this.$emit('saveChanges', true);
      } else {
        this.viewEventLive(this.event.id);
      }
    },
    // methods: show/hide add events dialog
    showEventDialog() {
      this.displayKey += 1;
      this.displayEventDialog = true;
    },
    hideEventDialog() { this.displayEventDialog = false; }
  }
}
</script>

<style scoped>

/* remove expansion panel padding */
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

</style>