import {v4 as uuidv4} from "uuid";
import _ from "lodash";
import Fuse from "fuse.js";
import { eventCollection } from "@/plugins/firebase/firestore";
import { navigateWithParams } from "@/routes/navigation";
import { mapGetters } from "vuex";

/*************************************************************
 Mixin for Events in Firestore
**************************************************************/

var eventsMixin = {

    computed: {
        // computed: store/state getters
        ...mapGetters({ eventViewer : "eventViewer" }),
    },

    data: () => ({
        // hole keys for calculations
        frontHoles: ['hole_01', 'hole_02', 'hole_03', 'hole_04', 'hole_05', 'hole_06', 'hole_07', 'hole_08', 'hole_09'],
        backHoles: ['hole_10', 'hole_11', 'hole_12', 'hole_13', 'hole_14', 'hole_15', 'hole_16', 'hole_17', 'hole_18']
    }),

    methods: {
        /*****************************************************************
         *  Event: Methods relating to an individual Event
         ****************************************************************/
        // computed: get event flight cuts => [0, 8, 16, etc...]
        eventFlights (event) { return _.range(0, this.eventPlayerCount(event), Math.floor(this.eventPlayerCount(event) / event.flights)).slice(0, event.flights); },
        // computed: get playoff hole key
        eventPlayoffHoleKey (event) { return `hole_${event.playoff.toLocaleString('en-US', {'minimumIntegerDigits': 2})}`},
        // (PRIVATE) computed: order playoff hole keys in order of comparison (ie. hole_13, hole_14, ..., hole_11, hole_12)
        $_eventPlayoffHoleKeyOrder (event) {
            // retrieve playoff holes index and event hole keys
            let eventHoleKeys = this.$_eventHoleKeys(event);
            let playoffIndex = eventHoleKeys.findIndex((element) => element === this.eventPlayoffHoleKey(event));
            // move every hole before playoff hole to end of array
            return _.concat(eventHoleKeys.slice(playoffIndex), eventHoleKeys.slice(0, playoffIndex))
        },

        /*****************************************************************
         *  Event: Methods relating to an individual Event's Course
         ****************************************************************/
        // computed: calculate how many holes for event
        eventHoleCount (event) { return event.holes === 'full' ? 18 : 9; },
        // computed: get event holes
        eventHoles (event) { return _.pick(event.course.holes, this.$_eventHoleKeys(event)) },
        // (PRIVATE) computed: get event hole properties
        $_eventHoleKeys (event) {
            if (event.holes === 'front') { return this.frontHoles }
            else if (event.holes === 'back') { return this.backHoles }
            else { return this.frontHoles.concat(this.backHoles) }
        },

        /*****************************************************************
         *  Event: Methods relating to an individual Event's Players
         ****************************************************************/
        // computed: calculate how many players in event
        eventPlayerCount (event) { return event.players ? Object.keys(this.event.players).length : '-'; },
        // computed: calculate appropriate handicap to factor into score
        eventPlayerHandicap (event, player) { return (event.handicap && player.handicap ? Math.round(player.handicap * (this.eventHoleCount(event)/18)) : 0); },
        // computed: calculate player's score for front-9
        eventPlayerScoreOut (playerScorecard) { return _.sum(_.values(_.pick(this.$_enforceScorecardIntegrity(playerScorecard), this.frontHoles))); },
        // computed: calculate player's score for back-9
        eventPlayerScoreIn (playerScorecard) { return _.sum(_.values(_.pick(this.$_enforceScorecardIntegrity(playerScorecard), this.backHoles))); },
        // computed: calculate player's total score for event (determined by event's hole selections)
        eventPlayerScoreTotal (event, playerScorecard) { return _.sum(_.values(_.pick(this.$_enforceScorecardIntegrity(playerScorecard), this.$_eventHoleKeys(event)))); },
        // computed: calculate event player's score-to-par value
        eventPlayerScoreToPar (event, player) {
            // par container for player
            let scoreToPar = 0, holes = this.eventHoles(event);
            // loop through event holes and calculate toPar for player scorecard
            Object.keys(holes).forEach((hole) => {
                scoreToPar += (player.scorecard[hole] || player.scorecard[hole] === 0 ? (player.scorecard[hole] - holes[hole].par) : 0)
            });
            // subtract handicap if applicable and return
            return (event.handicap ? scoreToPar - this.eventPlayerHandicap(event, player) : scoreToPar);
        },
        // computed: find players with skins in event
        eventSkinsWinners (event) {
            // convert players object to array
            let eventPlayers = this.$_convertEventPlayersToArray(event);
            // retain only players with skins_enabled
            let eventSkinsPlayers = eventPlayers.filter((player) => { return player.skins_enabled === true });

            // get scores for every hole
            let eventSkinWinners = {};
            this.$_eventHoleKeys(event).forEach((holeId) => {
                // build array from scores for a hole, remove undefined, and everything except minimum/lowest scores
                let holeScoresArray = _.map(eventSkinsPlayers, `scorecard.${holeId}`);
                let minHoleScoresArray = holeScoresArray.filter((score) => { return (score && true && score === _.min(holeScoresArray)); });
                // check if low score is the only one (skin winning score)
                if (minHoleScoresArray.length === 1) {
                    // find skin winner for hole and add to playerId+holeId skin winners
                    let skinWinner = _.find(eventSkinsPlayers, ((player) => { return player.scorecard[holeId] === minHoleScoresArray[0] }));
                    (eventSkinWinners[skinWinner.id] = eventSkinWinners[skinWinner.id] || []).push(holeId);
                }
            });
            return eventSkinWinners;
        },
        // computed: find player's position in flight, if there is one
        eventPlayerFlightPosition (event, playerPos) {
            return playerPos - this.eventFlights(event).reduce((a, b) => b <= playerPos && a < b ? b : a, 0 );
        },
        // (PRIVATE) computed: enforce integer scores for player scorecard
        $_enforceScorecardIntegrity (playerScorecard) { return _.pickBy(playerScorecard, _.isInteger); },

        /*****************************************************************
         *  Event: Methods relating to an individual Event's Players SORTING
         ****************************************************************/
        // computed: sort event players
        eventPlayersSorted (event) {
            // convert event players object into array
            let eventPlayers = event.players ? this.$_convertEventPlayersToArray(event) : [];
            // sort players by time created
            return eventPlayers ? this.$_sortEventPlayersByCreated(eventPlayers) : [];
        },
        // computed: main sort algorithm for live-event scoreboard
        eventLivePlayersSorted (event) {
            // convert event players object into array
            let eventPlayers = this.$_convertEventPlayersToArray(event);
            // players with score and no score
            let scorePlayers = this.$_eventPlayersWithScore(event, eventPlayers);
            let noScorePlayers = this.$_eventPlayersWithoutScore(event, eventPlayers);
            // add scoreToPar to each player record
            scorePlayers = this.$_eventPlayersAddScoreToPar(event, scorePlayers)
            // perform sorting of players with score
            let sortedScorePlayers = this.$_sortEventPlayersByAlgorithm(event, scorePlayers);
            // perform sorting of players with no score
            let sortedNoScorePlayers = this.$_sortEventPlayersByCreated(noScorePlayers);
            // concat arrays of sorted players and players without scores
            return _.concat(sortedScorePlayers, sortedNoScorePlayers)
        },
        // (PRIVATE) method: sort players algorithm defined below, multiple parameters
        $_sortEventPlayersByAlgorithm (event, players) {
            return players.sort((p1, p2) =>
                p1.scoreToPar - p2.scoreToPar ||                                                     // (1) Sort by ascending score to par (ie. -2, -1, E, +1, etc....)
                this.$_countFinishedHoles(event, p2) - this.$_countFinishedHoles(event, p1) ||       // (2) Sort by descending holes completed (ie. 18, 17, 16, etc...)
                this.$_playoffTieBreaker(event, p1, p2)                                              // (3) Sort by playoff tie-breaker holes TODO: ADD IDENTICAL SCORE TIE-BREAKER
            );
        },
        // (PRIVATE) method: calculate tie breaker based on playoff holes
        $_playoffTieBreaker (event, p1, p2) {
            // loop through ordered playoff hole keys
            for (let holeKey of this.$_eventPlayoffHoleKeyOrder(event)) {
                if (p1.scorecard[holeKey] !== p2.scorecard[holeKey]) { return p1.scorecard[holeKey] - p2.scorecard[holeKey] }
            }
            return 0
        },
        // (PRIVATE) method: convert event players object into array
        $_convertEventPlayersToArray (event) {
            let tempPlayers = [];
            for (const [playerId, playerValue] of Object.entries(event.players)) {
                playerValue['id'] = playerId;
                tempPlayers.push(playerValue);
            }
            return tempPlayers;
        },
        // (PRIVATE) method: count finished holes in player scorecard
        $_countFinishedHoles (event, player) {
            let finishedHoles = [], holes = this.eventHoles(event);
            Object.keys(holes).forEach((hole) => { if (player.scorecard[hole]) { finishedHoles.push(hole) } });
            return finishedHoles.length;
        },
        // (PRIVATE) method: sort players by time created
        $_sortEventPlayersByCreated (players) { return players.sort((p1, p2) =>
            (p2.created.seconds + p2.created.nanoseconds/1000000000) - (p1.created.seconds + p1.created.nanoseconds/1000000000)
        );},
        // (PRIVATE) computed: calculate players score-to-par
        // adds scoreToPar property to players
        $_eventPlayersAddScoreToPar (event, players) {
            let tempPlayers = players;
            tempPlayers.forEach((player) => { player.scoreToPar = this.eventPlayerScoreToPar(event, player) });
            return tempPlayers
        },
        // (PRIVATE) computed: retrieve players who have a score
        $_eventPlayersWithScore (event, players) {
            let tempPlayers = [];
            players.forEach((player) => {
                let score = _.sum(_.values(_.pick(player.scorecard, Object.keys(this.eventHoles(event)))));
                if (score > 0) { tempPlayers.push(player); }
            });
            return tempPlayers
        },
        // (PRIVATE) computed: retrieve players who have no score
        $_eventPlayersWithoutScore (event, players) {
            let tempPlayers = [];
            players.forEach((player) => {
                let score = _.sum(_.values(_.pick(player.scorecard, Object.keys(this.eventHoles(event)))));
                if (score === 0) { tempPlayers.push(player); }
            });
            return tempPlayers
        },

        /*****************************************************************
         *  Events: Methods relating to multiple Events
         ****************************************************************/
        // computed: sort and filter events
        eventsSortedAndFiltered (events, searchBy, sortField, sortBy) {
            // if search criteria supplied
            if (searchBy) {
                // to search by players, we need a list of player keys from events
                let playerIds = [];
                events.forEach((event) => { playerIds.push(...Object.keys(event.players)) });
                // build player search
                let playerSearch = [];
                //playerIds.forEach((playerId) => {playerSearch.push(`players.${playerId}.name`)})
                // define search options
                let searchOptions = { keys: ['name'].concat(playerSearch) }
                // create fuse instance
                const fuse = new Fuse(events, searchOptions)
                // query to get results
                let filteredEvents = fuse.search(searchBy).map(result => result.item)
                return _.orderBy(filteredEvents, [sortField], [sortBy])
            } else {
                // no search criteria, return sorted events
                return _.orderBy(events, [sortField], [sortBy])
            }
        },

        /*****************************************************************
         *  Event: Methods relating to CRUD-OPERATIONS for Event
         ****************************************************************/
        // method: create new event
        createEvent (eventPayload) {
            // perform event payload validation
            this.$_validateEventPayload(eventPayload);
        },
        // method: delete existing event
        deleteEvent (eventId) { eventCollection.doc(eventId).delete(); },

        // DATA VALIDATION METHODS
        // (PRIVATE) method: validate event payload
        $_validateEventPayload (eventPayload) {
            console.log(eventPayload);
        },

        /*****************************************************************
         *  Event: Methods relating to VIEWER-OPERATIONS for Event
         ****************************************************************/
        // method: get live event with viewer id
        getEvent (viewerId) { return this.eventViewer(viewerId) },
        // method: add event viewer, if not already exist
        attemptCreateEventViewer(eventId, viewerId) {
            let viewerParams = { eventId: eventId, viewerId: viewerId }
            this.$store.dispatch('commitCreateEventViewer', viewerParams);
        },
        // method: destroy event viewer, if exist
        attemptDestroyEventViewer(viewerId) {
            this.$store.dispatch('commitRemoveEventViewer', viewerId);
        },
        /***
        // method: bind event viewer to db
        bindEventViewer (eventId, viewerId) {
            console.log(`Binding Event Viewer: ${viewerId} to ${eventId}`);
            this.$store.dispatch('bindEventViewer', { eventId: eventId, viewerId: viewerId });
        },
        // method: unbind event viewer from db
        unbindEventViewer (viewerId) {
            console.log(`Unbinding Event Viewer: ${viewerId}`);
            this.$store.dispatch('unbindEventViewer', viewerId);
        },
         ***/

        /*****************************************************************
         *  Event: Methods relating to NAVIGATION for Event
         ****************************************************************/
        // method: build viewer for event admin and navigate
        viewEventAdmin (eventId) {
            // build viewer parameters
            let viewerParams = this.$_buildViewerParams(eventId)
            // set viewer placeholder in store
            this.$store.dispatch('commitCreateEventViewer', viewerParams);
            // navigate to event admin view
            navigateWithParams('eventAdmin', viewerParams);
        },
        // method: build viewer for event live and navigate
        viewEventLive (eventId) {
            // build viewer parameters
            let viewerParams = this.$_buildViewerParams(eventId)
            // set viewer placeholder in store and initialise binding
            this.$store.dispatch('commitCreateEventViewer', viewerParams);
            // navigate to event live view
            navigateWithParams('eventLive', viewerParams);
        },
        // method: build event viewer parameters
        $_buildViewerParams (eventId) { return { eventId: eventId, viewerId: uuidv4() }; }
    }
}

export default eventsMixin;