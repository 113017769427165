<template>

  <!-- Follow-up Row -->
  <v-row justify="center" align="center" class="follow-style secondary darken-1" no-gutters><v-col>
    <!-- Headline -->
    <v-row justify="center" align="center" no-gutters><v-col class="text-center">
      <span class="text-h4 font-weight-bold">A modern approach to recording</span>
    </v-col></v-row>
    <v-row justify="center" align="center" no-gutters><v-col class="text-center">
      <span class="text-h4 font-weight-bold">and displaying tournament scores.</span>
    </v-col></v-row>
    <v-row justify="center" align="center" no-gutters><v-col class="text-center">
      <span class="text-h4 font-weight-bold">Any size, without the headache.</span>
    </v-col></v-row>

    <!-- Catch-line Sub-text -->
    <v-row justify="center" align="center" class="mt-3" no-gutters><v-col class="text-center">
      <span class="text-h5 subtext-style font-weight-medium">We've created a simple platform to help you</span>
    </v-col></v-row>
    <v-row justify="center" align="center" no-gutters><v-col class="text-center">
      <span class="text-h5 subtext-style font-weight-medium">manage and display your tournament scores</span>
    </v-col></v-row>

    <!-- Get started Button -->
    <v-row justify="center" align="center" class="mt-10" no-gutters><v-col class="text-center">
      <v-btn color="success" @click="navigateTo('register')" x-large>
        <span class="secondary--text text--darken-3 text-h6">GET STARTED</span>
        <v-icon class="ml-3 secondary--text text--darken-3">east</v-icon>
      </v-btn>
    </v-col></v-row>

  </v-col></v-row>

</template>

<script>
import {navigateTo} from "@/routes/navigation";

export default {
  name: "followPanel",
  methods: {
    // method: calls navigate function
    navigateTo(nextRouteName) { navigateTo(nextRouteName); }
  }
}
</script>

<style scoped>

.follow-style {
  min-height: 600px !important;
}
.subtext-style {
  opacity: 0.3 !important;
}

</style>