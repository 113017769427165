<template>

  <!-- Events Panel Container -->
  <v-row no-gutters><v-col>

    <!-- Courses Actions -->
    <v-row no-gutters><v-col>
      <courses-actions :content-height="actionsContentHeight" />
    </v-col></v-row>

    <v-divider class="divider-opacity" />

    <!-- Courses Table -->
    <v-row no-gutters><v-col>
      <courses-table :header-color="headerColor"
                     :header-height="headerHeight"
                     :record-color="recordColor"
                     :record-height="recordHeight" />
    </v-col></v-row>

  </v-col></v-row>

</template>

<script>
import coursesTable from "@/pages/application/dashboardPage/panels/coursesPanel/components/coursesTable";
import coursesActions from "@/pages/application/dashboardPage/panels/coursesPanel/components/coursesActions";

export default {
  name: "coursesPanel",
  components: {
    coursesActions,
    coursesTable
  },
  data: () => ({
    // styling data -- TODO: Move to Mixin
    actionsHeaderColor: "#10293e",
    actionsHeaderHeight: 48,
    actionsContentColor: "#0d2334",
    actionsContentHeight: 72,
    headerColor: "#122F44", //#122F44
    headerHeight: 48,
    recordColor: "transparent",
    recordHeight: 72
  }),
}
</script>

<style scoped>

</style>