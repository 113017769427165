<template>

  <!-- Events Table Container -->
  <v-row no-gutters><v-col>

    <!-- Events Table Header -->
    <v-row no-gutters><v-col>
      <events-table-header :height="headerHeight" />
    </v-col></v-row>

    <!-- Transition Group for Table Records -->
    <transition-group name="event-transition" tag="div" class="relative-event-parent">
      <!-- All Event Table Records -->
      <v-row v-for="event in eventsSortedAndFiltered(events, searchBy, 'created.seconds', 'desc')" :key="`${event.name}`"
             class="event-transition-item" no-gutters><v-col>
        <events-table-record :event="event" :height="recordHeight" />
      </v-col></v-row>
    </transition-group>

    <!-- virtual scroll for events
    <v-virtual-scroll :height="windowHeight - 420" :item-height="recordHeight" bench="10" class="virtual-scroller"
                      :items="eventsSortedAndFiltered(events, searchBy, 'created.seconds', 'desc')">
      <template v-slot:default="{item}">
        <v-row no-gutters><v-col>
          <events-table-record :event="item" :height="recordHeight" color="transparent" />
        </v-col></v-row>
      </template>
    </v-virtual-scroll>
    -->

  </v-col></v-row>

</template>

<script>
import eventsTableHeader from "@/pages/application/dashboardPage/panels/eventsPanel/components/eventsTableHeader";
import eventsTableRecord from "@/pages/application/dashboardPage/panels/eventsPanel/components/eventsTableRecord";
import { mapGetters } from "vuex";
import eventsMixin from "@/mixins/firestoreMixins/eventsMixin";
import utilityMixin from "@/mixins/utilityMixin";

export default {
  name: "eventsTable",
  mixins: [eventsMixin, utilityMixin],
  components: {
    eventsTableHeader,
    eventsTableRecord
  },
  props: {
    // style props
    headerHeight: Number,
    recordHeight: Number,
    // search/filter props
    searchBy: String,
    sortBy: String,
  },
  computed: {
    // computed: store/state getters
    ...mapGetters({ events: "events" }),
  }
}
</script>

<style scoped>

  /* event transition */
  .event-transition-item {
    transition: all 0.75s;
  }

  .event-transition-leave-active {
    transition: all 0.25s;
  }
  .event-transition-leave-to {
    opacity: 0;
  }

  .event-transition-enter {
    opacity: 0;
    transform: translateX(1000px);
  }


  .relative-event-parent {
    position: relative;
  }

</style>