<template>

  <!-- Error Alerts with Message -->
  <v-dialog v-model="dialogDisplay" max-width="600" class="elevation-0">
    <v-alert v-model="dialogDisplay" type="error" class="pa-5 ma-0 fill-height">{{ message }}</v-alert>
  </v-dialog>

</template>

<script>
export default {
  name: "errorDialog",
  props: {
    display: Boolean,
    message: String
  },
  computed: {
    dialogDisplay: {
      get () { return this.display },
      set () { return this.$emit('close'); }
    }
  }
}
</script>

<style scoped>

</style>