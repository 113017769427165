<template>

  <v-fade-transition mode="out-in">
    <!-- Admin Page Container -->
    <v-row v-if="getEvent(viewerId) && eventVisible" no-gutters><v-col>

      <!-- Event Header -->
      <v-row no-gutters>
        <!-- Back Button -->
        <v-col cols="1" class="back-col-width">
          <v-btn @click="navigateTo('dashboard')" plain fab><v-icon>arrow_back</v-icon></v-btn>
        </v-col>
        <!-- Event Title -->
        <v-col cols="5" class="pt-3">
          <span class="text-h6 text--secondary font-weight-bold">{{ getEvent(viewerId).name }}</span>
        </v-col>
        <!-- Event Information -->
        <v-col v-if="getEvent(viewerId)" class="text-right pt-3">
          <event-metric-tag class="ml-4" icon="flag" category="Course" :value="getEvent(this.viewerId).course ? getEvent(this.viewerId).course.name : '-'" />
          <event-metric-tag class="ml-4" icon="casino" category="Playoff" :value="getEvent(this.viewerId).playoff_type" />
          <event-metric-tag class="ml-4" icon="paid" category="Skins" :value="getEvent(this.viewerId).skins ? 'Enabled' : 'Disabled'" />
          <event-metric-tag class="ml-4" icon="people" category="Players" :value="getEvent(this.viewerId).players ? Object.keys(getEvent(this.viewerId).players).length : '-'"/>
        </v-col>
      </v-row>

      <!-- Event Actions -->
      <v-row no-gutters><v-col>
        <event-actions :event="getEvent(viewerId)"
                       :content-height="actionsContentHeight"
                       @saveChanges="saveEventPlayers"/>
      </v-col></v-row>

      <v-divider class="divider-opacity" />

      <!-- Event Score Table -->
      <v-row no-gutters><v-col>
        <score-table ref="scoreTable"
                     :event="getEvent(viewerId)"
                     :header-height="headerHeight"
                     :record-height="recordHeight"/>
      </v-col></v-row>

    </v-col></v-row>

    <!-- if data not ready, loader -->
    <v-row v-else align="center" justify="center" class="fill-height"><v-col class="text-center">
      <v-row justify="center" align="center" no-gutters><v-col>
        <v-progress-circular :size="150" :width="3" color="white" indeterminate/>
      </v-col></v-row>
      <v-row justify="center" align="center" class="mt-5" no-gutters><v-col>
        <span class="subtitle-1 text--secondary">Loading Event...</span>
      </v-col></v-row>
    </v-col></v-row>

  </v-fade-transition>

</template>

<script>
import { navigateTo } from "@/routes/navigation";
import eventsMixin from "@/mixins/firestoreMixins/eventsMixin";
import eventActions from "@/pages/application/eventPages/adminPage/components/eventActions";
import scoreTable from "@/pages/application/eventPages/adminPage/components/scoreTable";
import eventMetricTag from "@/pages/application/eventPages/adminPage/components/eventMetricTag";

export default {
  name: "eventAdminPage",
  mixins: [eventsMixin],
  components: {
    eventActions,
    scoreTable,
    eventMetricTag
  },
  props: {
    eventId: String,
    viewerId: String
  },
  data: () => ({
    eventVisible: false,
    // styling data -- TODO: Move to Mixin
    actionsHeaderHeight: 48,
    actionsContentHeight: 90,
    headerHeight: 48,
    recordHeight: 60
  }),
  methods: {
    // ACTION METHODS
    // method: save all team updates to event
    saveEventPlayers (toScoreboard) {
      if (toScoreboard) {
        this.$refs.scoreTable.saveEventPlayers()
          .then((response) => {
            if (response === "success") {
              setTimeout(() => { this.viewEventLive(this.eventId); }, 2000)
            }
          })
          .catch(() => {});
      } else {
        this.$refs.scoreTable.saveEventPlayers();
      }
    },

    // UTILITY METHODS
    // method: calls navigate function
    navigateTo (nextRouteName) { navigateTo(nextRouteName); },
  },
  mounted() {
    // wait for component to be fully rendered
    this.$nextTick(function () {
      setTimeout(() => { this.eventVisible = true; }, 1000);
    })
  },
  beforeMount() {
    // beforeMount: bind event viewer to db
    this.attemptCreateEventViewer(this.eventId, this.viewerId);
  },
  beforeDestroy () {
    // beforeDestroy: perform event viewer unbinding
    this.attemptDestroyEventViewer(this.viewerId);
  }
}
</script>

<style scoped>

.back-col-width {
  max-width: 70px !important;
}

</style>