<template>

  <!-- Events Table Container -->
  <v-row v-if="event" ref="page-top" no-gutters><v-col>

    <!-- Live Event Table Header Detail -->
    <v-row no-gutters><v-col>
      <live-score-header-detail :height="headerDetailHeight"
                                :event="event"
      />
    </v-col></v-row>

    <!-- Live Event Table Header -->
    <v-row no-gutters><v-col>
      <live-score-header :height="headerHeight"
                         :event="event"
      />
    </v-col></v-row>

    <!-- Transition Group for Table Records -->
    <transition-group name="live-player-transition" tag="div" class="relative-live-player-parent">
      <!-- All Event Table Records -->
      <v-row v-for="(player, playerIndex) in eventLivePlayersSorted(event)"
             :key="`${player.id}_${player.created.nanoseconds}`"
             class="live-player-transition-item" no-gutters><v-col>
        <!-- Flight Record -->
        <live-score-flight v-if="eventFlights(event).includes(playerIndex) && event.flights > 1"
                           :height="flightHeight"
                           :flight-number="eventFlights(event).findIndex((element) => element === playerIndex)" />

        <!-- Player Score -->
        <live-score-record :playerId="player.id"
                           :playerNo="playerIndex"
                           :player="player"
                           :playerSkins="eventSkinsWinners(event)"
                           :playerFlightPos="eventPlayerFlightPosition(event, playerIndex)"
                           :event="event"
                           :height="recordHeight"
        />

      </v-col></v-row>
    </transition-group>

    <!-- Page bottom for auto-scrolling -->
    <span ref="page-bottom" />

  </v-col></v-row>

</template>

<script>
import Vue from 'vue';
import VueSmoothScroll from 'vue2-smooth-scroll'
import liveScoreHeader from "@/pages/application/eventPages/livePage/components/liveScoreHeader";
import liveScoreHeaderDetail from "@/pages/application/eventPages/livePage/components/liveScoreHeaderDetail";
import liveScoreRecord from "@/pages/application/eventPages/livePage/components/liveScoreRecord";
import eventsMixin from "@/mixins/firestoreMixins/eventsMixin";
import LiveScoreFlight from "@/pages/application/eventPages/livePage/components/liveScoreFlight";
// use smooth scroll, auto-scrolling
Vue.use(VueSmoothScroll)


export default {
  name: "liveScoreTable",
  components: {
    LiveScoreFlight,
    liveScoreHeader,
    liveScoreHeaderDetail,
    liveScoreRecord
  },
  mixins: [eventsMixin],
  props: {
    // data props
    event: Object,
    // style props
    headerHeight: Number,
    headerDetailHeight: Number,
    recordHeight: Number,
    // scrolling props
    scrollEnabled: Boolean
  },
  data: () => ({
    // styling data -- TODO: Move to Mixin
    flightColor: "#0d2334",
    flightHeight: 48
  }),
  methods: {
    // UTILITY METHODS
    // method: perform automatic scrolling
    initiateScroll () {
      // check if scroll-enabled
      if (this.scrollEnabled) {
        console.log('Scrolling')
        // scroll to bottom over n second duration
        this.$smoothScroll({ scrollTo: this.$refs['page-bottom'], duration: 30000, offset: -150 });
        // scroll back to top over n second duration
        setTimeout(() => {
          this.$smoothScroll({ scrollTo: this.$refs['page-top'], duration: 10000, offset: -150 });
        }, 30000);
      }
    }
  },
  mounted() {
    // mounted: set interval for auto-scrolling behavior
    window.setInterval(() => { this.initiateScroll(); }, 120000);
  }
}
</script>

<style scoped>

/* admin player transition */
.live-player-transition-item {
  transition: all 0.75s;
}
.live-player-transition-leave-active {
  transition: all 0.25s;
}
.live-player-transition-leave-to {
  opacity: 0;
}

.live-player-transition-enter {
  opacity: 0;
  transform: translateX(1000px);
}
.relative-live-player-parent {
  position: relative;
}

</style>