<template>

  <!-- Support/FAQ/Resources Container -->
  <v-row justify="center" align="center" no-gutters><v-col xl="6" lg="8" md="10" sm="12">

    <!-- Heading -->
    <v-row no-gutters><v-col>
      <span class="text-h5 font-weight-bold">Frequently Asked Questions</span>
    </v-col></v-row>
    <!-- Heading Subtitle -->
    <v-row no-gutters><v-col>
      <span class="subtitle-1 subtext-style font-weight-medium">Here you will find answers to common questions</span>
    </v-col></v-row>

    <!-- Questions -->
    <v-row class="mt-5" no-gutters><v-col>
      <v-expansion-panels class="rounded-0 elevation-0" flat tile>
        <!-- Q1 -->
        <v-expansion-panel key="q1">
          <v-expansion-panel-header class="pt-7 pb-7 font-weight-bold secondary darken-1">What is Scorepro?</v-expansion-panel-header>
          <v-expansion-panel-content class="secondary darken-1">
            Scorepro is a
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Q2 -->
        <v-expansion-panel key="q2">
          <v-expansion-panel-header class="pt-7 pb-7 font-weight-bold secondary darken-1">What is Scorepro?</v-expansion-panel-header>
          <v-expansion-panel-content class=" secondary darken-3">
            Scorepro is a
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Q3 -->
        <v-expansion-panel key="q3">
          <v-expansion-panel-header class="pt-7 pb-7 font-weight-bold secondary darken-1">What is Scorepro?</v-expansion-panel-header>
          <v-expansion-panel-content class=" secondary darken-3">
            Scorepro is a
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Q4 -->
        <v-expansion-panel key="q4">
          <v-expansion-panel-header class="pt-7 pb-7 font-weight-bold secondary darken-1">What is Scorepro?</v-expansion-panel-header>
          <v-expansion-panel-content>
            Scorepro is a
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Q5 -->
        <v-expansion-panel key="q5">
          <v-expansion-panel-header class="pt-7 pb-7 font-weight-bold secondary darken-1">What is Scorepro?</v-expansion-panel-header>
          <v-expansion-panel-content>
            Scorepro is a
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Q6 -->
        <v-expansion-panel key="q6">
          <v-expansion-panel-header class="pt-7 pb-7 font-weight-bold secondary darken-1">What is Scorepro?</v-expansion-panel-header>
          <v-expansion-panel-content>
            Scorepro is a
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Q7 -->
        <v-expansion-panel key="q7">
          <v-expansion-panel-header class="pt-7 pb-7 font-weight-bold secondary darken-1">What is Scorepro?</v-expansion-panel-header>
          <v-expansion-panel-content>
            Scorepro is a
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Q8 -->
        <v-expansion-panel key="q8">
          <v-expansion-panel-header class="pt-7 pb-7 font-weight-bold secondary darken-1">What is Scorepro?</v-expansion-panel-header>
          <v-expansion-panel-content>
            Scorepro is a
          </v-expansion-panel-content>
        </v-expansion-panel>

      </v-expansion-panels>
    </v-col></v-row>

    <!-- Heading Subtitle -->
    <v-row class="mt-5" no-gutters><v-col>
      <span class="subtitle-1 subtext-style font-weight-medium">Still have questions? Contact support at</span>
      <span class="pl-2 font-weight-bold">support@scorepro.golf</span>
    </v-col></v-row>

  </v-col></v-row>

</template>

<script>
export default {
  name: "supportPage"
}
</script>

<style scoped>

.subtext-style {
  opacity: 0.3 !important;
}

</style>