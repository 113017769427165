<template>

  <!-- Centered Row for Email -->
  <v-row justify="center" align="center" class="fill-height flex-nowrap" no-gutters>
    <v-col cols="3">
      <v-card min-width="50%" class="transparent elevation-0">

        <!-- Login Title -->
        <v-card-title class="pl-0 pr-0" color="white">
          <span>Reset Password</span>
        </v-card-title>

        <!-- Login Form -->
        <v-card-text class="pa-0">
          <v-form ref="form" v-model="passwordValid" method="post">
            <!-- Password -->
            <v-row justify="center" align="center" no-gutters>
              <v-text-field v-model="password" :rules="passwordRules" :error-count="1"
                            label="New Password" type="password" append-icon="fa-key" outlined required />
            </v-row>
            <!-- Confirm Password -->
            <v-row justify="center" align="center" no-gutters>
              <v-text-field v-model="re_password" :rules="[(password === re_password) || 'Password must match']"
                            :error-count="1" v-on:keyup.enter="resetPassword"
                            label="Confirm Password" type="password" append-icon="check_circle_outline" outlined required />
            </v-row>
          </v-form>
        </v-card-text>

        <!-- Submission Actions -->
        <v-card-actions class="pa-0">
          <v-row no-gutters>
            <v-col cols="12">
              <!-- Reset of Password Button -->
              <v-btn @click="resetPassword" :disabled="!passwordValid || attemptingReset" width="100%" outlined>
                <!-- login attempt spinner -->
                <span v-if="attemptingReset">
                  <v-progress-circular :size="20" :width="2" color="white" indeterminate />
                </span>
                <!-- send recovery email btn text -->
                <span v-else>Update Password</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

      </v-card>
    </v-col>

    <!-- Error Alerts with Message -->
    <v-dialog v-model="errorAlertDisplay" max-width="600" class="elevation-0">
      <v-alert v-model="errorAlertDisplay" type="error" class="pa-5 ma-0 fill-height">{{ errorAlertMessage }}</v-alert>
    </v-dialog>
    <!-- Success Alerts with Message -->
    <v-dialog v-model="successAlertDisplay" max-width="600"  class="elevation-0">
      <v-alert v-model="successAlertDisplay" type="success" class="pa-5 ma-0 fill-height">{{ successAlertMessage }}</v-alert>
    </v-dialog>

  </v-row>

</template>

<script>
import { firebaseResetPasswordByCode } from "@/plugins/firebase/security"
import { navigateTo } from "@/routes/navigation";


export default {
  name: "resetPassword",

  data: () => ({
    // attempting password reset
    attemptingReset: false,
    // password form
    password: null,
    re_password: null,
    // password validation
    passwordValid: false,
    // password form rules
    passwordRules: [
      v => !!v || 'Password is required',
      v => (v && v.length >= 5) || 'Password must have 5+ characters',
      v => /(?=.*[A-Z])/.test(v) || 'Must have one uppercase character',
      v => /(?=.*\d)/.test(v) || 'Must have one number',
      v => /([!@$%])/.test(v) || 'Must have one special character [!@#$%]'
    ],

    // error alert popup
    errorAlertDisplay: false,
    errorAlertMessage: null,
    // success alert popup
    successAlertDisplay: false,
    successAlertMessage: null,
  }),

  methods: {
    // method: submit resetting of password
    resetPassword() {
      // set button spinner
      this.attemptingReset = true;
      // get data from URL (provided in redirect from reset email)
      let code = this.$route.query.oobCode;

      // make sure code exists
      if (code) {
        // make call to firebase to reset password with code
        firebaseResetPasswordByCode(code, this.password)
            .then(() => {
              // set success message and display
              this.successAlertMessage = 'Password updated successfully! Login with new password...';
              this.successAlertDisplay = true;
              // set button spinner
              this.attemptingReset = false;
              // redirect to login page after n seconds
              setTimeout(() => navigateTo('login'), 2000);
            })
            .catch(error => {
              // error occurred
              // set error message and display
              this.errorAlertMessage = error;
              this.errorAlertDisplay = true;
              // set button spinner
              this.attemptingReset = false;
            });
      }
      // code doesn't exist
      else {
        // error occurred
        // set error message and display
        this.errorAlertMessage = 'Reset code not provided in URL. Please remember to use the redirection link found ' +
                                 'in the recovery email provided to you.';
        this.errorAlertDisplay = true;
        // set button spinner
        this.attemptingReset = false;
      }
    }
  }
}
</script>

<style scoped>

</style>