<template>

  <!-- Login Container -->
  <v-row justify="center" align="center" class="fill-parent-ht" no-gutters><v-col align="center" xl="6" lg="8" md="10" sm="12">

    <v-card color="secondary darken-2" class="pt-15 login-size rounded-xl elevation-0">
      <!-- Login Header -->
      <v-row justify="center" align="center" no-gutters><v-col class="text-center">
        <span class="text-h4 font-weight-bold">Login</span>
      </v-col></v-row>
      <!-- Heading Catch-line Sub-text -->
      <v-row justify="center" align="center" class="mt-1" no-gutters><v-col class="text-center">
        <span class="subtitle-1 subtext-style font-weight-medium">Welcome back! Login to use Scorepro</span>
      </v-col></v-row>

      <!-- Enter Email -->
      <v-form ref="form" v-model="isValid" method="post">
        <v-row class="mt-10" no-gutters><v-col class="text-left">
          <span class="caption text--secondary">Email Address</span>
        </v-col></v-row>
        <v-row no-gutters><v-col>
          <v-text-field v-model="email" :rules="emailRules" :error-count="1"
                        append-icon="email" outlined required hide-details />
        </v-col></v-row>

        <!-- Enter Password -->
        <v-row class="mt-3" no-gutters><v-col class="text-left">
          <span class="caption text--secondary">Password</span>
        </v-col></v-row>
        <v-row justify="center" align="center"  no-gutters><v-col>
          <v-text-field v-model="password" :rules="passwordRules" :error-count="1" v-on:keyup.enter="login"
                        type="password" append-icon="fa-key" outlined required hide-details />
        </v-col></v-row>
      </v-form>

      <!-- Remember Me/Forgot Password -->
      <v-row class="mt-2" no-gutters>
        <v-col class="text-right">
          <v-btn @click="navigateTo('forgotPassword')" color="info" class="pa-0 font-weight-medium" text small plain>Forgot Password?</v-btn>
        </v-col>
      </v-row>

      <!-- Login Button -->
      <v-row justify="center" align="center" class="mt-5" no-gutters><v-col>
        <v-btn @click="login" color="info" :disabled="!isValid" block large>
          <v-progress-circular v-if="attemptingLogin" :size="20" :width="2" color="white" indeterminate />
          <div v-else><span>Login</span></div>
        </v-btn>
      </v-col></v-row>

      <!-- OR -->
      <v-row class="mt-8" no-gutters>
        <v-col cols="5" class="pt-3"><v-divider /></v-col>
        <v-col cols="2"><span class="text--secondary subtext-style font-weight-bold">OR</span></v-col>
        <v-col cols="5" class="pt-3"><v-divider /></v-col>
      </v-row>

      <!-- Login with Google or Facebook -->


      <!-- Don't have account? Create Account now -->
      <v-row class="mt-5" no-gutters><v-col class="text-center">
        <span class="subtitle-2 text--secondary">
          <span class="subtext-style">Don't have an account?</span>
          <v-btn @click="navigateTo('register')" color="info" class="ml-3 pa-0 font-weight-medium" text small plain>Register here</v-btn>
        </span>
      </v-col></v-row>

    </v-card>

    <!-- Login Error -->
    <v-fade-transition>
      <v-row v-if="error" justify="center" align="center" class="mt-2" no-gutters><v-col>
        <v-alert width="550" color="transparent" class="error--text subtitle-1 font-weight-medium" dense>{{ error }}</v-alert>
      </v-col></v-row>
    </v-fade-transition>

  </v-col></v-row>

</template>

<script>
import { navigateTo } from "@/routes/navigation";
import { firebaseLogIn } from "@/plugins/firebase/security";

export default {
  name: "loginPage",
  data: () => ({
    attemptingLogin: false,
    // form entry
    email: null,
    password: null,
    // validation check
    isValid: true,
    error: null,
    emailRules: [v => !!v || '* Email is required', v => /.+@.+/.test(v) || '* Email must be valid'],
    passwordRules: [v => !!v || 'Password is required']
  }),
  methods: {
    // method: submission for login
    login() {
      // attempting login, display loader
      this.attemptingLogin = true;
      // firebase login
      firebaseLogIn(this.email, this.password)
          .catch(() => {
            // login failed, hide loader
            this.attemptingLogin = false;
            // reset email/password form
            this.$refs.form.reset()
            // print error message
            this.error = 'Email or Password incorrect. Re-enter to login.'
          })
    },
    // method: calls navigate function
    navigateTo(nextRouteName) { navigateTo(nextRouteName); }
  }
}
</script>

<style scoped>

.login-size {
  width: 550px !important;
  height: 600px !important;
  padding-right: 80px !important;
  padding-left: 80px !important;
}
.subtext-style {
  opacity: 0.3 !important;
}

</style>