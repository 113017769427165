<template>

  <!-- Account Info Summary Container -->
  <v-row no-gutters><v-col>

    <!-- Account Info Header -->
    <v-row no-gutters><v-col>
      <span class="subtitle-1 text--secondary font-weight-bold">Manage Account</span>
    </v-col></v-row>

    <v-row align="center" class="mt-3" no-gutters>
      <!-- Account Info Forms -->
      <v-col cols="6">
        <!-- Account Email -->
        <v-row no-gutters><v-col>
          <v-text-field :value="accountEmail(user)" label="Account Email" append-icon="account_circle"
                        disabled hide-details dense outlined />
        </v-col></v-row>
        <!-- Account Displayname -->
        <v-row class="mt-3" no-gutters><v-col>
          <v-text-field v-model="newAccountDisplayName" :value="accountDisplayName(user)"  label="Display Name"
                        append-icon="remove_red_eye" hide-details dense outlined />
        </v-col></v-row>
        <!-- Account Club Name -->
        <v-row class="mt-3" no-gutters><v-col>
          <v-text-field v-model="newAccountClubName" :value="accountClubName(user)" label="Club Name" append-icon="fa-flag"
                        hide-details dense outlined />
        </v-col></v-row>
        <!-- Account Phone Number -->
        <v-row class="mt-3" no-gutters><v-col>
          <v-text-field v-model="newAccountPhoneNumber" :value="accountPhoneNumber(user)" @input="phoneNumberFormat"
                        :maxlength="14" :prefix="accountPhonePrefix()" label="Phone Number" append-icon="fa-phone"
                        hide-details dense outlined />
        </v-col></v-row>
        <!-- Save Account updates btn -->
        <v-row class="mt-3" no-gutters><v-col class="text-right">
          <v-btn @click="saveAccountUpdates" outlined text block :disabled="!accountFormsValid || attemptAccountUpdate">
            <v-progress-circular v-if="attemptAccountUpdate" :size="20" :width="2" color="blue" indeterminate />
            <span v-else class="ml-3 caption font-weight-bold"
                         :class="(!accountFormsValid || attemptAccountUpdate ? 'text--disabled' : 'info--text')">
              Save Updates
            </span>
          </v-btn>
        </v-col></v-row>
      </v-col>

      <!-- Save account updates -->
      <v-col cols="6" class="text-center">

      </v-col>

    </v-row>

    <!-- success/error dialogs -->
    <success-dialog :display="successDialogDisplay" :message="successDialogMessage" @close="successDialogDisplay=false" />
    <error-dialog :display="errorDialogDisplay" :message="errorDialogMessage" @close="errorDialogDisplay=false" />

  </v-col></v-row>

</template>

<script>
import {mapGetters} from "vuex";
import accountMixin from "@/mixins/accountMixin";
import dialogMixin from "@/mixins/componentMixins/dialogMixin";

export default {
  name: "accountSummary",
  mixins: [accountMixin, dialogMixin],
  computed: {
    ...mapGetters({user: "user"}),
    // computed: validate changes in form input
    accountFormsValid () {
      return this.newAccountDisplayName !== this.accountDisplayName(this.user) ||
          this.newAccountClubName !== this.accountClubName(this.user) ||
          this.newAccountPhoneNumber !== this.accountPhoneNumber(this.user)
    }
  },
  data: () => ({
    // new account data
    newAccountDisplayName: null,
    newAccountClubName: null,
    newAccountPhoneNumber: null,
    // attempt data
    attemptAccountUpdate: false,
  }),
  methods: {
    // ACTION METHODS
    // method: update account information
    saveAccountUpdates () {
      // initiate spinner
      this.attemptAccountUpdate = true;

      // build payload for update
      let accountPayload = {
        'club_name' : this.newAccountClubName,
        'phone' : this.newAccountPhoneNumber,
        'name' : this.newAccountDisplayName
      }
      // update account information
      this.updateAccountInformation(this.user, accountPayload)
        .then(() => {
          // stop spinner
          this.attemptAccountUpdate = false;
          // success, display message
          this.alertSuccess('Account information successfully updated!');
        })
        .catch(error => {
          // stop spinner
          this.attemptAccountUpdate = false;
          // error occurred, display message
          this.alertError('An error occurred updating account, contact support if the issue persists.');
          throw error;
        });
    },

    // UTILITY METHODS
    // method: enforce phone number input form
    phoneNumberFormat() {
      let x = this.newAccountPhoneNumber.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.newAccountPhoneNumber = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    // method: set initial account information
    setAccountInformation() {
      this.newAccountDisplayName = this.accountDisplayName(this.user);
      this.newAccountClubName = this.accountClubName(this.user);
      this.newAccountPhoneNumber = this.accountPhoneNumber(this.user);
    }
  },
  mounted() {
    this.setAccountInformation()
  }
}
</script>

<style scoped>

</style>