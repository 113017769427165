<template>

  <!-- FAQ Row -->
  <v-row justify="center" align="center" class="faq-style secondary darken-3" no-gutters><v-col>

    <!-- Headline -->
    <v-row justify="center" align="center" no-gutters><v-col class="text-center">
      <span class="text-h4 font-weight-bold">Got Questions?</span>
    </v-col></v-row>

    <!-- Catch-line Sub-text -->
    <v-row justify="center" align="center" class="mt-3" no-gutters><v-col class="text-center">
      <span class="text-h5 subtext-style font-weight-medium">We're here to keep score</span>
    </v-col></v-row>
    <v-row justify="center" align="center" no-gutters><v-col class="text-center">
      <span class="text-h5 subtext-style font-weight-medium">See if our features match your needs</span>
    </v-col></v-row>

    <!-- Get started Button -->
    <v-row justify="center" align="center" class="mt-10" no-gutters><v-col class="text-center">
      <v-btn color="info" @click="navigateTo('pricing')" x-large>
        <span class="secondary--text text--darken-3 text-h6">Check it out</span>
        <v-icon class="ml-3 secondary--text text--darken-3">east</v-icon>
      </v-btn>
    </v-col></v-row>

  </v-col></v-row>

</template>

<script>
import {navigateTo} from "@/routes/navigation";

export default {
  name: "faqPanel",
  methods: {
    // method: calls navigate function
    navigateTo(nextRouteName) { navigateTo(nextRouteName); }
  }
}
</script>

<style scoped>

.faq-style {
  min-height: 600px !important;
}
.subtext-style {
  opacity: 0.3 !important;
}

</style>