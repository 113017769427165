<template>

  <!-- Successful Checkout -->
  <v-row justify="center" align="center" class="fill-height">
    <v-col cols=6>
      <!-- Message -->
      <v-row justify="center" align="center" class="fill-height" no-gutters>
        <span class="text-h6">Checkout Successful!</span>
      </v-row>
      <v-row justify="center" align="center" class="fill-height" no-gutters>
        <span class="caption blink_item">Configuring Subscription</span>
      </v-row>
      <!-- Progress Spinner -->
      <v-row justify="center" align="center" class="mt-5 fill-height" no-gutters>
        <v-progress-circular :size="150" :width="3" color="white" indeterminate/>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { router } from "@/routes/router";

export default {
  name: "successfulCheckoutPage",
  created() {
    // redirect to dashboard after n seconds
    // allows subscriptions to sync after successful checkout
    setTimeout(() => router.push({name: 'dashboard'}), 5000);
  }




}
</script>

<style scoped>
.blink_item {
  animation: blinker 2s linear infinite;
}
@keyframes blinker {
  50% { opacity: 0.2; }
}
</style>