<template>

  <!-- Header Card -->
  <v-row :class="event.flights > 1 ? '' : 'mb-3'" no-gutters><v-col>
    <v-card :height="height" color="secondary darken-1" class="rounded-0 elevation-0">
      <v-row :height="height" no-gutters align="center" class="fill-inner-parent-ht">

        <!-- Player Position -->
        <v-col cols="1" class="align-center text-center player-pos-width">
          <span class="text-subtitle-2 text--secondary font-weight-bold">#</span>
        </v-col>
        <!-- Player/Team Name -->
        <v-col cols="2" class="pl-1 align-center">
          <span class="text-subtitle-2 text--secondary font-weight-bold">Name</span>
        </v-col>
        <!-- Event Hole Scores -->
        <v-col class="pr-5 align-center text-center">
          <v-row no-gutters>
            <template v-for="(hole, holeId) in eventHoles(event)">
              <!-- Holes -->
              <!-- Playoff Hole -->
              <v-col v-if="hole.hole === event.playoff" :key="`${holeId}`" class="text-center skin-hole-styling">
                <span class="text--secondary">{{ hole.hole }}</span>
              </v-col>
              <!-- Non-Playoff Hole -->
              <v-col v-else :key="`${holeId}`" class="text-center">
                <span class="text--secondary">{{ hole.hole }}</span>
              </v-col>
              <!-- Hole Out -->
              <v-col v-if="hole.hole === 9 && eventHoleCount(event) !== 9" :key="`${holeId}_out`" class="text-center">
                <span class="text--secondary font-weight-bold">OUT</span>
              </v-col>
              <!-- Hole In -->
              <v-col v-if="hole.hole === 18 && eventHoleCount(event) !== 9" :key="`${holeId}_in`" class="text-center">
                <span class="text--secondary font-weight-bold">IN</span>
              </v-col>
              <!-- Hole Total -->
              <v-col v-if="hole.hole === 18 || hole.hole === eventHoleCount(event)"
                     :key="`${holeId}_total`" class="text-center">
                <span class="text--secondary font-weight-bold">TOT</span>
              </v-col>
              <!-- Handicap -->
              <v-col v-if="(hole.hole === 18 || hole.hole === eventHoleCount(event)) && event.handicap" :key="`${holeId}_handicap`" class="text-center">
                <span class="text--secondary font-weight-bold">HCP</span>
              </v-col>
              <!-- Score ToPar -->
              <v-col v-if="hole.hole === 18 || hole.hole === eventHoleCount(event)" :key="`${holeId}_topar`" class="text-center">
                <span class="text--secondary font-weight-bold">PAR</span>
              </v-col>
            </template>
          </v-row>
        </v-col>

      </v-row>
    </v-card>

    <v-divider class="divider-opacity" />

  </v-col></v-row>

</template>

<script>
import eventsMixin from "@/mixins/firestoreMixins/eventsMixin";


export default {
  name: "liveScoreHeader",
  mixins: [eventsMixin],
  props: {
    // data props
    event: Object,
    // styling props
    height: Number
  }
}
</script>

<style scoped>

.player-pos-width {
  max-width: 70px !important;
}

.skin-hole-styling {
  background-color: #0e3d58;
  border-radius: 20px;
}


</style>