<template>

  <v-row no-gutters><v-col>
    <!-- Pricing Button -->
    <v-btn @click="navigateTo('pricing')" small text plain class="white--text">Pricing</v-btn>
    <!-- Resources/FAQ Button -->
    <!--<v-btn @click="navigateTo('pricing')" small text plain class="white--text">Resources</v-btn>-->
    <!-- Login Button -->
    <v-btn @click="navigateTo('login')" small text plain class="white--text">Login</v-btn>
    <!-- Register Button -->
    <v-btn @click="navigateTo('register')" small text plain color="success" class="ml-3">Register</v-btn>
  </v-col></v-row>

</template>

<script>
import { navigateTo } from "@/routes/navigation";


export default {
  name: "webHeaderHelper",
  // methods for component
  methods: {
    // method: calls navigate function
    navigateTo(nextRouteName) { navigateTo(nextRouteName); }
  }
}
</script>

<style scoped>

</style>